export const STYLE_STANDARD = 'standard';
export const STYLE_SOLITARY = 'solitary';

export const SIZE_STANDARD = 'standard';
export const SIZE_COMPACT = 'compact';
export const SIZE_MINI = 'mini';

export const STYLES = [STYLE_STANDARD, STYLE_SOLITARY];

export const SIZES = [SIZE_STANDARD, SIZE_COMPACT, SIZE_MINI];
