import { Icon } from '@eventbrite/eds-icon';
import { StarFill } from '@eventbrite/eds-iconography';
import { checkGate } from '@eventbrite/statsig';
import classnames from 'classnames';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
    RATING_DISPLAY_TRACKING_METHODS,
    TRACK_EVENTS_PLATFORMS,
} from '../../constants';
import { useGetRatingObject } from '../../hooks';
import { getConfigStatsig, initializeStatsigSdk } from '../../tracking/statsig';
import {
    colorsTypes,
    DisabledTrackingList,
    ModalTypes,
    RatingData,
    widgetSize,
} from '../../types';
import {
    evaluateExperiment,
    getDomain,
    getFilteredTrackingMethods,
} from '../../utils';
import { RatingDetailModal } from '../ratingDetailModal/ratingDetailModal';
import { Portal } from '../shared/portal/Portal';
import { RatingDisplaySkeleton } from '../shared/skeletonLoader';
import { OPEN_MODAL_INFO, SIZES_FROM_WIDGET } from './constants';
import './RatingDisplay.scss';
import { RatingEmptyState } from './RatingEmptyState';

interface RatingDisplayProps {
    organizerData: {
        organizerId: string;
        organizerName?: string;
    };
    ratingSummary?: RatingData;
    size?: widgetSize;
    title?: string;
    color?: colorsTypes;
    divider?: boolean;
    modalType?: ModalTypes;
    trackCategory?: string;
    showLoading?: boolean;
    condensed?: boolean;
    filterRating?: boolean;
    addClassToContainer?: {
        className?: string;
    };
    disableTrackOn?: DisabledTrackingList;
    disableClick?: boolean;
    onGetRatingData?: Function;
    isLink?: boolean;
    displayOrganizerTags?: boolean;
    useContextData?: boolean;
    filterbyTld?: boolean;
    enableRatingDisplay?: boolean;
}

interface ToggleDisabledControlProps {
    disableClick?: boolean;
    children: React.ReactNode;
    onClick: (e: SyntheticEvent) => void;
    containerClassnames: string;
    isLink: boolean;
}

const ToggleDisabledControl = ({
    disableClick,
    children,
    onClick,
    containerClassnames,
    isLink = false,
}: ToggleDisabledControlProps) => {
    return disableClick ? (
        <div className={containerClassnames} data-testid="rating-display">
            {children}
        </div>
    ) : isLink ? (
        <div
            className={containerClassnames}
            onClick={onClick}
            data-testid="rating-display"
        >
            {children}
        </div>
    ) : (
        <button
            aria-label={OPEN_MODAL_INFO}
            title={OPEN_MODAL_INFO}
            className={containerClassnames}
            onClick={onClick}
            data-testid="rating-display"
        >
            {children}
        </button>
    );
};

export const RatingDisplay = ({
    organizerData,
    ratingSummary,
    size = 'xsmall',
    title = 'Rating',
    color = 'ui-orange',
    divider = false,
    modalType = 'attendee',
    showLoading = true,
    trackCategory = 'ratings-display',
    condensed = false,
    filterRating = false,
    addClassToContainer,
    disableTrackOn = [],
    disableClick = false,
    onGetRatingData,
    isLink = false,
    displayOrganizerTags,
    useContextData = false,
    filterbyTld = true,
}: RatingDisplayProps) => {
    const [showModal, setShowModal] = useState(false);
    const [statsigConfig, setStatsigConfig] = useState(false);
    const { organizerId, organizerName } = organizerData;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [displayAttendeeTags, setDisplayAttendeeTags] = useState(false);

    const ENABLED_TRACKING_METHODS = getFilteredTrackingMethods(
        TRACK_EVENTS_PLATFORMS,
        disableTrackOn,
    );

    useEffect(() => {
        ENABLED_TRACKING_METHODS.includes('StatSig') &&
            initializeStatsigSdk(organizerId).then(() => {
                if (filterRating) {
                    setStatsigConfig(getConfigStatsig('display_rating_config'));
                }
                setDisplayAttendeeTags(
                    checkGate('display_rating_tags_attendee'),
                );
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const HAS_ENABLE_TRACKING_METHODS = ENABLED_TRACKING_METHODS.length > 0;

    const { isLoading, ratingObject } = useGetRatingObject({
        ratingSummary,
        organizerId,
        trackCategory,
        trackingMethods: ENABLED_TRACKING_METHODS,
        useContextData,
    });

    const OVERALL_RATING = ratingObject?.rating || 0;
    const REVIEWS_COUNT = !evaluateExperiment(
        OVERALL_RATING,
        filterRating,
        statsigConfig,
        filterbyTld,
    )
        ? 0
        : ratingObject?.total_count?.value;
    const REVIEWS_DISPLAY = ratingObject?.total_count?.display;
    const domain = getDomain();
    const SHOULD_NOT_SHOW_RATING = filterRating && !REVIEWS_COUNT;

    useEffect(() => {
        ratingObject && onGetRatingData && onGetRatingData(ratingObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratingObject?.rating]);

    useEffect(() => {
        if (HAS_ENABLE_TRACKING_METHODS) {
            if (SHOULD_NOT_SHOW_RATING) {
                ENABLED_TRACKING_METHODS.forEach((platform) => {
                    return RATING_DISPLAY_TRACKING_METHODS[
                        platform as keyof typeof RATING_DISPLAY_TRACKING_METHODS
                    ].RATING_NOT_DISPLAYED(
                        trackCategory,
                        OVERALL_RATING,
                        organizerId,
                    );
                });
            } else {
                ENABLED_TRACKING_METHODS.forEach((method) => {
                    return RATING_DISPLAY_TRACKING_METHODS[
                        method as keyof typeof RATING_DISPLAY_TRACKING_METHODS
                    ].RATING_DISPLAYED(
                        trackCategory,
                        OVERALL_RATING,
                        organizerId,
                    );
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SHOULD_NOT_SHOW_RATING]);

    const EMPTY_REVIEWS = !Number(REVIEWS_COUNT) && !isLoading;
    const IS_CLICKABLE_COMPONENT =
        !disableClick && !EMPTY_REVIEWS && !isLoading;
    const USE_PLURAL = REVIEWS_COUNT && REVIEWS_COUNT > 1;

    const handleClickRating = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (IS_CLICKABLE_COMPONENT) {
            setShowModal(true);
            if (trackCategory) {
                HAS_ENABLE_TRACKING_METHODS &&
                    ENABLED_TRACKING_METHODS.forEach((method) => {
                        return RATING_DISPLAY_TRACKING_METHODS[
                            method as keyof typeof RATING_DISPLAY_TRACKING_METHODS
                        ].MODAL_OPEN(trackCategory, organizerId);
                    });
            }
        }
    };
    const handleCloseRating = () => {
        setShowModal(false);
        if (trackCategory) {
            HAS_ENABLE_TRACKING_METHODS &&
                ENABLED_TRACKING_METHODS.forEach((method) => {
                    return RATING_DISPLAY_TRACKING_METHODS[
                        method as keyof typeof RATING_DISPLAY_TRACKING_METHODS
                    ].MODAL_CLOSE(trackCategory, organizerId);
                });
        }
    };

    const dotDivider = (
        <p
            className={`eds-text-${SIZES_FROM_WIDGET[size].ratingNumber} eds-text-weight--heavy eds-l-mar-hor-1`}
        >
            •
        </p>
    );

    const ratingValue = (
        <p
            className={`eds-text-${
                SIZES_FROM_WIDGET[size].ratingNumber
            } eds-text-weight--${
                size === 'medium' ? 'bold' : 'heavy'
            } eds-l-mar-left-1`}
            data-testid="rating"
        >
            {OVERALL_RATING}
        </p>
    );

    const reviewsValueClassnames = classnames(
        `eds-text-${SIZES_FROM_WIDGET[size].reviewNumber} reviews-number`,
        {
            'eds-text-color--ui-600': disableClick,
            'eds-text-weight--heavy eds-text-color--ui-blue--hover':
                !disableClick,
        },
    );

    const reviewString = `${
        size !== 'medium' ? '· ' : ''
    }${REVIEWS_DISPLAY} review${USE_PLURAL ? 's' : ''}`;

    const reviewsValue = (
        <p className={reviewsValueClassnames} data-testid="reviews">
            {condensed ? `(${REVIEWS_DISPLAY})` : reviewString}
        </p>
    );

    const addedClassName = addClassToContainer?.className || '';
    const widgetContainerClassnames = classnames('rating-display--container', {
        'use-cursor-pointer':
            IS_CLICKABLE_COMPONENT && !showModal && !disableClick,
        'flex-column': size === 'medium',
        [addedClassName]: addClassToContainer,
    });

    if (SHOULD_NOT_SHOW_RATING) {
        return null;
    }

    let displayComponent = (
        <>
            <div className="rating-wrapper">
                {divider && size === 'xsmall' && dotDivider}
                <Icon
                    type={<StarFill />}
                    title={title}
                    size={SIZES_FROM_WIDGET[size].icon}
                    color={color}
                />
                {ratingValue}
            </div>
            {reviewsValue}
            <Portal>
                <RatingDetailModal
                    isShown={showModal}
                    onClose={handleCloseRating}
                    ratingData={ratingObject}
                    organizerName={organizerName}
                    organizerId={organizerId}
                    trackCategory={trackCategory}
                    type={modalType}
                    domain={domain}
                    trackingMethods={ENABLED_TRACKING_METHODS}
                    displayOrganizerTags={displayOrganizerTags}
                />
            </Portal>
        </>
    );

    if (isLoading && showLoading) {
        displayComponent = (
            <div className="content-wrapper">
                {size === 'medium' ? (
                    <>
                        <RatingDisplaySkeleton width={55} height={24} />
                        <RatingDisplaySkeleton width={75} />
                    </>
                ) : (
                    <RatingDisplaySkeleton width={100} />
                )}
            </div>
        );
    }

    if (EMPTY_REVIEWS) {
        displayComponent = (
            <div className="content-wrapper">
                <RatingEmptyState size={size} />
            </div>
        );
    }

    return (
        <ToggleDisabledControl
            onClick={handleClickRating}
            containerClassnames={widgetContainerClassnames}
            disableClick={disableClick}
            isLink={isLink}
        >
            {displayComponent}
        </ToggleDisabledControl>
    );
};
