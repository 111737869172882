import { sdkRequest } from '@eventbrite/http';
import {
    GET_RATING_URL,
    mockedGetRatingSample,
    MOCK_ORGANIZER,
} from '../constants/index';

/**
 *API to getRatingData
 */
export const getRatingData = ({
    organizer_id,
    locale,
}: {
    organizer_id: string;
    locale: string;
}) => {
    /* istanbul ignore if */
    if (organizer_id === MOCK_ORGANIZER) {
        return Promise.resolve(mockedGetRatingSample);
    } else {
        return sdkRequest(`${GET_RATING_URL}${organizer_id}/?&locale=${locale}`)
            .then((response: any) => response)
            .catch((error: any) => {
                /* istanbul ignore next  */
                if (organizer_id === MOCK_ORGANIZER) {
                    return Promise.resolve(mockedGetRatingSample);
                }
                const statusCode = error.response ? error.response.status : 400;
                if (statusCode === 200) {
                    // 200 Code It's Ok
                    return Promise.resolve();
                }
                throw error?.parsedError?.error || statusCode;
            });
    }
};
