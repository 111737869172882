import { track } from '@eventbrite/datalayer-library';
import { getEventName } from './statsig';

export const heapModalOpen = (eventPage: string, organizerId: string) => {
    trackHeap(getEventName(eventPage, 'MODAL_OPEN'), { organizerId });
};

export const heapModalClose = (eventPage: string, organizerId: string) => {
    trackHeap(getEventName(eventPage, 'MODAL_CLOSE'), { organizerId });
};

export const heapGetDataSuccess = (eventPage: string, organizerId: string) => {
    trackHeap(getEventName(eventPage, 'GET_DATA_SUCCESS'), { organizerId });
};

export const heapGetDataError = (
    eventPage: string,
    organizerId: string,
    error: string,
) => {
    trackHeap(getEventName(eventPage, 'GET_DATA_ERROR'), {
        organizerId,
        error,
    });
};

export const heapRatingsLoaded = (eventPage: string, organizerId: string) => {
    trackHeap(getEventName(eventPage, 'LOADED'), { organizerId });
};

export const heapRatingsDisplayed = (
    eventPage: string,
    rating: number,
    organizerId: string,
) => {
    trackHeap(getEventName(eventPage, 'DISPLAYED'), { rating, organizerId });
};

export const heapRatingsNotDisplayed = (
    eventPage: string,
    rating: number,
    organizerId: string,
) => {
    trackHeap(getEventName(eventPage, 'NOT_DISPLAYED'), {
        rating,
        organizerId,
    });
};

export const heapRatingHelperCenterClick = (
    eventPage: string,
    organizerId: string,
    link: string,
) => {
    trackHeap(getEventName(eventPage, 'LINK_HELPER_CENTER_CLICKED'), {
        link,
        organizerId,
    });
};

export const trackHeap = (eventName: string, eventData: any): any => {
    track({
        eventName,
        eventData,
    });
};
