import React from 'react';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';

const SuperCropOne = (props) => (
    <svg width="61px" height="80px" viewBox="0 0 40 80" {...props}>
        <defs>
            <circle id="supercrop-path-1" cx="40" cy="40" r="40" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-19.000000, 0.000000)">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#supercrop-path-1" />
                </mask>
                <path
                    d="M17,80 C26.8245231,63.1874144 32.7485663,47.2931287 34.7721297,32.3171429 C34.7721297,32.3171429 9.28383334,0.772244159 34.7721297,-12.1971472 C60.260426,-25.1665386 65.0948582,10.0692591 62.6776421,28.1866455 C62.6776421,28.1866455 73.3246986,36.8600727 80,39.3556587 C80,39.3556587 80,47.2071024 80,62.9099899 C68.3182226,57.7245383 60.907229,53.788732 57.7670191,51.102571 C57.7670191,51.102571 49.3184372,75.9707584 46.6161198,80 C46.6161198,80 36.7440799,80 17,80 Z"
                    fill="#000000"
                    mask="url(#mask-2)"
                />
            </g>
        </g>
    </svg>
);

const SuperCropTwo = (props) => (
    <svg width="75px" height="80px" viewBox="0 0 80 80" {...props}>
        <defs>
            <circle id="supercrop2-path-1" cx="40" cy="40" r="40" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <mask id="supercrop2-mask-2" fill="white">
                    <use xlinkHref="#supercrop2-path-1" />
                </mask>
                <path
                    d="M-10,62.6675841 C15.4962089,21.097528 36.1750909,0.3125 52.0366458,0.3125 C75.8289781,0.3125 78.2779203,20.6457262 72.1319348,39.0749558 C65.9859492,57.5041854 59.1407262,71.0196681 57.3255016,80.3125 C57.3255016,80.3125 49.0503344,80.3125 32.5,80.3125 C35.642018,68.336358 40.7829689,55.7226932 47.9228526,42.4715057 C58.6326781,22.5947244 50.412723,7.82562547 32.5,35.3066069 C20.5581847,53.6272612 11.0967809,68.6292256 4.11578854,80.3125 L-10,80.3125 L-10,62.6675841 Z"
                    fill="#000000"
                    mask="url(#supercrop2-mask-2)"
                />
            </g>
        </g>
    </svg>
);

const SuperCropThree = (props) => (
    <svg width="80px" height="65px" viewBox="0 0 82 50" {...props}>
        <defs>
            <circle id="supercrop3-path-1" cx="40" cy="40" r="40" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.000000, -15.000000)">
                <mask id="supercrop3-mask-2" fill="white">
                    <use xlinkHref="#supercrop3-path-1" />
                </mask>
                <path
                    d="M-24,55.003681 C-7.69017764,28.9829827 6.7808255,15.9726335 19.4130094,15.9726335 C38.3612853,15.9726335 43.4537618,36.5099206 38.4749216,57.6844365 C38.4749216,57.6844365 64.1653605,7.45479961 104,15.9726335 C104,15.9726335 104,37.315089 104,80 L92.2374608,80 C100.851358,60.557721 102.294148,47.4764814 96.5658307,40.7562814 C87.9733542,30.6759813 64.3652038,43.7336779 46.0384013,80 C46.0384013,80 35.042581,80 13.0509404,80 C20.2520899,58.2201691 22.3727795,45.1389296 19.4130094,40.7562814 C14.9733542,34.1823091 2.08777429,47.6567157 -15.9208464,80 C-15.9208464,80 -18.6138976,80 -24,80 L-24,55.003681 Z"
                    fill="#000000"
                    mask="url(#supercrop3-mask-2)"
                />
            </g>
        </g>
    </svg>
);

const SUPER_CROPS = [
    <SuperCropOne key="supercrop-1" />,
    <SuperCropTwo key="supercrop-2" />,
    <SuperCropThree key="supercrop-3" />,
];

export default class RandomSuperCrop extends React.Component {
    static propTypes = {
        // String to mod against to create deterministic
        // supercrop. Will be converted to number.
        id: (props, propName, componentName) => {
            const prop = props[propName];
            const integerFormOfProp = parseInt(prop, 10);

            if (
                isUndefined(prop) &&
                !isNumber(prop && isString(prop) && !integerFormOfProp)
            ) {
                return new Error(
                    //eslint-disable-next-line prefer-template
                    'Invalid prop `' +
                        propName +
                        '` supplied to' +
                        ' `' +
                        componentName +
                        '`. Must be parseable to a number.',
                );
            }

            return null;
        },
    };

    static defaultProps = {
        id: 0,
    };

    render() {
        const { id } = this.props;
        const modularIndex = parseInt(id, 10) % SUPER_CROPS.length;

        return SUPER_CROPS[modularIndex];
    }
}
