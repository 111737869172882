export const DEFAULT_DESTINATION_EXPANSIONS = [
    'event_sales_status',
    'image',
    'primary_venue',
    'saves',
    'series',
    'ticket_availability',
    'primary_organizer',
];

export const DESTINATION_EVENTS_ENDPOINT = '/api/v3/destination/events/';
