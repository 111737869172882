import React from 'react';
import { RatingData } from '../../../types';
import { getPercentage } from '../../../utils';
import { LinearProgress } from '../linearProgress/LinearProgress';
import './RatingCounts.scss';

interface RatingCountsProps {
    ratingCounts: RatingData['break_down'];
    totalCount: number;
}

export const RatingCounts = ({
    ratingCounts,
    totalCount,
}: RatingCountsProps) => {
    return (
        <div className="ratig-counts-container">
            {ratingCounts.map(({ rating_star, count }) => {
                const label = `${rating_star}`;
                const percentageValue = getPercentage(count, totalCount);
                return (
                    <LinearProgress
                        key={rating_star}
                        value={percentageValue}
                        label={label}
                    />
                );
            })}
        </div>
    );
};
