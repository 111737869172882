import { sdkRequest } from '@eventbrite/http';
import { formatUrl } from 'url-lib';

import type { IOrganizerRatingResponse } from '../types/organizer';

const _getRatingsApiUrl = (organizerId: string) => {
    return `/api/v3/feedback-service/organizer/${organizerId}/rating`;
};

export const getRatingsData = async (organizerId: string) => {
    const response: IOrganizerRatingResponse = await sdkRequest(
        formatUrl(_getRatingsApiUrl(organizerId)),
    );

    return response?.ratings?.overall_experience;
};
