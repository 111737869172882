import { Modal } from '@eventbrite/eds-modal';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { RATING_DETAIL_MODAL_TRACKING_METHODS } from '../../constants';
import { ModalTypes, RatingData } from '../../types';
import { BetaTag } from '../shared/betaTag/BetaTag';
import { Disclaimer } from '../shared/disclaimer/Disclaimer';
import { RatingSummary } from '../shared/ratingSummary/RatingSummary';
import { TagsDropdown } from '../tagsDropdown/TagsDropdown';
import { AboutRatingCard } from './AboutRatingCard';
import { CommingSoonMessage } from './CommingSoonMessage';
import './RatingDetailModal.scss';

interface RatingDetailModalProps {
    isShown: boolean;
    onClose: Function;
    ratingData?: RatingData;
    organizerName?: string;
    type: ModalTypes;
    domain?: string;
    organizerId: string;
    trackCategory: string;
    trackingMethods: string[];
    displayOrganizerTags?: boolean;
}

export const RatingDetailModal = ({
    isShown,
    onClose,
    ratingData,
    organizerName,
    type,
    domain,
    organizerId,
    trackCategory,
    trackingMethods,
    displayOrganizerTags,
}: RatingDetailModalProps) => {
    const { rating, tag_summary } = ratingData || {};

    const trackHelperCenterLinkEvent = (link: string) => {
        trackingMethods &&
            trackingMethods.forEach((platform) => {
                return RATING_DETAIL_MODAL_TRACKING_METHODS[
                    platform as keyof typeof RATING_DETAIL_MODAL_TRACKING_METHODS
                ].HELPER_CENTER_CLICK(trackCategory, organizerId, link);
            });
    };

    const IS_ORGANIZER_MODAL = type === 'organizer';
    const IS_ATENDEE_MODAL = type === 'attendee';
    const INITIAL_TAGS_TO_DISPLAY = 3;
    const TAGS_DROPDOWN_COPY = {
        TITLE: gettext('Top feedback from attendees:').toString(),
        SUMMARY: gettext(
            'These are the %(INITIAL_TAGS_TO_DISPLAY)s  most frequent pieces of feedback selected by your attendees to describe your events. Right now, only you can view these. Eventually, they’ll be added to your events to help people determine if they’re right for them.',
            { INITIAL_TAGS_TO_DISPLAY },
        ).toString(),
    };
    const EMPTY_TAGS = !tag_summary?.length;
    return (
        <Modal
            noMinHeight={true}
            isShown={isShown}
            onClose={onClose}
            dangerouslySetContainerClassName={{ __className: 'rating-modal' }}
        >
            <div className="rating-modal__container" data-testid="rating-modal">
                <div className="rating-modal__title">
                    <h3 className="eds-text-hs">{gettext('Ratings')}</h3>
                    <BetaTag />
                </div>
                <h4 className="eds-text-bl">{organizerName}</h4>
                {ratingData && <RatingSummary ratingData={ratingData} />}
                {IS_ORGANIZER_MODAL && (
                    <>
                        <AboutRatingCard
                            rating={rating}
                            domain={domain}
                            trackHelperCenterLinkEvent={
                                trackHelperCenterLinkEvent
                            }
                        />
                        {displayOrganizerTags && !EMPTY_TAGS ? (
                            <TagsDropdown
                                tags={tag_summary}
                                initialDisplayAmount={INITIAL_TAGS_TO_DISPLAY}
                                addClassToContainer={{
                                    className: 'dropdown-container',
                                }}
                                title={TAGS_DROPDOWN_COPY.TITLE}
                                summary={TAGS_DROPDOWN_COPY.SUMMARY}
                            />
                        ) : null}
                        <CommingSoonMessage />
                    </>
                )}
                {IS_ATENDEE_MODAL && (
                    <Disclaimer
                        trackHelperCenterLinkEvent={trackHelperCenterLinkEvent}
                    />
                )}
            </div>
        </Modal>
    );
};
