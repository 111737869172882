import { gettext } from '@eventbrite/i18n';
import React from 'react';
import './RatingDetailModal.scss';

export const CommingSoonMessage = () => {
    return (
        <div className="comming-soon__container">
            <h5 className="eds-text-bm eds-text-weight--heavy">
                {gettext('Reviews coming soon')}
            </h5>
            <p className="eds-text-bs" data-testid="comming-soon-body">
                {gettext(
                    'We’re still working on how to share reviews from your attendees with you. Soon you’ll be able to check each review and respond. We’re doing our best to get it right. Please stay tuned!',
                )}
            </p>
        </div>
    );
};
