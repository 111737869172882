import { useEffect, useState } from 'react';
import { getDataAction } from '../actions/ratings';
import { GET_RATING_OBJECT_TRACKING_METHODS } from '../constants';
import { RatingData } from '../types';
import { getLocaleInfo } from '../utils';

export const useGetRatingObject = ({
    ratingSummary,
    organizerId,
    trackCategory,
    trackingMethods,
    useContextData,
}: {
    ratingSummary?: RatingData;
    organizerId: string;
    trackCategory?: string;
    trackingMethods?: string[];
    useContextData?: boolean;
}) => {
    const [ratingObject, setRatingObject] = useState(ratingSummary);
    const [isLoading, setIsLoading] = useState(!ratingSummary);

    const shouldMakeApiRequest =
        !ratingSummary && organizerId && !useContextData;

    const getData = async (organizer_id: string) => {
        const locale = getLocaleInfo();
        await getDataAction(
            organizer_id,
            trackCategory || '',
            setRatingObject,
            locale,
            trackingMethods || [],
        );
        setIsLoading(false);
    };

    const onGetDataFromContext = () => {
        if (useContextData) {
            setRatingObject(ratingSummary);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;
        trackingMethods &&
            !useContextData &&
            trackingMethods.forEach((platform) => {
                return GET_RATING_OBJECT_TRACKING_METHODS[
                    platform as keyof typeof GET_RATING_OBJECT_TRACKING_METHODS
                ].RATINGS_LOADED(trackCategory || '', organizerId);
            });
        if (shouldMakeApiRequest) {
            isMounted && getData(organizerId);
        }

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        onGetDataFromContext,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ratingSummary?.rating],
    );

    return { ratingObject, isLoading };
};
