/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'cookies-js';

import logger from '@eventbrite/client-logger';

import withFollowGDPRModalControls from '../HOC/withFollowGDPRModalControls';

import { setFollowOrganizerStatus as setFollowOrganizerStatusApi } from '@eventbrite/redux-destination';
import { track } from '@eventbrite/datalayer-library';

import { Button } from '@eventbrite/eds-button';
import { Modal } from '@eventbrite/eds-modal';

import {
    GDPR_ACCEPT_GA_ACTION,
    GDPR_REJECT_GA_ACTION,
    GDPR_CLOSE_GA_ACTION,
    GDPR_TITLE_TEXT,
    GDPR_SUBTITLE_TEXT,
    ACCEPT_TEXT,
    REJECT_TEXT,
} from '../constants';

const setFollowOrganizerPreference = (userId, isOptedIn) => {
    setFollowOrganizerStatusApi(userId, isOptedIn).catch((err) => {
        logger.warn(
            'Follow Organizer: Setting Follow Organizer Preferences Error',
            {
                userId,
                isOptedIn,
                err,
            },
        );
    });
};

const TitleHeader = ({ title }) => {
    let component = null;

    if (title) {
        component = (
            <h2
                className="
                    eds-dialog__title
                    eds-l-mar-top-10
                    eds-l-pad-hor-5
                    eds-align--center
                    eds-text--center
                    eds-text-hs
                    eds-text-weight--heavy"
                data-spec="eds-dialog__title"
            >
                {title}
            </h2>
        );
    }

    return component;
};

const MessageParagraph = ({ message }) => {
    let component = null;

    if (message) {
        component = (
            <p
                className="
                    eds-dialog__message
                    eds-l-mar-vert-5
                    eds-text-bm
                    eds-text--center
                    eds-text-color--ui-800
                    eds-l-pad-hor-10
                "
            >
                {message}
            </p>
        );
    }

    return component;
};

class ButtonBar extends Component {
    componentDidMount() {
        this._focusRef(this.okButtonRef);
    }

    _createRef = (name) => (ref) => {
        this[name] = ref;
    };

    _focusRef = (ref) => {
        if (ref) {
            ref.focus();
        }
    };

    render() {
        const {
            primaryType,
            primaryText,
            onPrimaryAction,
            secondaryType,
            secondaryText,
            onSecondaryAction,
            title,
        } = this.props;
        const buttonBarClasses = classNames(
            'eds-dialog__button-bar',
            'eds-align--center',
            'eds-text-bm',
            {
                'eds-dialog__button-bar--with-title': title,
            },
        );

        let secondaryButton = null;

        if (secondaryText) {
            secondaryButton = (
                <div className="eds-dialog__secondary-button eds-l-pad-hor-2">
                    <Button
                        onClick={onSecondaryAction}
                        style={secondaryType}
                        data-spec="eds-dialog__secondary-button"
                    >
                        {secondaryText}
                    </Button>
                </div>
            );
        }

        let component = null;

        if (primaryText) {
            component = (
                <div className="eds-l-mar-top-5 eds-l-mar-bot-10">
                    <div className="eds-dialog__button-bar eds-align--center">
                        <div className={buttonBarClasses}>
                            {secondaryButton}
                            <div className="eds-dialog__primary-button eds-l-pad-hor-2">
                                <Button
                                    onClick={onPrimaryAction}
                                    style={primaryType}
                                    data-spec="gdpr-dialog__primary-button"
                                    ref={this._createRef('okButtonRef')}
                                >
                                    {primaryText}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return component;
    }
}

class FollowGDPRModal extends Component {
    static propTypes = {
        userId: PropTypes.string.isRequired,
        isGDPRModalShown: PropTypes.bool.isRequired,
        onCloseGDPRModal: PropTypes.func.isRequired,
        trackGAEvent: PropTypes.func.isRequired,
        gaCategory: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isShown: props.isGDPRModalShown,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            isShown: props.isGDPRModalShown,
        });
    }

    componentDidMount() {
        track({
            eventName: 'FollowOrganizerGDPRModalRendered',
        });
    }

    _handleAccept() {
        const { trackGAEvent, gaCategory, userId, onCloseGDPRModal } =
            this.props;

        trackGAEvent({
            action: GDPR_ACCEPT_GA_ACTION,
            category: gaCategory,
        });
        setFollowOrganizerPreference(userId, true);
        onCloseGDPRModal();
    }

    _handleReject() {
        const { trackGAEvent, gaCategory, userId, onCloseGDPRModal } =
            this.props;

        trackGAEvent({
            action: GDPR_REJECT_GA_ACTION,
            category: gaCategory,
        });
        setFollowOrganizerPreference(userId, false);
        onCloseGDPRModal();
    }

    _handleOnClose() {
        const { trackGAEvent, gaCategory, onCloseGDPRModal } = this.props;
        track({
            eventName: 'FollowOrganizerGDPRModalDismissed',
        });
        trackGAEvent({
            action: GDPR_CLOSE_GA_ACTION,
            category: gaCategory,
        });
        onCloseGDPRModal();
    }

    render() {
        return (
            <Modal
                type="simple"
                isShown={this.state.isShown}
                onClose={this._handleOnClose.bind(this)}
                noMinHeight={true}
            >
                <TitleHeader title={GDPR_TITLE_TEXT} />
                <MessageParagraph message={GDPR_SUBTITLE_TEXT} />
                <ButtonBar
                    onPrimaryAction={this._handleAccept.bind(this)}
                    primaryText={ACCEPT_TEXT}
                    primaryType="link"
                    onSecondaryAction={this._handleReject.bind(this)}
                    secondaryText={REJECT_TEXT}
                    secondaryType="link"
                />
            </Modal>
        );
    }
}

export default withFollowGDPRModalControls(FollowGDPRModal);
