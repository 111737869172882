import { trackAnalyticsEvent } from '@eventbrite/site-analytics';

const MODAL_OPEN = 'ratingsModalOpen';
const MODAL_CLOSE = 'ratingsModalClose';
const GET_DATA_SUCCESS = 'ratingsGetDataSuccess';
const GET_DATA_ERROR = 'ratingsGetDataError';
const LOADED = 'ratingsLoaded';
const DISPLAYED = 'ratingsDisplayed';
const NOT_DISPLAYED = 'ratingsNotDisplayed';
const HELPER_CENTER_LINK_CLICK = 'ratingsHelpCenterLinkClick';

export const trackModalOpen = (category: string, organizerID: string) => {
    trackAnalyticsEvent({
        category,
        action: MODAL_OPEN,
        label: organizerID,
    });
};

export const trackModalClose = (category: string, organizerID: string) => {
    trackAnalyticsEvent({
        category,
        action: MODAL_CLOSE,
        label: organizerID,
    });
};

export const trackGetDataSuccess = (category: string, organizerID: string) => {
    trackAnalyticsEvent({
        category,
        action: GET_DATA_SUCCESS,
        label: organizerID,
    });
};
export const trackGetDataError = (
    category: string,
    organizerID: string,
    error: string,
) => {
    trackAnalyticsEvent({
        category,
        action: GET_DATA_ERROR,
        label: error,
        value: organizerID,
    });
};
export const trackRatingsLoaded = (category: string, organizerID: string) => {
    trackAnalyticsEvent({
        category,
        action: LOADED,
        label: organizerID,
    });
};
export const trackRatingsDisplayed = (
    category: string,
    rating: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    organizerId?: string,
) => {
    trackAnalyticsEvent({
        category,
        action: DISPLAYED,
        label: rating,
    });
};
export const trackRatingsNotDisplayed = (
    category: string,
    rating: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    organizerId?: string,
) => {
    trackAnalyticsEvent({
        category,
        action: NOT_DISPLAYED,
        label: rating,
    });
};

export const trackRatingHelperCenterClick = (
    category: string,
    organizerID: string,
) => {
    trackAnalyticsEvent({
        category,
        action: HELPER_CENTER_LINK_CLICK,
        label: organizerID,
    });
};
