import { Button } from '@eventbrite/eds-button';
import { GenericLazyString } from '@eventbrite/i18n';
import React, { SyntheticEvent } from 'react';

interface ToggleButtonProps {
    onToggle: Function;
    state: boolean;
    label: {
        hide: GenericLazyString;
        show: GenericLazyString;
    };
}

export const ToggleButton = ({ onToggle, state, label }: ToggleButtonProps) => {
    const handleToggle = (e: SyntheticEvent) => {
        e.preventDefault();
        onToggle?.(!state);
    };

    return (
        <Button
            style="none"
            onClick={handleToggle}
            data-testid="tag-toggle_button"
        >
            <span className="eds-link eds-text-bs eds-text-weight--heavy">
                {state ? label.hide : label.show}
            </span>
        </Button>
    );
};
