import React from 'react';
import { ORGANIZER_ABOUT_TYPES } from '../../constants';
import { OrganizerContext } from '../../context';
import { Organizer } from '../../types';
import { SocialWidgetRedesign } from '../SocialWidget';
import './OrganizerAbout.scss';
import { OrganizerSummary } from './OrganizerSummary';

export const OrganizerAboutContent: React.FunctionComponent = () => {
    const organizer = React.useContext(OrganizerContext) as Organizer;

    const hasSocialLinks =
        !!organizer.website || !!organizer.twitter || !!organizer.facebook;

    return (
        <>
            <OrganizerSummary organizer={organizer} />
            {hasSocialLinks && (
                <div className="organizer-profile-header__social-widget">
                    <SocialWidgetRedesign
                        website={organizer.website}
                        twitter={organizer.twitter}
                        facebook={organizer.facebook}
                    />
                </div>
            )}
        </>
    );
};

interface OrganizerAboutProps {
    type: typeof ORGANIZER_ABOUT_TYPES[number];
}

export const OrganizerAbout: React.FunctionComponent<OrganizerAboutProps> = (
    props,
) => {
    const { type } = props;

    return (
        <section
            className={
                type === 'mobile' ? 'eds-show-down-sw' : 'eds-show-up-mn'
            }
        >
            <OrganizerAboutContent />
        </section>
    );
};
