import { gettext } from '@eventbrite/i18n';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { a, useSpring, useSprings } from 'react-spring';
import { useMeasure } from 'react-use';
import { Tag } from '../../types';
import { ReviewTag } from '../reviewTag/ReviewTag';
import './TagsDropdown.scss';
import { ToggleButton } from './ToggleButton';

export interface TagsDropdownProps {
    title?: string;
    summary?: string;
    tags?: Tag[];
    initialDisplayAmount: number;
    defaultOpen?: boolean;
    animationDelay?: number;
    addClassToContainer?: {
        className?: string;
    };
}
export const toggleButtonLabel = {
    hide: gettext('Hide options'),
    show: gettext('View all options'),
};

export const TagsDropdown = ({
    tags,
    title,
    summary,
    initialDisplayAmount = 0,
    defaultOpen = false,
    animationDelay = 20,
    addClassToContainer,
}: TagsDropdownProps) => {
    const [isExpanded, setIsExpanded] = useState(defaultOpen);
    const [visibleTagList, setVisibleTagList] = useState<Tag[]>([]);
    const [hiddenTagList, setHiddenTagList] = useState<Tag[]>([]);

    const TAGS_AMOUNT = tags?.length || 0;

    const SHOULD_DISPLAY_TOGGLE_BUTTON = initialDisplayAmount < TAGS_AMOUNT;
    const ANIMATION_DELAY_VALUE = animationDelay;

    const [containerRef, { height: containerHeight }] = useMeasure();

    useEffect(() => {
        const VISIBLE_TAGS = tags?.slice(0, initialDisplayAmount);
        const HIDDEN_TAGS = tags?.slice(initialDisplayAmount, tags.length);

        setVisibleTagList(VISIBLE_TAGS || []);
        setHiddenTagList(HIDDEN_TAGS || []);
        //eslint-disable-next-line
    }, [initialDisplayAmount]);

    const animatedTags = useSprings(
        hiddenTagList.length,
        hiddenTagList.map((item, i) => ({
            item,
            delay: isExpanded
                ? i * ANIMATION_DELAY_VALUE
                : hiddenTagList.length * ANIMATION_DELAY_VALUE -
                  i * ANIMATION_DELAY_VALUE,
            opacity: isExpanded ? 1 : 0,
            y: isExpanded ? '0%' : '-10%',
            scale: isExpanded ? 1 : 0,
            from: {
                opacity: 0,
                scale: 1,
                y: '-10%',
            },
        })),
    );

    const { height } = useSpring({
        height: isExpanded ? containerHeight * TAGS_AMOUNT : containerHeight,
        from: {
            height: containerHeight,
        },
    });

    const addedClassName = addClassToContainer?.className || '';
    const containerClassNames = classnames('tag-dropdown', {
        [addedClassName]: addClassToContainer,
    });

    return (
        <div className={containerClassNames}>
            <h3
                className="tag-dropdown__title"
                data-testid="tag-dropdown__title"
            >
                {title}
            </h3>
            {summary ? (
                <p className="tag-dropdown__summary">{summary}</p>
            ) : null}

            <a.div
                className="tag-dropdown__container"
                data-testid="tag-dropdown__container"
                style={{
                    maxHeight: height.to((h: number) => `${h}px`),
                }}
            >
                <span ref={containerRef}>
                    {visibleTagList.map((tag) => {
                        const { display_text, id, display_count } = tag;
                        return (
                            <ReviewTag
                                id={id}
                                key={id}
                                displayText={display_text}
                                displayCount={display_count}
                                displayOnly
                            />
                        );
                    })}
                </span>

                {animatedTags.map(({ opacity, y }, i) => {
                    const { display_text, id, display_count } =
                        hiddenTagList[i];
                    return (
                        <a.div
                            key={id}
                            style={{
                                opacity,
                                transform: y.to((y) => `translateY(${y})`),
                            }}
                        >
                            <ReviewTag
                                id={id}
                                key={id}
                                displayText={display_text}
                                displayCount={display_count}
                                displayOnly
                            />
                        </a.div>
                    );
                })}
            </a.div>
            {SHOULD_DISPLAY_TOGGLE_BUTTON ? (
                <ToggleButton
                    state={isExpanded}
                    onToggle={setIsExpanded}
                    label={toggleButtonLabel}
                />
            ) : null}
        </div>
    );
};
