import { RatingData } from '@eventbrite/trust-signals-lib';
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useRef,
    useState,
} from 'react';
export interface TrustSignalsProviderProps {
    children?: any;
    enableDisplayRatings?: boolean;
}

type TrustSignalsContextValue = {
    followers: number;
    totalEvents: number;
    ratingData?: RatingData;
    enableDisplayRatings?: boolean;
    setFollowers: Dispatch<SetStateAction<number>>;
    setTotalEvents: Dispatch<SetStateAction<number>>;
    setRatingData: Dispatch<SetStateAction<RatingData | any>>;
};
const contextInitialState: TrustSignalsContextValue = {
    followers: 0,
    totalEvents: 0,
    ratingData: undefined,
    enableDisplayRatings: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    setFollowers: (followers) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    setTotalEvents: (totalEvents) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    setRatingData: (ratingData) => {},
};

const TrustSignalsContext = createContext(contextInitialState);

export const TrustSignalsProvider = (props: TrustSignalsProviderProps) => {
    const [followers, setFollowers] = useState(contextInitialState.followers);
    const [totalEvents, setTotalEvents] = useState(
        contextInitialState.totalEvents,
    );
    const [ratingData, setRatingData] = useState();
    const featureFlagRef = useRef(props.enableDisplayRatings);
    const enableDisplayRatings = featureFlagRef.current;

    return (
        <TrustSignalsContext.Provider
            value={{
                followers,
                totalEvents,
                ratingData,
                enableDisplayRatings,
                setTotalEvents,
                setFollowers,
                setRatingData,
            }}
        >
            {props.children}
        </TrustSignalsContext.Provider>
    );
};

export const UseTrustSignalsContext = () => useContext(TrustSignalsContext);
