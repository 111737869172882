import PropTypes from 'prop-types';

export const ITEMS_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
        displayName: PropTypes.node.isRequired,
        content: PropTypes.element,
        isSelected: PropTypes.bool,
    }),
);
export const FORMAT_TAB = 'tab';
export const FORMAT_PILL = 'pill';
export const DIRECTION_HORIZONTAL = 'horizontal';
export const DIRECTION_VERTICAL = 'vertical';

export const TAB_FORMAT_PROP_TYPE = PropTypes.oneOf([FORMAT_TAB, FORMAT_PILL]);
export const TAB_DIRECTION_PROP_TYPE = PropTypes.oneOf([
    DIRECTION_HORIZONTAL,
    DIRECTION_VERTICAL,
]);
