import React from 'react';
import { TabNavigationProvider } from './context';
import './TabNavigation.scss';

interface TabNavigationProps {
    breakPoints?: Record<string, number>;
}

export const TabNavigation: React.FunctionComponent<TabNavigationProps> = (
    props,
) => {
    return (
        <TabNavigationProvider breakPoints={props.breakPoints}>
            {props.children}
        </TabNavigationProvider>
    );
};
