import {
    addFollow,
    FollowButton,
    withFollow,
} from '@eventbrite/follow-organizer-ebui';
import { logEvent } from '@eventbrite/statsig';
import React, { useContext } from 'react';
import { ORGANIZER_FOLLOW_CLICK, ORGANIZER_UNFOLLOW_CLICK } from '../constants';
import { EnvContext, UserContext } from '../context';
import { Organizer } from '../types';
import { getLoginExperiment } from '../utils/statsig';
import './FollowOrganizer.scss';

interface FollowOrganizerWidgetProps {
    gaSettings: object;
    gaCategory: string;
    organizers: Organizer[];
    isAuthenticated: boolean;
    loginOptions: object;
    size?: string;
    __containerClassName?: string;
    renderFollowCount?: (
        followCount: string,
    ) => React.ReactNode | JSX.Element | null;
}

const EnhancedFollowButton = withFollow(FollowButton);
const FollowOrganizerWidget: React.FunctionComponent<
    FollowOrganizerWidgetProps
> = (props) => {
    return (
        <EnhancedFollowButton
            size={props.size}
            isOnlyButton={true}
            organizerId={props.organizers[0]}
            renderFollowCount={props.renderFollowCount}
            __containerClassName={props.__containerClassName}
            onFollow={() => {
                logEvent(ORGANIZER_FOLLOW_CLICK);
            }}
            onUnFollow={() => {
                logEvent(ORGANIZER_UNFOLLOW_CLICK);
            }}
        />
    );
};

interface FollowOrganizerProps {
    organizer: Organizer;
    size?: string;
    containerClassName?: string;
    userId: string;
    renderFollowCount?: (
        followCount: string,
    ) => React.ReactNode | JSX.Element | null;
}

const EnhancedFollowOrganizerWidget = addFollow(FollowOrganizerWidget);
export const FollowOrganizer: React.SFC<FollowOrganizerProps> = (props) => {
    const { isAuthenticated } = useContext(UserContext);
    const { gaSettings } = useContext(EnvContext);
    const userId = props.userId;
    const loginOptions = {
        isLoginExperimentActive: getLoginExperiment(),
    };

    return (
        <EnhancedFollowOrganizerWidget
            isAuthenticated={isAuthenticated}
            gaSettings={gaSettings}
            gaCategory={'organizer'}
            organizers={[props.organizer.id]}
            renderFollowCount={props.renderFollowCount}
            loginOptions={loginOptions}
            size={props.size}
            userId={userId}
            __containerClassName={`${props.containerClassName}`}
        />
    );
};
