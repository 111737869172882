/**
 *
 * @param obj Record<string,number>
 * @returns string
 *
 * Iterates over a given object and will return the key associated
 * to the highest value. Leverages a simple reducer pattern
 * and also includes logic to ensure only numbers are operated
 * against.
 */
export const getMaxKey = (obj: Record<string, number>): string => {
    const activeIndex = Object.keys(obj).reduce(
        (prevKey: string, currentKey: string) => {
            const currentVal = obj[currentKey];
            const prevVal = obj[prevKey];

            if (typeof currentVal === 'number' && typeof prevVal === 'number') {
                return currentVal > prevVal ? currentKey : prevKey;
            }

            if (
                typeof currentVal === 'number' &&
                !(typeof prevVal === 'number')
            ) {
                return currentKey;
            }

            if (typeof prevVal === 'number') {
                return prevKey;
            }

            return prevKey;
        },
    );

    return activeIndex;
};
