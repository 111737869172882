import { Button } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import React, { useRef, useState } from 'react';
import { trackClickOnOrganizerEventsTags } from '../tracking/analytics';
import {
    DiscoveryEventsFromView,
    organizerPastEvent,
    organizerUpcomingEvent,
} from '../types';
import { formatReturnCountLimit } from '../utils';
import { EventRenderer } from './EventRenderer';

interface OrganizerEventsInternalProps {
    events: DiscoveryEventsFromView;
}

export enum EventStates {
    'future',
    'past',
}

const handleOnClick = (callback: Function, state: EventStates) => {
    trackClickOnOrganizerEventsTags(state);
    callback(state);
};

export const OrganizerEvents: React.FunctionComponent<
    OrganizerEventsInternalProps
> = (props) => {
    const { future_events, past_events, ...eventMetaData } = props.events;
    const [eventType, setEventType] = useState(EventStates.future);
    const hasRenderedPastEvents = useRef(false);

    if (eventType === EventStates.past && !hasRenderedPastEvents.current) {
        hasRenderedPastEvents.current = true;
    }

    return (
        <>
            <section className="organizer-events__event-controls">
                <Button
                    onClick={() =>
                        handleOnClick(setEventType, EventStates.future)
                    }
                    style={
                        eventType === EventStates.future ? 'follow' : 'neutral'
                    }
                    isFollowing={eventType === EventStates.future}
                >
                    {gettext('Upcoming (%(numFutureEvents)s)', {
                        numFutureEvents: formatReturnCountLimit(
                            eventMetaData.num_future_events,
                        ),
                    })}
                </Button>
                <Button
                    onClick={() =>
                        handleOnClick(setEventType, EventStates.past)
                    }
                    style={
                        eventType === EventStates.past ? 'follow' : 'neutral'
                    }
                    isFollowing={eventType === EventStates.past}
                >
                    {gettext('Past (%(numPastEvents)s)', {
                        numPastEvents: formatReturnCountLimit(
                            eventMetaData.num_past_events,
                        ),
                    })}
                </Button>
            </section>
            <div
                data-testid="organizer-profile__future-events"
                className={eventType === EventStates.future ? '' : 'eds-hide'}
            >
                <EventRenderer
                    eventMetaData={eventMetaData}
                    events={future_events}
                    removeSaveHandlers={false}
                    type="future"
                    bucketLabel={organizerUpcomingEvent}
                />
            </div>
            {hasRenderedPastEvents ? (
                <div
                    data-testid="organizer-profile__past-events"
                    className={eventType === EventStates.past ? '' : 'eds-hide'}
                >
                    <EventRenderer
                        eventMetaData={eventMetaData}
                        events={past_events}
                        removeSaveHandlers={true}
                        type="past"
                        bucketLabel={organizerPastEvent}
                    />
                </div>
            ) : null}
        </>
    );
};
