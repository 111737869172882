import React, { ReactNode } from 'react';
import './MainSection.scss';

interface MainSectionProps {
    headerNode: ReactNode;
}

export const MainSection: React.FunctionComponent<MainSectionProps> = (
    props,
) => {
    return (
        <section className="organizer-profile__section">
            <h2 className="organizer-profile__section--header eds-text-hm">
                {props.headerNode}
            </h2>
            <div className="organizer-profile__section--content">
                {props.children}
            </div>
        </section>
    );
};
