import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { onClickOutsideHOC } from '@eventbrite/eds-utils';
import { ALIGN_PROP_TYPE, ALIGN_FULL } from './constants';

import './dropdown.scss';

/**
 * A Dropdown is a simple component that contains arbitrary content. It is intended to be used as the dropdown for AutocompleteField or DropdownMenu.
 */
export class Dropdown extends React.PureComponent {
    static propTypes = {
        /**
         * Contents of the dropdown
         */
        children: PropTypes.node.isRequired,

        /**
         * How the dropdown should be horizontally positioned relative its container
         */
        align: ALIGN_PROP_TYPE,

        /**
         * Whether or not the dropdown should "drop up"
         */
        dropUp: PropTypes.bool,

        /**
         * Callback triggered by clicking outside of the dropdown
         */
        onClickOutside: PropTypes.func,
    };

    static defaultProps = {
        align: ALIGN_FULL,
        dropUp: false,
    };

    render() {
        const { children, align, dropUp } = this.props;
        const className = classNames(
            'eds-dropdown',
            'eds-fx--fade-in',
            `eds-dropdown--align-${align}`,
            dropUp ? 'eds-dropdown--drop-up' : 'eds-dropdown--drop-down',
        );

        return (
            <div className={className} data-spec="dropdown">
                {children}
            </div>
        );
    }
}

export default onClickOutsideHOC(Dropdown);
