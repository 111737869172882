import {
    getConfig,
    getExperiment,
    getLayer,
    initializeSdk,
    logEvent,
} from '@eventbrite/statsig';
import { addPageToEvent, getDomain } from '../utils';

export const STATSIG_EVENTS = {
    MODAL_OPEN: 'Ratings - Click - Component CTA',
    MODAL_CLOSE: 'Ratings modal - Click - Close Button',
    GET_DATA_SUCCESS: 'Ratings - Network Response - Success',
    GET_DATA_ERROR: 'Ratings - Network Response - Error',
    LOADED: 'Ratings - Loaded - Component',
    DISPLAYED: 'Ratings - Displayed - Component',
    NOT_DISPLAYED: 'Ratings - NotDisplayed - Component',
    LINK_HELPER_CENTER_CLICKED: 'Ratings modal - Click - Helper center link',
};

export const initializeStatsigSdk = async (organizerID: string) => {
    await initializeSdk({
        user: {
            custom: {
                organizerId: organizerID,
                ebDomain: getDomain(),
            },
        },
    });
};

export const logModalOpen = (eventPage: string, organizerId: string) => {
    logEvent(getEventName(eventPage, 'MODAL_OPEN'), null, { organizerId });
};

export const logModalClose = (eventPage: string, organizerId: string) => {
    logEvent(getEventName(eventPage, 'MODAL_CLOSE'), null, { organizerId });
};

export const logGetDataSuccess = (eventPage: string, organizerId: string) => {
    logEvent(getEventName(eventPage, 'GET_DATA_SUCCESS'), null, {
        organizerId,
    });
};

export const logGetDataError = (
    eventPage: string,
    organizerId: string,
    error: string,
) => {
    logEvent(getEventName(eventPage, 'GET_DATA_ERROR'), null, {
        organizerId,
        error,
    });
};

export const logRatingsLoaded = (eventPage: string, organizerId: string) => {
    logEvent(getEventName(eventPage, 'LOADED'), null, { organizerId });
};

export const logRatingsDisplayed = (
    eventPage: string,
    rating: number,
    organizerId: string,
) => {
    logEvent(getEventName(eventPage, 'DISPLAYED'), rating, { organizerId });
};

export const logRatingsNotDisplayed = (
    eventPage: string,
    rating: number,
    organizerId: string,
) => {
    logEvent(getEventName(eventPage, 'NOT_DISPLAYED'), rating, { organizerId });
};

export const logRatingHelperCenterClick = (
    eventPage: string,
    organizerId: string,
    link: string,
) => {
    logEvent(getEventName(eventPage, 'LINK_HELPER_CENTER_CLICKED'), link, {
        organizerId,
    });
};

export const isDisplayRatingExperientEnabled = (experimentData: any) => {
    let value: boolean | string = false;
    if (experimentData.layerName) {
        value = getLayer(
            experimentData.layerName,
            experimentData.parameterName,
            false,
        );
    } else if (experimentData.experimentName) {
        value = getExperiment(
            experimentData.experimentName,
            experimentData.parameterName,
            false,
        );
    }
    return value === true || value === 'true';
};

export const getConfigStatsig = (configName: string): any => {
    return getConfig(configName);
};

export const getEventName = (eventPage: string, optionName: string): string => {
    return addPageToEvent(
        STATSIG_EVENTS[optionName as keyof typeof STATSIG_EVENTS] as string,
        eventPage,
    );
};
