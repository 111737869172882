import { CreatorCollectionCard } from '@eventbrite/creator-collection-card';
import { sdkRequest } from '@eventbrite/http';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import {
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryResult,
} from '@tanstack/react-query';
import React, { ReactNode } from 'react';
import { useIntersection } from 'react-use';
import { useOrganizerContext } from '../context';
import {
    CamelOrganizerCollection,
    Organizer,
    OrganizerCollection,
    OrganizerCollections as OrganizerCollectionsInterface,
} from '../types';
import './OrganizerCollections.scss';
import { ShowMoreEvents } from './ShowMoreEvents';

interface OrganizerCollectionsProps {
    organizerCollections: OrganizerCollectionsInterface<OrganizerCollection>;
}

const transformOrganizerCollection = (
    collection: OrganizerCollection,
): CamelOrganizerCollection => {
    return deepKeysToCamel(collection) as CamelOrganizerCollection;
};

const fetchOrganizerCollections: QueryFunction<
    OrganizerCollectionsInterface<CamelOrganizerCollection>
> = async ({ queryKey, pageParam = 1 }) => {
    //eslint-disable-next-line
    const [_, organizerId] = queryKey;

    const organizerCollections = await sdkRequest(
        `/api/v3/organizers/${organizerId}/collections/?status=live&expand=image,event_count&page=${pageParam}`,
    );

    const camelCollections = organizerCollections.collections.map(
        transformOrganizerCollection,
    );

    return {
        ...organizerCollections,
        collections: camelCollections,
    };
};

const THRESHOLD = [0, 0.1, 1];
const useIsVisible = (ref: ReactNode) => {
    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '400px 0px 0px 0px',
        threshold: THRESHOLD,
    });
    const [isVisible, setVisible] = React.useState(false);

    if (intersection && intersection.intersectionRatio > 0) {
        if (!isVisible) setVisible(true);
    } else {
        if (isVisible) setVisible(false);
    }

    return isVisible;
};

const useOrganizerCollections = (
    collections: OrganizerCollectionsInterface<OrganizerCollection>,
    organizer: Organizer,
    isVisible: boolean,
): UseInfiniteQueryResult<
    OrganizerCollectionsInterface<CamelOrganizerCollection>
> => {
    const organizerId = organizer.id;
    const isEnabled = React.useRef(false);

    if (!isEnabled.current && isVisible) {
        isEnabled.current = true;
    }

    return useInfiniteQuery<
        OrganizerCollectionsInterface<CamelOrganizerCollection>,
        Error
    >(['organizer-collection', organizerId], fetchOrganizerCollections, {
        getNextPageParam: (prevPage) => {
            if (prevPage.pagination.has_more_items)
                return prevPage.pagination.page_number + 1;
        },
        initialData: {
            pages: [
                {
                    ...collections,
                    collections: collections.collections.map(
                        transformOrganizerCollection,
                    ),
                },
            ],
            pageParams: [{ pagination: collections.pagination }],
        },
        enabled: isEnabled.current,
        staleTime: 0,
        refetchOnMount: true,
    });
};

export const OrganizerCollections: React.FunctionComponent<
    OrganizerCollectionsProps
> = (props) => {
    const collectionRef = React.useRef(null);
    const isVisible = useIsVisible(collectionRef);

    const organizer = useOrganizerContext() as Organizer;
    const { data, fetchNextPage, hasNextPage, isLoading } =
        useOrganizerCollections(
            props.organizerCollections,
            organizer,
            isVisible,
        );

    return (
        <div
            className="organizer-profile__organizer-collections"
            ref={collectionRef}
        >
            <ul className="organizer-profile__organizer-collections__grid">
                {data?.pages.map(({ collections }) =>
                    collections.map((collection) => (
                        <li key={collection.id}>
                            <CreatorCollectionCard
                                {...collection}
                                slug={collection?.relativeUrl}
                                imageUrl={collection?.image?.url}
                                collectionImageBackgroundColor={
                                    collection?.image?.edgeColor
                                }
                                numUpcomingEvents={
                                    collection?.eventCount
                                        ?.currentFuturePublished
                                }
                            />
                        </li>
                    )),
                )}
            </ul>
            <div>
                <ShowMoreEvents
                    hasMore={!!hasNextPage}
                    isLoading={isLoading}
                    onClick={fetchNextPage}
                />
            </div>
        </div>
    );
};
