import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { HELP_CENTER_ATTENDEE_URL } from '../../../constants';
import './Disclaimer.scss';

const DISCLAIMER_TEXT = {
    FIRST_PARAGRAPH:
        'Ratings are here! In this beta version, we’re only displaying ratings for organizers who earned an average of 4 stars or more.',
    SECOND_PARAGRAPH:
        'We’re still building how we’ll display reviews and more ratings. Thanks for your patience.',
};

const HELPER_CENTER_LINK_TEXT = gettext('Learn more in the Help center.');

export const Disclaimer = ({
    trackHelperCenterLinkEvent,
}: {
    trackHelperCenterLinkEvent?: Function;
}) => {
    const trackEvent = () => {
        trackHelperCenterLinkEvent &&
            trackHelperCenterLinkEvent(HELP_CENTER_ATTENDEE_URL);
    };

    return (
        <div className="disclaimer__container">
            {Object.entries(DISCLAIMER_TEXT).map(([paragraph, content]) => {
                return (
                    <p
                        key={paragraph}
                        className="eds-text-bm eds-text-weight--regular"
                    >
                        {content}
                    </p>
                );
            })}

            <a
                href={HELP_CENTER_ATTENDEE_URL}
                className="eds-text-bm helper-text--link eds-link eds-text-weight--heavy"
                target="_blank"
                rel="noopener noreferrer"
                onClick={trackEvent}
            >
                {HELPER_CENTER_LINK_TEXT}
            </a>
        </div>
    );
};
