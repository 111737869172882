import React, { CSSProperties } from 'react';
import './BetaTag.scss';

interface BetaTagProps {
    color?: CSSProperties['color'];
    content?: string;
}

export const BetaTag = ({
    color = '#d1410c',
    content = 'Beta',
}: BetaTagProps) => {
    const tagStyle = {
        color: `${color}`,
        borderColor: `${color}`,
    };

    return (
        <p className="beta-tag__container" style={tagStyle}>
            {content}
        </p>
    );
};
