import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { getWindowObject, HAS_WINDOW } from '@eventbrite/feature-detection';
import { NotificationBar } from '@eventbrite/eds-notification';
import { TYPE_NEUTRAL, TYPE_ERROR } from '@eventbrite/eds-notification';
import { Check } from '@eventbrite/eds-iconography';
import { AlertChunky } from '@eventbrite/eds-iconography';

import withNotificationControls from '../HOC/withNotificationControls';
import {
    getSuccessNotificationText,
    getFollowErrorNotification,
    getUnFollowErrorNotification,
    GET_USER_ERROR_NOTIFICATION,
    GET_ORG_ERROR_NOTIFICATION,
    GLOBAL_NAV_HEIGHT,
    ERROR_FOLLOW,
    ERROR_UNFOLLOW,
    ERROR_ORG_INFO,
    ERROR_USER_INFO,
} from '../constants';

const NOTIFICATION_BOUNCE_INTERVAL = 50;

const SuccessNotificationBarContent = ({ name }) => {
    //Need to set dangerousInnterHTML because there is markup in the translated string,
    //which would otherwise be encoded. No user controlled data is coming to this function
    //so is quite safe.
    const dangerousContent = getSuccessNotificationText(name);

    return <NotificationBarContent dangerousContent={dangerousContent} />;
};

const ErrorNotificationBarContent = ({ name, errorActionType }) => {
    const ERROR_ACTION_MAP = {
        [ERROR_FOLLOW]: getFollowErrorNotification(name),
        [ERROR_UNFOLLOW]: getUnFollowErrorNotification(name),
        [ERROR_USER_INFO]: GET_USER_ERROR_NOTIFICATION,
        [ERROR_ORG_INFO]: GET_ORG_ERROR_NOTIFICATION,
    };

    return (
        <NotificationBarContent
            dangerousContent={ERROR_ACTION_MAP[errorActionType]}
        />
    );
};

const NotificationBarContent = ({ dangerousContent }) => (
    <span
        className="eds-text-bm"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: dangerousContent }}
    />
);

class FollowNotification extends PureComponent {
    static propTypes = {
        /*
         * Wether the notification should be shown or not
         */
        isNotificationShown: PropTypes.bool.isRequired,
        /*
         * Callback for when the notification is dismissed
         */
        hideNotification: PropTypes.func,
        /*
         * Type of notification to render
         */
        type: PropTypes.oneOf([TYPE_NEUTRAL, TYPE_ERROR]),
        /*
         * Name of the organizer that was followed
         */
        organizerName: PropTypes.string,
        /*
         * Error type to determine copy
         */
        errorActionType: PropTypes.string,
        /*
         * Offset with the top of its container
         */
        topOffset: PropTypes.number,
    };

    static defaultProps = {
        type: 'neutral',
    };

    constructor(props) {
        super(props);

        this.state = {
            top: this._getTopOffset(),
        };
    }

    componentDidMount() {
        const addEventListener = getWindowObject('addEventListener');

        this._throttledHandler = throttle(
            this._updateHeight,
            NOTIFICATION_BOUNCE_INTERVAL,
            { leading: true },
        );

        addEventListener('scroll', this._throttledHandler);

        this._updateHeight();
    }

    componentWillUnmount() {
        const removeEventListener = getWindowObject('removeEventListener');

        if (removeEventListener) {
            removeEventListener('scroll', this._throttledHandler);
        }
    }

    _getTopOffset = () =>
        this.props.topOffset !== undefined
            ? this.props.topOffset
            : GLOBAL_NAV_HEIGHT;

    _updateHeight = () => {
        if (HAS_WINDOW) {
            const scrollDelta = Math.max(
                0,
                this._getTopOffset() - window.pageYOffset,
            );

            this.setState({ top: scrollDelta });
        }
    };

    render() {
        const {
            type,
            hideNotification,
            organizerName,
            errorActionType,
            isNotificationShown,
        } = this.props;
        const { top } = this.state;
        const isSuccessNotification = type === TYPE_NEUTRAL;

        return (
            isNotificationShown && (
                <div
                    className="following-notification eds-fx--fade-in-down"
                    style={{
                        top: `${top}px`,
                        position: 'fixed',
                        left: 0,
                        width: '100%',
                        zIndex: 10000,
                        transition: '100ms',
                    }}
                    data-spec="following-organizer-notification"
                >
                    <NotificationBar
                        type={type}
                        isHidden={false}
                        iconType={
                            isSuccessNotification ? <Check /> : <AlertChunky />
                        }
                        hasCloseButton={true}
                        onClose={hideNotification}
                    >
                        {isSuccessNotification ? (
                            <SuccessNotificationBarContent
                                name={organizerName}
                            />
                        ) : (
                            <ErrorNotificationBarContent
                                name={organizerName}
                                errorActionType={errorActionType}
                            />
                        )}
                    </NotificationBar>
                </div>
            )
        );
    }
}

export default withNotificationControls(FollowNotification);
