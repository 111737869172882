import React, { useEffect } from 'react';

import classnames from 'classnames';

import { getShortenedNumber } from '@eventbrite/eds-utils';
import { gettext } from '@eventbrite/i18n';
import { formatReturnCountLimit, getFormattedNumber } from '../utils';

import {
    useSurfacingExperimentContext,
    UseTrustSignalsContext,
} from '../context';

import { GA_PROFILE_CATEGORY, TOTAL_RATING_THRESHOLD } from '../constants';

import { RatingData, RatingDisplay } from '@eventbrite/trust-signals-lib';
import RatingStarIcon from './RatingStarIcon';
import { SignalDisplay } from './SignalDisplay';

import './OrganizerTrustSignalsVariant.scss';

import type { Organizer } from '../types';
import type { OrganizerTrustSignalsVariants } from '../types/organizer';
import type { TSignalContentList } from './SignalDisplay';

export interface OrganizerTrustSignalsVariantProps {
    followCount: number;
    totalEvents: number;
    organizer: Organizer;
    variant?: OrganizerTrustSignalsVariants;
    ratingSummary?: RatingData;
}

const _addRatingSignalSafe = (
    signalsData: TSignalContentList,
    starRatingCount: number,
    totalRatingCount: number,
) => {
    if (totalRatingCount > TOTAL_RATING_THRESHOLD) {
        signalsData.splice(0, 0, {
            id: 'star-rating',
            countEl: (
                <div className="eds-align--center">
                    <RatingStarIcon />
                    <p>{starRatingCount.toFixed(1)}</p>
                </div>
            ),
            title: `${gettext('Ratings')} (${getFormattedNumber(
                totalRatingCount,
            )})`,
        });
    }
};

const _getSignalElList = (
    followCount: number,
    totalEvents: number,
    starRatingCount: number,
    totalRatingCount: number,
    variant: OrganizerTrustSignalsVariants,
) => {
    // prettier-ignore
    const followCountTitle = variant === 'condensed' ? gettext('followers') : gettext('Followers');
    // prettier-ignore
    const totalEventsTitle = variant === 'condensed' ? gettext('events') : gettext('Total events');

    const _signalElList: TSignalContentList = [
        {
            id: 'follow-count',
            countEl: getShortenedNumber(followCount),
            title: followCountTitle,
        },
        {
            id: 'total-events',
            countEl: formatReturnCountLimit(totalEvents),
            title: totalEventsTitle,
        },
    ];

    _addRatingSignalSafe(_signalElList, starRatingCount, totalRatingCount);

    return _signalElList;
};

export const OrganizerTrustSignalsVariant = ({
    followCount,
    totalEvents,
    organizer,
    variant = 'regular',
    ratingSummary,
}: OrganizerTrustSignalsVariantProps) => {
    const [showLoader, setShowLoader] = React.useState(true);
    // prettier-ignore
    const { starRatingCount, totalRatingCount } = useSurfacingExperimentContext();
    const { setRatingData, enableDisplayRatings } = UseTrustSignalsContext();

    const _signalElList = _getSignalElList(
        followCount,
        totalEvents,
        starRatingCount,
        totalRatingCount,
        variant,
    );

    const signalsContainerClassNames = classnames(
        'organizer-trust-signals__container',
        { condensed: variant === 'condensed' },
        { 'loading-container': showLoader },
    );

    useEffect(() => {
        setShowLoader(!followCount && !totalEvents);
    }, [followCount, totalEvents]);

    if (showLoader) {
        return <div className={signalsContainerClassNames} />;
    }

    return (
        <div className={signalsContainerClassNames}>
            {enableDisplayRatings && (
                <RatingDisplay
                    organizerData={{
                        organizerName: organizer.name,
                        organizerId: organizer.id,
                    }}
                    size={variant === 'condensed' ? 'xsmall' : 'medium'}
                    onGetRatingData={setRatingData}
                    trackCategory={GA_PROFILE_CATEGORY}
                    ratingSummary={ratingSummary}
                    filterRating
                />
            )}
            {_signalElList.map((el) => {
                if (!el.countEl || el.countEl === '0') return null;
                return (
                    <SignalDisplay
                        key={el.id}
                        countEl={el.countEl}
                        title={el.title}
                        variant={variant}
                    />
                );
            })}
        </div>
    );
};
