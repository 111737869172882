import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Button } from '@eventbrite/eds-button';

import { TABBABLE_INDEX, UNTABBABLE_INDEX } from '@eventbrite/eds-hot-keys';
import { getTabId, getPanelId } from '../util';

import '../tabs.scss';
import { Icon } from '@eventbrite/eds-icon';

export default class TabItem extends PureComponent {
    componentDidUpdate(prevProps) {
        const { isSelected: wasSelected } = prevProps;
        const { isSelected } = this.props;

        if (!wasSelected && isSelected) {
            this._selectedTab.focus();
        }
    }

    _getRef(ref) {
        this._selectedTab = ref;
    }

    render() {
        const { value, displayName, onSelected, isSelected, isDisabled, icon } =
            this.props;

        const tabItemClasses = classNames(
            'eds-tabs__item',
            'eds-l-pad-hor-2',
            'eds-l-md-pad-hor-3',
            'eds-l-mw-pad-hor-4',
            'eds-l-ln-pad-hor-4',
            'eds-l-lg-pad-hor-4',
            'eds-l-lw-pad-hor-4',
            {
                'eds-tabs__item--selected': isSelected,
                'eds-tabs__item--disabled': isDisabled,
            },
        );

        const tabItemLinkClasses = classNames(
            'eds-tabs__item-link',
            'eds-l-pad-vert-3',
        );

        const tabIndex = isSelected ? TABBABLE_INDEX : UNTABBABLE_INDEX;
        let controlledPanel;

        if (isSelected) {
            controlledPanel = getPanelId(value);
        }

        return (
            <li
                id={getTabId(value)}
                className={tabItemClasses}
                role="tab"
                aria-selected={isSelected}
                aria-controls={controlledPanel}
            >
                <Button
                    ref={this._getRef.bind(this)}
                    style="none"
                    __containerClassName={tabItemLinkClasses}
                    onClick={onSelected}
                    data-spec={`tab-link--${value}`}
                    data-automation={`tab-link--${value}`}
                    tabIndex={tabIndex}
                    disabled={isDisabled}
                >
                    {icon ? (
                        <span className="eds-tab-item-icon">
                            <Icon size="small" type={icon} />
                        </span>
                    ) : null}
                    <span className="eds-text-weight--heavy">
                        {displayName}
                    </span>
                </Button>
            </li>
        );
    }
}
