// Card and List Types
export const GRID_TYPE = 'grid';
export const LIST_TYPE = 'list';
export const CARD_TYPES = [GRID_TYPE, LIST_TYPE];
export const FOLLOW_LIST_STYLES = [GRID_TYPE, LIST_TYPE];

// Card Sizes
export const STANDARD_SIZE = 'standard';
export const MINI_SIZE = 'mini';
export const CARD_SIZES = [STANDARD_SIZE, MINI_SIZE];

// Button Sizes
export const STANDARD_BUTTON_SIZE = 'standard';
export const COMPACT_BUTTON_SIZE = 'compact';
export const MINI_BUTTON_SIZE = 'mini';
export const FOLLOW_BUTTON_SIZES = [
    COMPACT_BUTTON_SIZE,
    MINI_BUTTON_SIZE,
    STANDARD_BUTTON_SIZE,
];

// Button Styles
export const STANDARD_BUTTON_STYLE = 'standard';
export const SOLITARY_BUTTON_STYLE = 'solitary';
export const FOLLOW_BUTTON_STYLES = [
    STANDARD_BUTTON_STYLE,
    SOLITARY_BUTTON_STYLE,
];

export const GLOBAL_NAV_HEIGHT = 60;
