import { Badge } from '@eventbrite/eds-badge';
import { gettext } from '@eventbrite/i18n';
import { BetaTag } from '@eventbrite/trust-signals-lib';
import React from 'react';
import { createBreakpoint } from 'react-use';
import { FollowOrganizer } from '../components/FollowOrganizer';
import '../components/Main.scss';
import { MainSection } from '../components/MainSection';
import { OrganizerCollections } from '../components/OrganizerCollections';
import { OrganizerEvents } from '../components/OrganizerEvents';
import {
    TabNavigation,
    TabNavigationHeader,
    TabNavigationSection,
    TabNavLink,
    TabSection,
} from '../components/TabNavigation';
import {
    useOrganizerContext,
    useSurfacingExperimentContext,
    UseTrustSignalsContext,
} from '../context';
import {
    DiscoveryEventsFromView,
    Organizer,
    OrganizerCollection,
    OrganizerCollections as OrganizerCollectionsInterface,
} from '../types';
import { OrganizerAboutRedesign } from './OrganizerAbout';
import { OrganizerRatingSummary } from './OrganizerRatingSummary';
import { OrganizerTrustSignals } from './OrganizerTrustSignals';
import { OrganizerTrustSignalsVariant } from './OrganizerTrustSignalsVariant';

interface MainProps {
    organizer: Organizer;
    events: DiscoveryEventsFromView;
    organizerCollections: OrganizerCollectionsInterface<OrganizerCollection>;
    userId: string;
}
interface StickyHeaderContentProps {
    userId: string;
}
const useBreakPoint = createBreakpoint({ desktop: 792, mobile: 0 });

export const StickyHeaderContent: React.FunctionComponent<
    StickyHeaderContentProps
> = (props) => {
    const organizer = useOrganizerContext() as Organizer;
    const { followers, totalEvents, ratingData } = UseTrustSignalsContext();
    const { variant } = useSurfacingExperimentContext();
    const [size, setSize] = React.useState('mini');
    const breakPoint = useBreakPoint(useBreakPoint);
    const userId = props.userId;
    const TrustSignal =
        variant === 'control'
            ? OrganizerTrustSignals
            : OrganizerTrustSignalsVariant;

    React.useEffect(() => {
        const dynamicSize = breakPoint === 'mobile' ? 'mini' : 'standard';
        setSize(dynamicSize);
    }, [breakPoint]);

    return (
        <div className="sticky-header__extra-content">
            <h2 className="sticky-header__organizer-name eds-text-hs shorten-name">
                {organizer.name}
            </h2>
            <div className="sticky-header__follow-group">
                {ratingData && (
                    <TrustSignal
                        followCount={followers}
                        totalEvents={totalEvents}
                        organizer={organizer}
                        variant="condensed"
                        ratingSummary={ratingData}
                    />
                )}
                <FollowOrganizer
                    organizer={organizer}
                    size={size}
                    userId={userId}
                />
            </div>
        </div>
    );
};

export const Main: React.FunctionComponent<MainProps> = (props) => {
    const { ratingData } = UseTrustSignalsContext();
    const breakPoint = useBreakPoint(useBreakPoint);
    const hasCollections =
        props.organizerCollections.collections &&
        props.organizerCollections.collections.length > 0;

    const hasRating = !!ratingData?.rating;
    const userId = props.userId;
    const ratingSectionTitle = (
        <span>
            {gettext('Ratings')} <BetaTag />
        </span>
    );
    return (
        <section
            data-testid="organizer-profile"
            className="main eds-g-cell eds-g-cell-12-12 eds-g-cell-lg-10-12 eds-g-offset-lg-1-12 eds-g-cell--has-overflow"
        >
            <TabNavigation breakPoints={{ desktop: 792, mobile: 0 }}>
                <TabNavigationHeader
                    stickyNode={<StickyHeaderContent userId={userId} />}
                    stickyContentClassNames="organizer-profile__sticky-header--content eds-g-cell eds-g-cell-12-12 eds-g-cell-lg-10-12 eds-g-offset-lg-1-12 eds-g-cell--has-overflow"
                >
                    <TabNavLink
                        id="about"
                        containerClassName="eds-show-down-sw"
                        hide="desktop"
                    >
                        {gettext('About')}
                    </TabNavLink>
                    <TabNavLink id="events">{gettext('Events')}</TabNavLink>
                    {hasCollections && (
                        <TabNavLink id="collections">
                            <span style={{ paddingRight: '4px' }}>
                                {gettext('Collections')}
                            </span>
                            <Badge text={gettext('New')} bgColor="success" />
                        </TabNavLink>
                    )}
                    {hasRating && (
                        <TabNavLink id="ratings">
                            <span style={{ paddingRight: '4px' }}>
                                {gettext('Ratings')}
                            </span>
                            <Badge text={gettext('New')} bgColor="success" />
                        </TabNavLink>
                    )}
                </TabNavigationHeader>
                <TabNavigationSection>
                    <TabSection
                        hide="desktop"
                        containerClassName="eds-show-down-sw"
                        intersectionOptions={{
                            rootMargin: '-160px 0px 0px 0px',
                        }}
                    >
                        <OrganizerAboutRedesign type="mobile" />
                    </TabSection>
                    <TabSection
                        id="events"
                        containerClassName="organizer-profile__events-tab-section"
                    >
                        <MainSection headerNode={gettext('Events')}>
                            <OrganizerEvents events={props.events} />
                        </MainSection>
                    </TabSection>
                    {hasCollections && (
                        <TabSection
                            id="collections"
                            containerClassName="organizer-profile__events-tab-section"
                        >
                            <MainSection headerNode={gettext('Collections')}>
                                <OrganizerCollections
                                    organizerCollections={
                                        props.organizerCollections
                                    }
                                />
                            </MainSection>
                        </TabSection>
                    )}
                    {hasRating && (
                        <TabSection
                            id="ratings"
                            containerClassName="organizer-profile__events-tab-section"
                        >
                            <MainSection headerNode={ratingSectionTitle}>
                                <OrganizerRatingSummary
                                    ratingData={ratingData}
                                    breakPoint={breakPoint}
                                />
                            </MainSection>
                        </TabSection>
                    )}
                </TabNavigationSection>
            </TabNavigation>
        </section>
    );
};
