import { gettext } from '@eventbrite/i18n';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { HELP_CENTER_ORGANIZER_URL } from '../../constants';

export const AboutRatingCard = ({
    rating,
    domain = '',
    trackHelperCenterLinkEvent,
}: {
    rating?: number;
    domain?: string;
    trackHelperCenterLinkEvent: Function;
}) => {
    const [cardVariant, setCardVariant] = useState('');
    const [cardCopy, setCardCopy] = useState({
        FIRST_PARAGRAPH: '',
        SECOND_PARAGRAPH: '',
    });
    const [shouldShowDefault, setShouldShowDefault] = useState(true);

    const DOMAINS = ['evbqa.com', 'eventbrite.com'];
    const POSITIVE_RATING = rating && rating >= 4;
    const NEGATIVE_RATING = rating && rating < 4;

    useEffect(() => {
        setShouldShowDefault(!DOMAINS.includes(domain));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain]);

    const VARIANTS = {
        INFO: 'INFO',
        SUCCESS: 'SUCCESS',
        DEFAULT: 'DEFAULT',
    };

    type CardsCopyParagraphs = {
        FIRST_PARAGRAPH: string;
        SECOND_PARAGRAPH: string;
    };
    interface CardsCopy {
        POSITIVE_RATING: CardsCopyParagraphs;
        NEGATIVE_RATING: CardsCopyParagraphs;
        DEFAULT_MESSAGE: CardsCopyParagraphs;
    }

    const CARDS_COPY: CardsCopy = {
        POSITIVE_RATING: {
            FIRST_PARAGRAPH: gettext(
                'You’ve earned an average <span>%(rating)s star</span> rating from your attendees! That’s worth celebrating! 🎉',
                { rating },
            ).toString(),
            SECOND_PARAGRAPH: gettext(
                'We’ll share your rating on your event listings to get more people’s attention, so you can sell even more tickets. Keep up the great work!',
            ).toString(),
        },
        NEGATIVE_RATING: {
            FIRST_PARAGRAPH: gettext(
                'You’ve earned an average <span>%(rating)s star</span> rating from your attendees. At this time, we won’t share your rating on your event listings until it’s at least an average of 4 stars or more.',
                { rating },
            ).toString(),
            SECOND_PARAGRAPH: gettext(
                'We know what you do isn’t easy—and we wouldn’t exist without organizers like you. We encourage you to get feedback from your attendees for ideas to improve your rating.',
            ).toString(),
        },
        DEFAULT_MESSAGE: {
            FIRST_PARAGRAPH: gettext(
                'At this time, we won’t share your average rating publicly. We want this information to be useful for both you and your attendees. Please be patient while we work on getting it right.',
            ).toString(),
            SECOND_PARAGRAPH: '',
        },
    };

    useEffect(() => {
        if (shouldShowDefault) {
            setCardVariant(VARIANTS.DEFAULT);
            setCardCopy(CARDS_COPY.DEFAULT_MESSAGE);
        } else {
            if (POSITIVE_RATING) {
                setCardVariant(VARIANTS.SUCCESS);
                setCardCopy(CARDS_COPY.POSITIVE_RATING);
            }
            if (NEGATIVE_RATING) {
                setCardVariant(VARIANTS.INFO);
                setCardCopy(CARDS_COPY.NEGATIVE_RATING);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldShowDefault, POSITIVE_RATING, NEGATIVE_RATING]);

    const cardClassnames = classnames('about-rating__container', {
        'info-variant':
            cardVariant === VARIANTS.INFO || cardVariant === VARIANTS.DEFAULT,
        'success-variant': cardVariant === VARIANTS.SUCCESS,
    });

    const HELPER_CENTER_LINK_TEXT = gettext(
        'Learn more about ratings in the Help center.',
    );

    const CARD_TITLE = gettext('About ratings');

    const trackEvent = () => {
        trackHelperCenterLinkEvent &&
            trackHelperCenterLinkEvent(HELP_CENTER_ORGANIZER_URL);
    };

    return (
        <div className={cardClassnames}>
            <h4 className="eds-text-bl">{CARD_TITLE}</h4>
            {Object.entries(cardCopy).map(([paragraph, content]) => {
                return (
                    <p
                        key={paragraph}
                        className="eds-text-bs"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: content,
                        }}
                    />
                );
            })}
            <a
                href={HELP_CENTER_ORGANIZER_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="eds-text-bs helper-text--link eds-link"
                onClick={trackEvent}
            >
                {HELPER_CENTER_LINK_TEXT}
            </a>
        </div>
    );
};
