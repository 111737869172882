/* istanbul ignore file */
import { COLORS_JSON, GREYS_JSON } from '@eventbrite/eds-color';
export type iconSizes = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

const COLORS = { ...COLORS_JSON, ...GREYS_JSON };
export type colorsTypes = keyof typeof COLORS;
export type starColorsType = {
    base?: colorsTypes;
    hover?: colorsTypes;
    selected?: colorsTypes;
};

export type RatingSummaryVariants = 'horizontal' | 'vertical';

export type widgetSize = 'xsmall' | 'small' | 'medium';

type TagStyles = 'outlined' | 'default';
export interface ReviewTagProps {
    id: string;
    displayText: string;
    count?: number;
    displayCount?: string;
    onActiveToggle?: Function;
    isSelected?: boolean;
    isDisabled?: boolean;
    displayOnly?: boolean;
    symbol?: {
        codes: string[];
        label: string;
    };
    style?: TagStyles;
}

export interface Tag {
    id: string;
    display_text: string;
    is_selected?: boolean;
    count?: number;
    display_count?: string;
    symbol?: {
        codes: string[];
        label: string;
    };
}

export interface Category {
    id: string;
    display_text: string;
    order: number;
    tags: Tag[];
}

export interface RatingStarData {
    rating_star: number;
    count: number;
    percentage: number;
    display: string;
}
export interface RatingData {
    rating: number;
    total_count: {
        value: number;
        display: string;
    };
    break_down: RatingStarData[];
    tag_summary: Tag[];
}

export type TagId = Tag['id'];

export interface setProgressValueProps {
    element: string;
    value: number;
}

export type ModalTypes = 'attendee' | 'organizer';

const DisabledTrackingArray = ['GA', 'StatSig'] as const;
export type DisabledTrackingList = typeof DisabledTrackingArray[number][];
