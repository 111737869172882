import * as React from 'react';

const StarSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="star_svg__eds-icon--star_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="star_svg__eds-icon--star_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.6 14.1l-4-3.6h5.3L12 4.9l2.1 5.6h5.3l-4 3.6 1.5 5.6-4.9-3.2-4.9 3.2 1.5-5.6zM12 2L9.2 9.5H2l5.5 5-2 7.5 6.5-4.3 6.5 4.3-2-7.5 5.5-5h-7.2L12 2z"
        />
    </svg>
);

StarSvg.displayName = 'StarSvg';
export default StarSvg;
