import { getRatingData } from '../api/ratings';
import { GET_RATING_DATA_TRACKING_METHODS } from '../constants';
import { RatingData } from '../types';

export const getDataAction = async (
    organizer_id: string,
    trackCategory: string,
    setRatingObject: Function,
    locale: string,
    trackingMethods: string[],
) => {
    await getRatingData({ organizer_id, locale })
        .then((response: RatingData) => {
            setRatingObject(response);
            trackingMethods &&
                trackingMethods.forEach((platform) => {
                    return GET_RATING_DATA_TRACKING_METHODS[
                        platform as keyof typeof GET_RATING_DATA_TRACKING_METHODS
                    ].GET_DATA_SUCCESS(trackCategory, organizer_id);
                });
        })
        .catch((error: any) => {
            trackingMethods &&
                trackingMethods.forEach((platform) => {
                    return GET_RATING_DATA_TRACKING_METHODS[
                        platform as keyof typeof GET_RATING_DATA_TRACKING_METHODS
                    ].GET_DATA_ERROR(trackCategory, organizer_id, error);
                });
        });
};
