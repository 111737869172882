import React, { Attributes } from 'react';

export const TabNavigationSection: React.FunctionComponent = (props) => {
    return (
        <>
            {React.Children.map(props.children, (child, index) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { index } as Attributes);
                }
            })}
        </>
    );
};
