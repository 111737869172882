import React from 'react';
import { createBreakpoint } from 'react-use';
import { getMaxKey } from '../utils';

interface TabNavigationContextShape {
    setTabIntersection?: (index: number, val: number) => void;
    activeTab?: number;
    removeTab?: (index: number) => void;
    currentBreakpoint?: string | null;
}

interface TabNavigationContextProps {
    breakPoints?: Record<string, number>;
    activeTab?: number;
}

export const TabNavigationContext = React.createContext<
    Partial<TabNavigationContextShape>
>({});

export const useTabNavigationContext = () => {
    const value = React.useContext(TabNavigationContext);

    if (value === undefined) {
        throw new Error(
            'useTabNavigationContext must be used within TabNavigation',
        );
    }

    return value;
};

const useCreateDynamicBreakPoints = (
    breakPoints?: Record<string, number>,
): null | Function => {
    const ref = React.useRef(null);

    if (ref.current) {
        return ref.current;
    } else if (!breakPoints) {
        return ref.current;
    }

    ref.current = createBreakpoint(breakPoints);

    return ref.current;
};

export const TabNavigationProvider: React.FunctionComponent<
    TabNavigationContextProps
> = (props) => {
    const [activeTab, setActiveTab] = React.useState(props.activeTab);
    const valueRef = React.useRef<Record<string, number>>({});
    const useBreakPoints = useCreateDynamicBreakPoints(props.breakPoints);
    const currentBreakpoint = useBreakPoints?.();

    const handleSetActiveTab = React.useCallback(
        (index, val) => {
            valueRef.current[index] = val;
            const activeIndex = getMaxKey(valueRef.current);

            if (activeTab !== parseInt(activeIndex)) {
                setActiveTab(parseInt(activeIndex));
            }
        },
        [activeTab, setActiveTab],
    );

    const handleRemoveTab = React.useCallback(
        (index: number) => {
            const {
                //eslint-disable-next-line
                [index]: _notUsingThis,
                ...remainingVals
            } = valueRef.current;
            valueRef.current = remainingVals;

            if (Object.keys(valueRef.current).length > 0) {
                const newVal = getMaxKey(valueRef.current);
                if (activeTab !== parseInt(newVal)) {
                    setActiveTab(parseInt(newVal));
                }
            }
        },
        [activeTab],
    );

    return (
        <TabNavigationContext.Provider
            value={{
                activeTab,
                setTabIntersection: handleSetActiveTab,
                removeTab: handleRemoveTab,
                currentBreakpoint,
            }}
        >
            {props.children}
        </TabNavigationContext.Provider>
    );
};
