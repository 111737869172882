import { DiscoveryEvent } from '@eventbrite/event-renderer';

export interface DiscoveryEventsFromView {
    has_next_future_page: boolean;
    num_future_events: number;
    future_events: DiscoveryEvent[];
    has_next_past_page: boolean;
    num_past_events: number;
    past_events: DiscoveryEvent[];
}
export const organizerUpcomingEvent = 'Organizer Upcoming Event';
export const organizerPastEvent = 'Organizer Past Event';
