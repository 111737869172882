import { gettext } from '@eventbrite/i18n';
import { InlineAlert } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import React, { useEffect, useState } from 'react';
import {
    ORGANIZER_FOLLOW_MESSAGE_CLOSE,
    ORGANIZER_FOLLOW_MESSAGE_VIEW,
} from '../constants';

export const FollowMessageComponent = () => {
    const [hideAlert, setHideAlert] = useState(false);
    const [maxAlert, setMaxAlert] = useState(true);

    useEffect(() => {
        const messageCount = localStorage.getItem('messageCount');
        const count = messageCount ? parseInt(messageCount) : 0;

        if (count < 3) {
            setMaxAlert(false);
            localStorage.setItem('messageCount', (count + 1).toString());
        }
    }, []);

    useEffect(() => {
        if (!maxAlert) {
            logEvent(ORGANIZER_FOLLOW_MESSAGE_VIEW);
        }
    }, [maxAlert]);

    return (
        <>
            {!maxAlert && !hideAlert && (
                <div className="organizer-profile-header__container--alert-container">
                    <InlineAlert
                        variant="success"
                        description={gettext(
                            'Get notified when they add new events and view them in your feed.',
                        )}
                        closeOnClick={() => {
                            logEvent(ORGANIZER_FOLLOW_MESSAGE_CLOSE);
                            setHideAlert(true);
                        }}
                    />
                </div>
            )}
        </>
    );
};
