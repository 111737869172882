import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withFollowGDPRModalControls = (Comp) =>
    class WithFollowGDPRModalControlsWrapper extends Component {
        static propTypes = {
            getSharedState: PropTypes.func.isRequired,
            setSharedState: PropTypes.func.isRequired,
            onChangeSharedState: PropTypes.func.isRequired,
        };

        state = {
            isShown: false,
        };

        componentDidMount() {
            this.props.onChangeSharedState(this._handleStateChange);
        }

        _handleStateChange = () => {
            const { gdprModalShown } = this.props.getSharedState();
            const { isShown } = this.state;

            if (isShown !== gdprModalShown) {
                this.setState({ isShown: gdprModalShown });
            }
        };

        _handleOnCloseModal = () => {
            this.setState({ isShown: false });
            this.props.setSharedState({
                ...this.props.getSharedState(),
                gdprModalShown: false,
            });
        };

        render() {
            const { isShown } = this.state;

            return (
                <Comp
                    isGDPRModalShown={isShown}
                    onCloseGDPRModal={this._handleOnCloseModal}
                    {...this.props}
                />
            );
        }
    };

export default withFollowGDPRModalControls;
