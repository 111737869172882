import { RatingData } from '../types';

export const getSortedRatingCounts = (ratingData: RatingData) => {
    const {
        total_count: { value: totalCount, display: displayCount },
        rating,
        break_down: breakDown,
    } = ratingData;
    const ratingCounts = breakDown.sort(
        (a, b) => b.rating_star - a.rating_star,
    );
    return { ratingCounts, totalCount, displayCount, rating };
};
