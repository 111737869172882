import * as React from 'react';

const StarFillSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="star-fill_svg__eds-icon--star-fill_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="star-fill_svg__eds-icon--star-fill_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2L9.2 9.5H2l5.5 5-2 7.5 6.5-4.3 6.5 4.3-2-7.5 5.5-5h-7.2z"
        />
    </svg>
);

StarFillSvg.displayName = 'StarFillSvg';
export default StarFillSvg;
