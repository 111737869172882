import React from 'react';
import './SkeletonLoader.scss';

interface RatingDisplaySkeletonProps {
    width?: number;
    height?: number;
    borderRadius?: number;
}

export const RatingDisplaySkeleton = ({
    width = 60,
    height = 14,
    borderRadius = 4,
}: RatingDisplaySkeletonProps) => {
    const skeletonStyles = {
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: `${borderRadius}px`,
    };

    return (
        <div
            className="rating-display-loader"
            data-testid="skeleton-loader"
            style={skeletonStyles}
        />
    );
};
