import classnames from 'classnames';
import React from 'react';
import { RatingData, RatingSummaryVariants } from '../../../types';
import { getSortedRatingCounts } from '../../../utils';
import { RatingStars } from '../../ratingStars/RatingStars';
import { RatingCounts } from '../ratingCounts/RatingCounts';
import { RatingNumber } from './RatingNumber';
import './RatingSummary.scss';
import { ReviewsCount } from './ReviewsCount';
interface RatingSummaryProps {
    ratingData: RatingData;
    variant?: RatingSummaryVariants;
}

export const RatingSummary = ({
    ratingData,
    variant = 'vertical',
}: RatingSummaryProps) => {
    const { ratingCounts, totalCount, displayCount, rating } =
        getSortedRatingCounts(ratingData);
    const containerClassNames = classnames('rating-summary-container', {
        'horizontal-variant': variant === 'horizontal',
    });
    return (
        <div className={containerClassNames}>
            <div>
                <RatingNumber rating={rating} />
                <RatingStars rating={Number(rating)} size="xsmall" isReadOnly />
                <ReviewsCount totalCount={displayCount} />
            </div>
            <div className="rating-summary-counts">
                <RatingCounts
                    ratingCounts={ratingCounts}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
};
