import React, { useCallback, useEffect, type FC } from 'react';

import { track } from '@eventbrite/datalayer-library';
import { getExperiment } from '@eventbrite/statsig';
import { getRatingsData } from '../api/surfacingRating';

import { useOrganizerContext, useSurfacingExperimentContext } from '../context';

import {
    SURFACING_RATING_EXPERIMENT,
    TOTAL_RATING_THRESHOLD,
} from '../constants';

import type { TSurfacingExperimentVariant } from '../context';
import type { IOrganizerRatingMeta } from '../types/organizer';

const trackEligibleUsersForControlVariant = (
    variant: TSurfacingExperimentVariant,
    organizerId: string,
    rating: number,
    totalRatings: number,
) => {
    if (variant === 'control') {
        track({
            eventName:
                SURFACING_RATING_EXPERIMENT.eligibleOrganizerControlVariant,
            eventData: {
                organizerId,
                rating,
                totalRatings,
            },
        });
    }
};

const getRatings = (ratingsData: IOrganizerRatingMeta) => ({
    starRating: Number(ratingsData.average ?? 0),
    totalRating: Number(ratingsData.total ?? 0),
});

export const OrganizerRatingDataHandler: FC = ({ children }) => {
    const { id: organizerId = '' } = useOrganizerContext();
    const { setVariant, setStarRatingCount, setTotalRatingCount } =
        useSurfacingExperimentContext();

    const setStatsigVariantSafe = useCallback(
        (ratingCount: number, totalRatingCount: number) => {
            if (totalRatingCount > TOTAL_RATING_THRESHOLD) {
                const surfacingVariant = getExperiment<
                    string,
                    TSurfacingExperimentVariant
                >(
                    SURFACING_RATING_EXPERIMENT.experimentName,
                    SURFACING_RATING_EXPERIMENT.param,
                    SURFACING_RATING_EXPERIMENT.defaultValue,
                );
                setVariant(surfacingVariant);
                trackEligibleUsersForControlVariant(
                    surfacingVariant,
                    organizerId,
                    ratingCount,
                    totalRatingCount,
                );
            } else {
                setVariant('control');
            }
        },
        [organizerId, setVariant],
    );

    useEffect(() => {
        async function fetchRatingsData() {
            try {
                const ratingsData = await getRatingsData(organizerId);
                const { starRating, totalRating } = getRatings(ratingsData);
                setStarRatingCount(starRating);
                setTotalRatingCount(totalRating);
                setStatsigVariantSafe(starRating, totalRating);
            } catch (e) {
                console.log('cannot fetch ratings data', e);
                setVariant('control');
            }
        }
        fetchRatingsData();
    }, [
        organizerId,
        setVariant,
        setStarRatingCount,
        setTotalRatingCount,
        setStatsigVariantSafe,
    ]);

    return <>{children}</>;
};
