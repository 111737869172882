import { Avatar, AvatarSize } from '@eventbrite/eds-avatar';
import { NotificationBar } from '@eventbrite/eds-notification';
import React from 'react';

import {
    EDIT_ORGANIZER_PROFILE,
    NOTIFICATION_BAR_LABEL,
} from '../../components/Header/constants';
import { OrganizerContext } from '../../context';
import { Organizer } from '../../types';
import { OrganizerAboutRedesign } from '../OrganizerAbout';
import './Header.scss';
import { OrganizerDetails } from './OrganizerDetails';

const ProfileImage: React.FunctionComponent<{ src: string }> = (props) => {
    const { src } = props;

    if (!src) {
        return null;
    }

    return (
        <div className="organizer-profile-header__profile-image">
            <Avatar imageUrl={src} size={AvatarSize.medium} />
        </div>
    );
};

const BackgroundImage: React.FunctionComponent<{
    src: string;
}> = (props) => {
    const { src } = props;

    if (!src) {
        return null;
    }

    return (
        <div className="organizer-profile-header__background-image-redesign--width-benchmark">
            <section
                className={
                    'organizer-profile-header__background-image--container-revamp'
                }
            >
                <div className="organizer-profile-header__background-image--positioner">
                    <img
                        src={src}
                        alt=""
                        className="organizer-profile-header__background-image"
                    />
                </div>
            </section>
        </div>
    );
};

export interface HeaderProps {
    organizer: Organizer;
    website: string;
    hasPermissionToEditOrganizationInfo: boolean;
    userId: string;
}

const Title: React.FunctionComponent = (props) => {
    return (
        <h1 className={'organizer-profile-header__title-redesign eds-text-hl'}>
            {props.children}
        </h1>
    );
};

const OrganizerBanner: React.FunctionComponent<{
    organizerId: string;
    hasPermissionToEditOrganizationInfo: boolean;
}> = ({ organizerId, hasPermissionToEditOrganizationInfo }) => (
    <>
        {hasPermissionToEditOrganizationInfo && (
            <div className="organizer-profile__banner-redesign">
                <NotificationBar>
                    {NOTIFICATION_BAR_LABEL}
                    <a href={`/organizations/info/profile/${organizerId}`}>
                        {EDIT_ORGANIZER_PROFILE}
                    </a>
                </NotificationBar>
            </div>
        )}
    </>
);

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
    const organizer = React.useContext(OrganizerContext) as Organizer;
    const pastEvents = organizer?.num_past_events || 0;
    const futureEvents = organizer?.num_future_events || 0;

    const totalEvents = pastEvents + futureEvents;
    const userId = props.userId;

    return (
        <>
            <OrganizerBanner
                organizerId={props.organizer.id}
                hasPermissionToEditOrganizationInfo={
                    props.hasPermissionToEditOrganizationInfo
                }
            />
            <header className="organizer-profile-header" id="about">
                <BackgroundImage src={props.organizer?.logo?.url} />

                <div
                    className={` ${'organizer-profile-header__content--container-redesign'} ${
                        props.organizer?.logo?.url
                            ? 'organizer-profile-header__content--container-redesign--with-image'
                            : ''
                    }`}
                >
                    <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-8-12">
                        <ProfileImage src={props.organizer?.logo?.url} />
                        <Title>{props.organizer.name}</Title>
                        <OrganizerDetails
                            totalEvents={totalEvents}
                            organizer={organizer}
                            userId={userId}
                        />
                        <div className="organizer-profile-control-container">
                            <OrganizerAboutRedesign type="desktop" />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};
