import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TYPE_NEUTRAL, TYPE_ERROR } from '@eventbrite/eds-notification';

import delay from 'lodash/delay';
import get from 'lodash/get';

const NOTIFICATION_DISPLAY_TIME = 7000;

const withNotificationControls = (OriginalComponent) =>
    class WithNotificationControls extends Component {
        static propTypes = {
            getSharedState: PropTypes.func.isRequired,
            setSharedState: PropTypes.func.isRequired,
            onChangeSharedState: PropTypes.func.isRequired,
            organizerId: PropTypes.string,
            errorActionType: PropTypes.string,
        };

        state = {
            isNotificationShown: false,
        };

        componentDidMount() {
            this.props.onChangeSharedState(this._handleStateChange);
        }

        _handleStateChange = () => {
            const { followNotificationShown: sharedIsNotificationShown } =
                this.props.getSharedState();
            const { isNotificationShown } = this.state;

            if (isNotificationShown !== sharedIsNotificationShown) {
                if (sharedIsNotificationShown) {
                    this._onShowNotification();
                } else {
                    this._onHideNotification();
                }
            }
        };

        _onShowNotification = () => {
            this.setState({ isNotificationShown: true }, () =>
                delay(this._onHideNotification, NOTIFICATION_DISPLAY_TIME),
            );
        };

        _onHideNotification = () => {
            const { getSharedState, setSharedState } = this.props;

            this.setState({ isNotificationShown: false }, () => {
                const currentSharedState = getSharedState();

                setSharedState({
                    ...currentSharedState,
                    followNotificationShown: false,
                });
            });
        };

        render() {
            const { organizerId, getSharedState, errorActionType } = this.props;
            const { isNotificationShown } = this.state;
            const organizerName = get(getSharedState(), [
                'organizerData',
                organizerId,
                'name',
            ]);

            return (
                <OriginalComponent
                    isNotificationShown={isNotificationShown}
                    showNotification={this._onShowNotification}
                    hideNotification={this._onHideNotification}
                    organizerName={organizerName}
                    type={errorActionType ? TYPE_ERROR : TYPE_NEUTRAL}
                    {...this.props}
                />
            );
        }
    };

export default withNotificationControls;
