import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FollowButton } from '@eventbrite/eds-follow-button';
import FollowLoadingButton from './FollowLoadingButton';
import { gettext, translationPropType } from '@eventbrite/i18n';
import {
    FOLLOW_BUTTON_SIZES,
    FOLLOW_BUTTON_STYLES,
    FOLLOW_ORGANIZER_ATTEMPT,
} from '../constants';
import { logEvent } from '@eventbrite/statsig';

const _isInfoReady = (isFollowing) => typeof isFollowing === 'boolean';

class FollowButtonComponent extends Component {
    static propTypes = {
        /**
         * Callback for when user clicks to follow
         */
        onFollow: PropTypes.func.isRequired,
        /**
         * Callback for when user clicks to unfollow
         */
        onUnFollow: PropTypes.func.isRequired,
        /**
         * Whether user is currently following organizer or not
         */
        isFollowing: PropTypes.bool,
        /**
         * Whether user can click the button or not
         */
        isDisabled: PropTypes.bool,
        /**
         * Name of the organizer
         */
        organizerName: PropTypes.string,
        /**
         * Render function that allows the calling
         * application to render the follow count
         * as it sees fit.
         */
        renderFollowCount: PropTypes.func,
        /**
         * Style of Button to be rendered 'standard' or 'solitary'
         */
        style: PropTypes.oneOf(FOLLOW_BUTTON_STYLES),
        /**
         * Size of button 'compact', 'mini' or 'standard'
         */
        size: PropTypes.oneOf(FOLLOW_BUTTON_SIZES),
        /**
         * Class for the container of the button
         */
        __containerClassName: PropTypes.string,
        /**
         * Adds a custom text to the button
         */
        text: PropTypes.exact({
            active: translationPropType,
            default: translationPropType,
        }),
        /**
         * Adds a custom pageArea to identify the button
         * This is for metrics purposes
         */
        pageArea: PropTypes.string,
    };

    static defaultProps = {
        style: 'standard',
        size: 'standard',
        isFollowing: null,
        text: {
            active: gettext('Following'),
            default: gettext('Follow'),
        },
    };

    render() {
        const {
            style,
            size,
            __containerClassName,
            isFollowing,
            onFollow,
            onUnFollow,
            organizerName,
            isDisabled,
            renderFollowCount,
            numFollowers,
            text,
            pageArea,
        } = this.props;

        const followButtonText = isFollowing ? text.active : text.default;
        const followButtonDataSpec = isFollowing
            ? 'following-organizer-button'
            : 'follow-organizer-button';
        const followButtonAction = () => {
            isFollowing ? onUnFollow() : onFollow();
            logEvent(
                FOLLOW_ORGANIZER_ATTEMPT,
                isFollowing ? 'Unfollow' : 'Follow',
            );
        };
        const shouldRenderFollowCount = numFollowers >= 10 && renderFollowCount;

        if (!_isInfoReady(isFollowing)) {
            return <FollowLoadingButton size={size} />;
        }

        if (!organizerName) {
            return null;
        }

        return (
            <>
                <div
                    className={__containerClassName}
                    data-follow-page-area={pageArea}
                >
                    <FollowButton
                        text={text}
                        disabled={isDisabled}
                        data-spec={followButtonDataSpec}
                        onClick={followButtonAction}
                        style={style}
                        size={size}
                        isFollowing={isFollowing}
                        __containerClassName={__containerClassName}
                    >
                        {followButtonText}
                    </FollowButton>
                </div>
                {shouldRenderFollowCount && renderFollowCount(numFollowers)}
            </>
        );
    }
}

export default FollowButtonComponent;
