import React from 'react';
import { Organizer } from '../types';

export const useOrganizerContext = () => {
    const organizer = React.useContext(OrganizerContext);

    if (organizer === undefined) {
        throw new Error(
            'useOrganizerContext must be used within an Organizer provider',
        );
    }

    return organizer;
};

export const OrganizerContext = React.createContext<Partial<Organizer>>({});
