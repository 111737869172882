import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withModalControls = (Comp) =>
    class WithModalControlsControlsWrapper extends Component {
        static propTypes = {
            getSharedState: PropTypes.func.isRequired,
            setSharedState: PropTypes.func.isRequired,
            onChangeSharedState: PropTypes.func.isRequired,
        };

        state = {
            isModalShown: false,
        };

        componentDidMount() {
            this.props.onChangeSharedState(this._handleStateChange);
        }

        _handleStateChange = () => {
            const { loginModalShown } = this.props.getSharedState();
            const { isModalShown } = this.state;

            if (isModalShown !== loginModalShown) {
                this.setState({ isModalShown: loginModalShown });
            }
        };

        _handleOnOpenModal = () => {
            this.setState({ isModalShown: true });
        };

        _handleOnCloseModal = () => {
            this.setState({ isModalShown: false });
            this.props.setSharedState({
                ...this.props.getSharedState(),
                loginModalShown: false,
            });
        };

        render() {
            const { isModalShown } = this.state;

            return (
                <Comp
                    isModalShown={isModalShown}
                    onOpenModal={this._handleOnOpenModal}
                    onCloseModal={this._handleOnCloseModal}
                    {...this.props}
                />
            );
        }
    };

export default withModalControls;
