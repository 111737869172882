const initialSharedState = {
    gdprModalShown: false,
    loginModalShown: false,
    followNotificationShown: false,
};

export const getInitialSharedState = ({
    organizers = [],
    organizerData = {},
    isAuthenticated,
    userId,
    isGDPRCountry,
}) => ({
    ...initialSharedState,
    organizers,
    organizerData,
    isAuthenticated,
    userId,
    isGDPRCountry,
});

const reduceDefaultState = (memo, item) => ({
    ...memo,
    [item]: {
        id: item,
        followedByYou: null,
    },
});

export const computeMergedState = (initialState) => {
    const { organizerData = {}, organizers } = initialState;
    const updatedOrganizers = Object.keys(organizerData).length
        ? [...new Set([...organizers, ...Object.keys(organizerData)])]
        : organizers;
    const defaultOrganizerData = updatedOrganizers.reduce(
        reduceDefaultState,
        {},
    );

    return {
        ...initialState,
        organizers: updatedOrganizers,
        organizerData: {
            ...defaultOrganizerData,
            ...organizerData,
        },
    };
};

const isNotFollowed = ({ followedByYou }) => followedByYou === false;
const hasName = ({ name }) => !!name;
const identity = (d) => d;
const reduceToObject = (acc, data) => ({
    ...acc,
    [data.id]: data,
});

export const cleanOrganizerData = (
    organizers,
    organizerData,
    isHiddenWhenFollowed,
) => {
    const filteredOrganizerData = organizers
        .map((orgId) => organizerData[orgId])
        .filter(isHiddenWhenFollowed ? isNotFollowed : identity)
        .filter(hasName)
        .reduce(reduceToObject, {});

    return {
        organizers: Object.keys(filteredOrganizerData),
        organizerData: filteredOrganizerData,
    };
};
