import { useState, type RefObject } from 'react';
import { useIntersection } from 'react-use';

export const useStickyHeader = (
    ref: RefObject<HTMLDivElement>,
    options = {},
) => {
    const [isSticky, setIsSticky] = useState(false);
    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px 0px 150% 0px',
        threshold: 1,
        ...options, //allows for override of default options by the caller
    });

    if (intersection) {
        const intersectionPoint = intersection.intersectionRatio === 0;
        if (intersectionPoint) {
            !isSticky && setIsSticky(true);
        } else {
            isSticky && setIsSticky(false);
        }
    }

    return isSticky;
};
