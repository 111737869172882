import { CoreUser } from '@eventbrite/context-gen';
import React from 'react';
import { Collection } from '../types';

interface CoreUserWithCollections extends CoreUser {
    collections: {
        userCollections: Collection[] | [];
        setUserCollections: Function;
    };
}

interface UserProviderProps {
    user: CoreUser;
}

export const UserContext = React.createContext<
    Partial<CoreUserWithCollections>
>({});

export const UserProvider: React.FunctionComponent<UserProviderProps> = (
    props,
) => {
    return (
        <UserContext.Provider value={{ ...props.user }}>
            {props.children}
        </UserContext.Provider>
    );
};
