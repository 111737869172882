import { CoreEnv, CoreGoogleAnalyticsSettings } from '@eventbrite/context-gen';
import React from 'react';

interface KeyedCoreEnv {
    env: CoreEnv;
}

export interface FacebookOptions {
    appId: string;
    version: string;
    xfbml: boolean;
}

interface KeyedFacebookOptions {
    facebookOptions: FacebookOptions;
}

interface Captcha {
    captchaKey: string;
}

export const EnvContext = React.createContext<
    Partial<
        CoreGoogleAnalyticsSettings &
            KeyedCoreEnv &
            KeyedFacebookOptions &
            Captcha
    >
>({});
