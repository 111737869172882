import React, { createContext, FC, useContext, useState } from 'react';

export type TSurfacingExperimentVariant = 'control' | 'variant';

export interface ISurfacingExperimentContext {
    starRatingCount: number;
    totalRatingCount: number;
    variant: TSurfacingExperimentVariant;
    setStarRatingCount: (count: number) => void;
    setTotalRatingCount: (count: number) => void;
    setVariant: (variant: TSurfacingExperimentVariant) => void;
}

export const SurfacingExperimentContext =
    createContext<ISurfacingExperimentContext>(
        {} as ISurfacingExperimentContext,
    );

export const useSurfacingExperimentContext = () => {
    return useContext(SurfacingExperimentContext);
};

export const SurfacingExperimentProvider: FC = ({ children }) => {
    const [variant, setVariant] =
        useState<ISurfacingExperimentContext['variant']>('control');
    const [starRatingCount, setStarRatingCount] = useState(0);
    const [totalRatingCount, setTotalRatingCount] = useState(0);

    return (
        <SurfacingExperimentContext.Provider
            value={{
                variant,
                starRatingCount,
                totalRatingCount,
                setVariant,
                setStarRatingCount,
                setTotalRatingCount,
            }}
        >
            {children}
        </SurfacingExperimentContext.Provider>
    );
};
