import React from 'react';
import { ReadMoreProvider } from './context';
import './ReadMore.scss';
import { ReadMoreContent } from './ReadMoreContent';
import { ReadMoreToggle } from './ReadMoreToggle';
import { ReadMoreProps } from './types';

const readMoreDefaults: ReadMoreProps = {
    previewLineCount: 4,
    initialIsExpanded: false,
};

export const ReadMore: React.FunctionComponent<ReadMoreProps> = (props) => {
    const { children, initialIsExpanded } = props;

    return (
        <>
            <ReadMoreProvider isExpanded={initialIsExpanded}>
                <ReadMoreContent previewLineCount={props.previewLineCount}>
                    {children}
                </ReadMoreContent>
                <ReadMoreToggle />
            </ReadMoreProvider>
        </>
    );
};

ReadMore.defaultProps = readMoreDefaults;
