import { withMainControls } from '@eventbrite/eds-structure';
import {
    DiscoveryEvent,
    EventCardShareAction,
    FormattedEvent,
    getEventsById,
    getShareActionProps,
    transformDiscoveryEvent,
    useInfiniteEvents,
} from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React, { useContext } from 'react';
import {
    EVENT_CARD_AFFILIATE_CODE,
    ORGANIZER_EVENT_CARD_CLICK,
    STATSIG_LOCATION_STRING,
} from '../constants';
import { EnvContext, OrganizerContext, UserContext } from '../context';
import './EventRenderer.scss';
import OrganizerProfileVerticalEventCard from './OrganizerProfileEventCard';
import { ShowMoreEvents } from './ShowMoreEvents';

interface EventRendererProps {
    events: DiscoveryEvent[];
    eventMetaData: {
        num_past_events: number;
        has_next_past_page: boolean;
        num_future_events: number;
        has_next_future_page: boolean;
    };
    removeSaveHandlers?: boolean;
    type: string;
    bucketLabel?: string;
}

interface ShowMoreEventsResponse {
    data: {
        events: DiscoveryEvent[];
        has_next_page: boolean;
    };
}

const fetchMoreOrganizerEvents = async (
    currentPage: number,
    id: string,
    eventType: string,
) => {
    const url = `/org/${id}/showmore/?page_size=12&type=${eventType}&page=${
        currentPage + 1
    }`;

    const response = await fetch(url);
    const responseData: ShowMoreEventsResponse = await response.json();
    let formattedEvents;

    if (eventType === 'past') {
        formattedEvents = responseData.data.events.map((event) =>
            transformDiscoveryEvent(event),
        );
    } else {
        const expandedEvents = await getEventsById(
            responseData.data.events.map(({ id }) => id),
        );

        //ensure that the getEventsById endpoint doesn't
        //remove or overwrite any of the events from response
        formattedEvents = responseData.data.events.map((event) => {
            const expandedEvent = expandedEvents.events.find(
                ({ id }) => id === event.id,
            );

            return expandedEvent || transformDiscoveryEvent(event);
        });
    }

    return {
        has_next_page: responseData.data.has_next_page,
        events: formattedEvents,
    };
};

export const EventRenderer: React.FunctionComponent<EventRendererProps> = (
    props,
) => {
    const currentPage = React.useRef(1);
    const { id } = useContext(OrganizerContext);
    const user = useContext(UserContext);
    const envContext = useContext(EnvContext);
    const locale = envContext.env?.localeInfo.locale;
    const isAuthenticated = user?.isAuthenticated;
    const bucketLabelString = props.bucketLabel
        ? props?.bucketLabel.toString()
        : '';

    const utmOptions = {
        'utm-campaign': 'social',
        'utm-content': 'attendeeshare',
        'utm-medium': 'discovery',
        'utm-term': 'organizer-profile',
        'utm-share-source': 'organizer-profile',
    };

    const { data, isFetchingNextPage, hasNextPage, fetchNextPage } =
        useInfiniteEvents(
            [id, user.publicId, props.type, 'organizer-events'],
            async () => {
                const organizerEvents = await fetchMoreOrganizerEvents(
                    currentPage.current,
                    id as string,
                    props.type,
                );
                currentPage.current = currentPage.current + 1;

                return organizerEvents;
            },
            {
                getNextPageParam: (page) => page.has_next_page || undefined,
                initialData: {
                    pageParams: [
                        props.type === 'past'
                            ? props.eventMetaData.has_next_past_page
                            : props.eventMetaData.has_next_future_page,
                    ],
                    pages: [
                        {
                            events: props.events.map((event) =>
                                transformDiscoveryEvent(event),
                            ),
                            has_next_page:
                                props.type === 'past'
                                    ? props.eventMetaData.has_next_past_page
                                    : props.eventMetaData.has_next_future_page,
                        },
                    ],
                },
                expandEventsOnMount: props.type !== 'past',
            },
        );

    if (!data || data.pages[0].events.length === 0) {
        return (
            <div className="eds-text-bl">
                {props.type === 'past'
                    ? gettext('Sorry, there are no past events')
                    : gettext('Sorry, there are no upcoming events')}
            </div>
        );
    }

    return (
        <div data-testid="organizer-profile__events">
            <div className="eds-show-up-mn organizer-profile__event-renderer__grid">
                {data.pages.map((response) =>
                    response.events.map((event: FormattedEvent) => (
                        <OrganizerProfileVerticalEventCard
                            key={event.id}
                            event={event}
                            locale={locale || 'en-US'}
                            isAuthenticated={!!isAuthenticated}
                            affCode={EVENT_CARD_AFFILIATE_CODE}
                            statsigLocationString={STATSIG_LOCATION_STRING}
                            onClick={() => {
                                logEvent(ORGANIZER_EVENT_CARD_CLICK);
                            }}
                            bucketLabel={bucketLabelString}
                            moreActions={
                                event &&
                                props.type !== 'past' && (
                                    <EventCardShareAction
                                        {...getShareActionProps({
                                            name: event.name,
                                            id: event.id,
                                            url: event.url,
                                            affCode: EVENT_CARD_AFFILIATE_CODE,
                                            utmOptions: utmOptions,
                                        })}
                                    />
                                )
                            }
                        />
                    )),
                )}
            </div>
            <div className="eds-show-down-sw">
                {data.pages.map((response) =>
                    response.events.map((event: FormattedEvent) => (
                        <div className="eds-l-pad-vert-2" key={event.id}>
                            <OrganizerProfileVerticalEventCard
                                event={event}
                                locale={locale || 'en-US'}
                                isAuthenticated={!!isAuthenticated}
                                affCode={EVENT_CARD_AFFILIATE_CODE}
                                statsigLocationString={STATSIG_LOCATION_STRING}
                                onClick={() => {
                                    logEvent(ORGANIZER_EVENT_CARD_CLICK);
                                }}
                                bucketLabel={bucketLabelString}
                                moreActions={
                                    event &&
                                    props.type !== 'past' && (
                                        <EventCardShareAction
                                            {...getShareActionProps({
                                                name: event.name,
                                                id: event.id,
                                                url: event.url,
                                                affCode:
                                                    EVENT_CARD_AFFILIATE_CODE,
                                                utmOptions: utmOptions,
                                            })}
                                        />
                                    )
                                }
                            />
                        </div>
                    )),
                )}
            </div>
            <ShowMoreEvents
                isLoading={isFetchingNextPage}
                onClick={fetchNextPage}
                hasMore={!!hasNextPage}
            />
        </div>
    );
};

export const EventRenderWithControls = withMainControls(EventRenderer);
