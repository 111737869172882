import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const withFollow = (EnhancedComponent) =>
    class WithFollowWrapper extends Component {
        static propTypes = {
            organizerId: PropTypes.string.isRequired,
        };

        static contextTypes = {
            subscribeToContextUpdates: PropTypes.func,
            unsubscribeFromContextUpdates: PropTypes.func,

            onFollow: PropTypes.func,
            onUnFollow: PropTypes.func,
            onRemoveOrganizer: PropTypes.func,
            onViewOrganizer: PropTypes.func,
            pageArea: PropTypes.string,
        };

        state = {
            organizers: [],
            organizerData: {},
        };

        componentDidMount() {
            const { subscribeToContextUpdates } = this.context;

            if (subscribeToContextUpdates) {
                subscribeToContextUpdates(this._updateState);
            }
        }

        componentWillUnmount() {
            const { unsubscribeFromContextUpdates } = this.context;

            if (unsubscribeFromContextUpdates) {
                unsubscribeFromContextUpdates(this._updateState);
            }
        }

        _updateState = (followState) => {
            this.setState(followState);
        };

        _handleFollow = ({ callback } = {}) => {
            this.props.onFollow?.();
            this.context.onFollow(this.props.organizerId, { callback });
        };

        _handleUnFollow = ({ callback } = {}) => {
            this.props.onUnFollow?.();
            this.context.onUnFollow(this.props.organizerId, { callback });
        };

        _handleRemoveOrganizer = (organizerId) => {
            this.context.onRemoveOrganizer(organizerId);
        };

        _handleViewOrganizer = (organizerId) => {
            this.context.onViewOrganizer(organizerId);
        };

        _getPropFromState = (propName, defaultValue) => {
            const { organizerData } = this.state;
            const { organizerId } = this.props;

            return get(organizerData, [organizerId, propName], defaultValue);
        };

        render() {
            return (
                <EnhancedComponent
                    {...this.props}
                    isFollowing={this._getPropFromState('followedByYou', null)}
                    onFollow={this._handleFollow}
                    onUnFollow={this._handleUnFollow}
                    onRemoveOrganizer={this._handleRemoveOrganizer}
                    onViewOrganizer={this._handleViewOrganizer}
                    organizerName={this._getPropFromState('name', '')}
                    organizerProfileUrl={this._getPropFromState('url', '')}
                    numUpcomingEvents={this._getPropFromState(
                        'numUpcomingEvents',
                        null,
                    )}
                    numFollowers={this._getPropFromState('numFollowers', null)}
                    imageUrl={this._getPropFromState('profilePicture', '')}
                    customDataPoint={this._getPropFromState(
                        'customDataPoint',
                        '',
                    )}
                />
            );
        }
    };

export default withFollow;
