import {
    EventCardActions,
    EventCardBadge,
    EventCardDate,
    EventCardDetails,
    EventCardImage,
    EventCardLink,
    EventCardLocation,
    EventCardPrice,
    EventCardPromoted,
    EventCardRecipeProps,
    EventCardSaveAction,
    EventCardTitle,
    EventCardTracking,
    getEventCardLinkProps,
    getFormattedSalesStatus,
    HORIZONTAL_LEFT_CARD_BADGE_STYLE,
    useFormattedLocationString,
    useGetFormattedEventDateTimeString,
    useMinPriceString,
    VerticalEventCard,
    VERTICAL_EVENT_CARD_IMAGE_CONFIG,
} from '@eventbrite/event-renderer';
import React from 'react';

export const OrganizerProfileVerticalEventCard: React.FunctionComponent<
    EventCardRecipeProps
> = (props) => {
    const {
        event,
        locale,
        isRaised,
        statsigLocationString,
        moreActions,
        affCode,
        bucketLabel,
        onClick,
    } = props;

    const linkProps = getEventCardLinkProps({
        affCode: affCode,
        event,
        onClick,
    });

    //Only show active Sales Status (sold out etc)
    //For non-repeating events to prevent incorrect signals.
    const formattedSalesStatus = getFormattedSalesStatus(event);

    const eventCardTrackingProps = {
        statsigLocationString: statsigLocationString,
        event,
        eventBucketLabel: bucketLabel,
    };

    const formattedDateString = useGetFormattedEventDateTimeString({
        startDate: event?.startDate,
        startTime: event?.startTime,
        timezone: event?.timezone,
        repeatingInstanceCount: event?.repeatingInstanceCount,
        locale: locale,
        useUserTimezone: event.isOnlineEvent,
        dateTimeDelimeter: '•',
    });

    const formattedPriceString = useMinPriceString({
        price: event?.minPrice,
        locale: locale,
    });

    const formattedLocationString = useFormattedLocationString({
        venue: event.venue,
        style: 'venue',
    });

    return (
        <VerticalEventCard isRaised={isRaised}>
            <EventCardTracking {...eventCardTrackingProps}>
                <EventCardBadge style={HORIZONTAL_LEFT_CARD_BADGE_STYLE}>
                    {formattedSalesStatus}
                </EventCardBadge>
                <EventCardLink {...linkProps}>
                    <EventCardImage
                        name={event.name}
                        loading={props.isLazyImage ? 'lazy' : 'eager'}
                        tokens={{
                            ...VERTICAL_EVENT_CARD_IMAGE_CONFIG,
                            '--image-background-color': event.edgeColor,
                        }}
                        src={event.imageUrl}
                    />
                </EventCardLink>
                <EventCardDetails
                    space="xxs"
                    tokens={{
                        '--EventCardDetailsPadding': '16px 8px 12px 12px',
                        '--EventCardDetailsFlexGrow': '2',
                        '--EventCardDetailsPosition': 'relative',
                    }}
                >
                    <EventCardPromoted isPromoted={event.isPromoted} />
                    <EventCardTitle
                        className="event-card__clamp-line--two"
                        title={event.name}
                        color="neutral-800"
                    />
                    <EventCardDate
                        className="event-card__clamp-line--one"
                        formattedDateString={formattedDateString}
                        variant="body-md"
                    />
                    <EventCardLocation
                        className="event-card__clamp-line--one"
                        formattedLocationString={formattedLocationString}
                    />
                    <EventCardPrice
                        color="neutral-800"
                        isFree={event.isFree}
                        formattedPriceString={formattedPriceString}
                        variant="body-md-bold"
                    />
                    <EventCardActions
                        tokens={{
                            '--EventCardActionsPosition': 'absolute',
                            '--EventCardActionsTop': '-22px',
                            '--EventCardActionsRight': '8px',
                            '--EventCardActionsGap': '8px',
                        }}
                        actionStyleOverride="outline"
                    >
                        <EventCardSaveAction
                            isAuthenticated={!!props.isAuthenticated}
                            savedByYou={event.savedByYou}
                            id={event.id}
                            eventName={event.name}
                            imageUrl={event.imageUrl}
                        />
                        {moreActions}
                    </EventCardActions>
                </EventCardDetails>
            </EventCardTracking>
        </VerticalEventCard>
    );
};
