import { FormattedEvent, FormattedEventResponse } from '../../types';

export const getMergedEvents = (
    oldEventData?: FormattedEventResponse,
    expandedEvents?: FormattedEventResponse,
): FormattedEvent[] => {
    const normalizedEvents =
        oldEventData?.events.reduce((memo, event) => {
            return {
                ...memo,
                [event.id]: event,
            };
        }, {} as Record<string, FormattedEvent>) || {};

    expandedEvents?.events.forEach((event: FormattedEvent) => {
        normalizedEvents[event.id] = event;
    });

    const mergedEvents =
        oldEventData?.events.map(
            (event: FormattedEvent) => normalizedEvents[event.id],
        ) || [];

    return mergedEvents;
};
