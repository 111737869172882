import React from 'react';

import classnames from 'classnames';

import styles from './SignalDisplay.module.scss';

import type { SignalDisplayProps } from './SignalDisplay.type';

export const SignalDisplay: React.FC<SignalDisplayProps> = ({
    countEl,
    title,
    variant,
}) => {
    const containerClassNames = classnames(
        styles['signal-display__container'],
        {
            [styles['condensed-variant']]: variant === 'condensed',
        },
    );
    const countClassNames = classnames(styles.countText, {
        'eds-text-color--ui-900 eds-text-bs': variant === 'condensed',
        'eds-text-bl eds-text-weight--bold': variant === 'regular',
    });
    const titleClassNames = classnames(styles.infoText, 'eds-text-bs', {
        'eds-text-color--ui-600': variant === 'regular',
        'eds-text-color--ui-900': variant === 'condensed',
    });
    return (
        <div
            className={containerClassNames}
            data-testid="trust-signals-display-container"
        >
            <span className={countClassNames}>{countEl}</span>
            <p className={titleClassNames}>{title}</p>
        </div>
    );
};
