import { isDisplayRatingExperientEnabled } from '../tracking/statsig';
import { validateTld } from './data';
import { capitalizeString } from './strings';

export const evaluateExperiment = (
    rating: number,
    filterRating: boolean,
    statsigConfig: any,
    filterbyTld: boolean,
) => {
    if (filterbyTld && !validateTld()) return false;
    if (!filterRating) return true;
    if (statsigConfig) {
        const rangeToDisplay = statsigConfig.get('rangeToDisplay', null);
        const rangeToEvaluate = statsigConfig.get('rangeToEvaluate', null);
        const shouldDisplay =
            rangeToDisplay &&
            rating >= Number(rangeToDisplay?.from) &&
            rating <= Number(rangeToDisplay?.to);
        const shouldEvaluate =
            rangeToEvaluate &&
            rating >= Number(rangeToEvaluate?.from) &&
            rating < Number(rangeToEvaluate?.to) &&
            rangeToEvaluate?.experimentData &&
            isDisplayRatingExperientEnabled(rangeToEvaluate.experimentData);

        return shouldDisplay || shouldEvaluate;
    }
    return false;
};

export const addPageToEvent = (event: string, page: string) => {
    return page ? `${capitalizeString(page)} - ${event}` : event;
};

export const getFilteredTrackingMethods = (
    methods: string[],
    filterList: string[],
) => {
    return methods.filter((item) => !filterList.includes(item));
};
