import classNames from 'classnames';
import React, { useContext } from 'react';
import { ReadMoreContext } from './context';
import { ReadMoreContentProps } from './types';

export const ReadMoreContent: React.FunctionComponent<ReadMoreContentProps> = (
    props,
) => {
    const { previewLineCount } = props;
    const { isExpanded, setIsClamped } = useContext(ReadMoreContext);
    const className = classNames('read-more--content', {
        'read-more--content__clamped': !isExpanded,
    });

    //Determine if the children are clamped so other
    //elements may respond to that data.
    const isClampedRef = React.useCallback(
        (element) => {
            if (element) {
                const elementIsClampingContent =
                    element.scrollHeight > element.clientHeight;
                setIsClamped?.(elementIsClampingContent);
            }
        },
        [setIsClamped],
    );

    const lineClampValue = isExpanded ? 0 : previewLineCount;

    return (
        <div
            ref={isClampedRef}
            data-testid="read-more-content"
            className={className}
            style={{
                WebkitLineClamp: lineClampValue,
            }}
        >
            {props.children}
        </div>
    );
};
