import { initializeErrorReporting } from '@eventbrite/error-reporting';
import {
    getLocaleOverride,
    getParentLocale,
    setupAsync,
} from '@eventbrite/i18n';
import {
    initDimensionsFromState,
    trackInitialPageView,
} from '@eventbrite/site-analytics';
import {
    dehydrate,
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import { render } from 'react-dom';
import { App } from './app';
import { initializeStatsigSdk } from './utils/statsig';
// retrieve data from server to hydrate on the client
const props = window.__SERVER_DATA__ || ({} as any);
const root = document.getElementById('root');

// retrieve dehydrated react-query state to hydrate
const FIVE_MINUTES = 5 * 60 * 1000;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            staleTime: FIVE_MINUTES,
        },
    },
});
const dehydratedState = window.__REACT_QUERY_STATE__;

const parentLocale = getParentLocale(
    getLocaleOverride() || props.env.localeInfo.locale,
);

const main = async () => {
    initializeErrorReporting();
    try {
        if (parentLocale !== 'en_US') {
            await setupAsync({
                parentLocale,
                translations:
                    /* webpackChunkName: "organizer-profile-i18n-translation" */ import(
                        `./i18n/translations/${parentLocale}.json`
                    ),
            });
        }
        await initializeStatsigSdk();
    } finally {
        const safeDehydratedState = dehydratedState
            ? dehydratedState
            : dehydrate(queryClient);

        render(
            <QueryClientProvider client={queryClient}>
                <Hydrate state={safeDehydratedState}>
                    <App {...props} />
                </Hydrate>
            </QueryClientProvider>,
            root,
        );
    }
};
main();

initDimensionsFromState(props);
trackInitialPageView(props.gaSettings);
