import React, { useEffect, useState } from 'react';

import { track } from '@eventbrite/datalayer-library';
import { sdkRequest } from '@eventbrite/http';
import { useQuery } from '@tanstack/react-query';
import { formatUrl } from 'url-lib';

import {
    useSurfacingExperimentContext,
    UseTrustSignalsContext,
} from '../../context';

import { SURFACING_RATING_EXPERIMENT } from '../../constants';

import { ContactOrganizerButton } from '../../components-redesign/ContactOrganizerButton';
import { FollowMessageComponent } from '../../components/FollowMessageComponent';
import { FollowOrganizer } from '../../components/FollowOrganizer';
import { OrganizerTrustSignals } from '../OrganizerTrustSignals';
import { OrganizerTrustSignalsVariant } from '../OrganizerTrustSignalsVariant';

import './OrganizerDetails.scss';

import type { Organizer } from '../../types';

interface OrganizerDetailsProps {
    organizer?: Organizer;
    totalEvents: number;
    userId: string;
}
const ORGANIZERS_URL = '/api/v3/organizers/';

export const getFollowStatus = async (organizerId: string) => {
    const response = await sdkRequest(
        formatUrl(ORGANIZERS_URL, {
            ids: organizerId,
            'expand.organizer': 'follow_status',
        }),
    );

    return response?.organizers[0]?.follow_status?.followed_by_you;
};

export const OrganizerDetails: React.FunctionComponent<
    OrganizerDetailsProps
> = (props) => {
    const { followers, totalEvents, setTotalEvents, setFollowers } =
        UseTrustSignalsContext();
    const { variant, starRatingCount, totalRatingCount } =
        useSurfacingExperimentContext();
    const [profileHeaderContainerCss, setProfileHeaderContainerCss] = useState(
        'organizer-profile-header__container--experiment-container',
    );

    const { organizer, totalEvents: hostedEvents, userId } = props;
    const TrustSignal =
        variant === 'control'
            ? OrganizerTrustSignals
            : OrganizerTrustSignalsVariant;

    const { data: followStatus } = useQuery({
        queryKey: ['followStatus', organizer?.id],
        queryFn: () => getFollowStatus(organizer?.id ?? ''),
    });

    useEffect(() => {
        setTotalEvents(hostedEvents);
        setProfileHeaderContainerCss(
            followers == 0 && hostedEvents == 0
                ? ''
                : 'organizer-profile-header__container--experiment-container',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostedEvents, followers]);

    useEffect(() => {
        if (!organizer?.id || variant !== 'variant') return;
        track({
            eventName: SURFACING_RATING_EXPERIMENT.experimentLoaded,
            eventData: {
                organizerId: organizer.id,
                rating: starRatingCount,
                totalRatings: totalRatingCount,
            },
        });
    }, [organizer?.id, starRatingCount, totalRatingCount, variant]);

    if (!organizer) {
        return null;
    }

    return (
        <div className={'organizer-profile-header__container'}>
            <div className="organizer-profile-experiment-container">
                <div className={profileHeaderContainerCss}>
                    <TrustSignal
                        organizer={organizer}
                        followCount={followers}
                        totalEvents={totalEvents}
                    />
                </div>
            </div>
            <div className="organizer-profile-header__container--button-group">
                <div className="message-container">
                    {followStatus === false && (
                        <div className="organizer-profile-experiment-container">
                            <FollowMessageComponent />
                        </div>
                    )}
                    <FollowOrganizer
                        organizer={organizer}
                        userId={userId}
                        containerClassName="target-child-width"
                        renderFollowCount={(followCount: string) => {
                            setFollowers(Number(followCount));
                            setProfileHeaderContainerCss(
                                followers == 0 && totalEvents == 0
                                    ? ''
                                    : 'organizer-profile-header__container--experiment-container',
                            );
                            return <></>;
                        }}
                    />
                </div>
                <div className="organizer-profile-control-container">
                    <ContactOrganizerButton />
                </div>
            </div>
            <div className="organizer-profile-control-container trust-signal-container-desktop">
                <TrustSignal
                    organizer={organizer}
                    followCount={followers}
                    totalEvents={totalEvents}
                />
            </div>
        </div>
    );
};
