import { gettext, ngettext } from '@eventbrite/i18n';
import React from 'react';
import { Organizer } from '../../types';
import { FollowOrganizer } from '../FollowOrganizer';

interface OrganizerDetailsProps {
    organizer?: Organizer;
    totalEvents: number;
    userId: string;
}

export const OrganizerDetails: React.FunctionComponent<
    OrganizerDetailsProps
> = (props) => {
    const { organizer, totalEvents, userId } = props;

    if (!organizer) {
        return null;
    }

    //Remove when EB-173655 is resolved
    gettext('');

    return (
        <div className="organizer-profile-header__follow-button--container">
            <FollowOrganizer
                organizer={organizer}
                userId={userId}
                renderFollowCount={(followCount: string) => {
                    return (
                        <div className="organizer-profile-header__follow-count">
                            {ngettext(
                                '%(followCount)s follower',
                                '%(followCount)s followers',
                                parseInt(followCount),
                                { followCount },
                            )}
                        </div>
                    );
                }}
            />
            {totalEvents ? (
                <div className="organizer-profile-header__hosted-events">
                    {ngettext(
                        'Hosted %(totalEvents)s event total',
                        'Hosted %(totalEvents)s events total',
                        totalEvents,
                        { totalEvents },
                    )}
                </div>
            ) : null}
        </div>
    );
};
