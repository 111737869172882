import { getWindowObject } from '@eventbrite/feature-detection';
import { TLD_DISPLAY } from '../constants';
export const getLocaleInfo = (): string => {
    return (
        getWindowObject('__SERVER_DATA__')?.env?.localeInfo?.locale || 'en_US'
    );
};

export const getDomain = () => {
    return getWindowObject('__SERVER_DATA__')?.env?.ebDomain;
};

export const validateTld = (): boolean => {
    return TLD_DISPLAY.some((tld) => getDomain()?.endsWith(tld));
};
