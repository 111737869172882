import { SIZES as BUTTON_SIZES } from '@eventbrite/eds-button';
import { ICON_PROP_TYPE } from '@eventbrite/eds-icon-button';
import { NOTIFICATION_OPTIONS_PROP_TYPES } from '@eventbrite/eds-notification';
import { makePropType } from '@eventbrite/eds-utils';
import { translationPropType } from '@eventbrite/i18n';
import PropTypes from 'prop-types';

export const ROOT_NODE_SELECTOR = '#creator-nav-root';

// modal types

export enum ModalType {
    simple = 'simple',
    aperture = 'aperture',
    takeover = 'takeover',
    complex = 'complex',
}

export const TYPES = [
    ModalType.simple,
    ModalType.aperture,
    ModalType.takeover,
    ModalType.complex,
];

/**
 * Interaction modes for pane layout component
 */
export enum PaneLayoutInteractionType {
    asCollapsible = 'as-collapsible',
    asModal = 'as-modal',
}

export const PANE_LAYOUT_INTERACTION_TYPES = [
    PaneLayoutInteractionType.asCollapsible,
    PaneLayoutInteractionType.asModal,
];

/**
 * @deprecated Use `ModalType.simple` instead
 */
export const TYPE_SIMPLE = ModalType.simple;
/**
 * @deprecated Use `ModalType.aperture` instead
 */
export const TYPE_APERTURE = ModalType.aperture;
/**
 * @deprecated Use `ModalType.takeover` instead
 */
export const TYPE_TAKEOVER = ModalType.takeover;
/**
 * @deprecated Use `ModalType.complex` instead
 */
export const TYPE_COMPLEX = ModalType.complex;

export const MODAL_TITLE_ID = 'eds-modal-title';

// Checks that the prop has the correct length
const propLengthCheck = <Props extends Record<string, unknown>>(
    props: Props,
    propName: keyof Props,
    componentName: string,
    length = 140,
) => {
    if (props[propName] > length) {
        return new Error(
            `Invalid prop ${String(
                propName,
            )} suppied to ${componentName}. content must be ${length} characters or less.`,
        );
    }

    return null;
};

const MODAL_MESSAGE_TYPE = <Props extends Record<string, unknown>>(
    props: Props,
    propName: keyof Props,
    componentName: string,
) => propLengthCheck(props, propName, componentName, 32);

const MODAL_TITLE_TYPE = <Props extends Record<string, unknown>>(
    props: Props,
    propName: keyof Props,
    componentName: string,
) => propLengthCheck(props, propName, componentName, 180);

export const MODAL_MESSAGE_PROPTYPE = makePropType(MODAL_MESSAGE_TYPE);
export const MODAL_TITLE_PROPTYPE = makePropType(MODAL_TITLE_TYPE);
export const MODAL_CHILDREN_PROPTYPE = PropTypes.node;

export const MODAL_PROP_TYPES = {
    children: MODAL_CHILDREN_PROPTYPE.isRequired,
    /**
     * The type of modal that should be shown
     */
    type: PropTypes.oneOf(TYPES),
    /**
     * A function with the desired behavior on mount (componentDidMount)
     */
    onMount: PropTypes.func,
    /**
     * Is the dialog currently shown?
     */
    isShown: PropTypes.bool,
    /**
     * Removes min height for simple Modals
     */
    noMinHeight: PropTypes.bool,
    /**
     * Is the dialog closable?
     */
    isClosable: PropTypes.bool,
    /**
     * Removes padding on the modal container
     */
    noPadding: PropTypes.bool,
    /**
     * Add a class to the modals wrapper <dialog>
     */
    dangerouslySetContainerClassName: PropTypes.shape({
        __className: PropTypes.string,
    }),
};

export const MODAL_BODY_PROP_TYPES = {
    children: MODAL_CHILDREN_PROPTYPE.isRequired,
    /**
     * A function with the desired behavior on close
     */
    onClose: PropTypes.func,
    /**
     * The icon to use for the secondary header action
     */
    headerActionIconType: ICON_PROP_TYPE,
    /**
     * The title for the secondary header action icon
     */
    headerActionTitle: translationPropType,
    /**
     * A function with the desired behavior on clicking the secondary header action icon
     */
    onHeaderAction: PropTypes.func,
    /**
     * The title displayed above the content
     */
    title: MODAL_TITLE_PROPTYPE,
    /**
     * To apply a custom css class to title
     */
    titleClassName: PropTypes.string,
    /**
     * The title displayed above the content
     */
    secondaryTitle: PropTypes.node,
    /**
     * The illustration displayed inside the modal
     */
    illustration: PropTypes.node,
    /**
     * The type of button that should be shown for primary button
     */
    primaryType: PropTypes.string,
    /**
     * The text that should be shown across the primary button
     */
    primaryText: PropTypes.node,
    /**
     * The size of the primary button
     */
    primarySize: PropTypes.oneOf([...BUTTON_SIZES]),
    /**
     * Whether the primary button should be disabled
     */
    primaryIsDisabled: PropTypes.bool,
    /**
     * The function that should be triggered on primary button click
     */
    onPrimaryAction: PropTypes.func,
    /**
     * The type of button that should be shown for secondary button
     */
    secondaryType: PropTypes.string,
    /**
     * The text that should be shown across the secondary button
     */
    secondaryText: PropTypes.node,
    /**
     * Whether the secondary button should be disabled
     */
    secondaryIsDisabled: PropTypes.bool,
    /**
     * The function that should be triggered on secondary button click
     */
    onSecondaryAction: PropTypes.func,
    /**
     * To apply a custom css class to secondary title
     */
    secondaryTitleClassName: PropTypes.string,
    /**
     * The node that will act as a tertiary action
     */
    tertiaryNode: PropTypes.node,
    /**
     * The function that should be triggered on tertiary node click
     */
    onTertiaryAction: PropTypes.func,
    /**
     * A message to show above the button bar
     */
    buttonBarMessage: PropTypes.oneOf([
        translationPropType,
        PropTypes.string,
        PropTypes.node,
    ]),
    /**
     * True if the modal body should be empty, i.e. not include the header, footer, or body containers
     */
    modalBodyIsEmpty: PropTypes.bool,
    /**
     * Disables the ability to close the modal by clicking the outside overlay
     */
    disableCloseOnClickOutside: PropTypes.bool,
    /**
     * Used to render notifications inside the modal header.
     */
    notificationOptions: PropTypes.oneOfType([
        NOTIFICATION_OPTIONS_PROP_TYPES,
        PropTypes.arrayOf(NOTIFICATION_OPTIONS_PROP_TYPES),
    ]),
    /**
     * Changes the role attribute to dialog
     */
    roleIsDialog: PropTypes.bool,
};

export const COLLAPSIBLE_PANE_LAYOUT_PROP_TYPES = {
    /**
     * The content to display inside the pane, if any
     */
    pane: PropTypes.node,
    /**
     * Whether pane is open or closed
     */
    isPaneOpen: PropTypes.bool,
    /**
     * Callback when user clicks outside the pane content
     */
    onPaneClickOutside: PropTypes.func,
    /**
     * To support different interaction and internal layout modes
     */
    paneInteraction: PropTypes.oneOf(PANE_LAYOUT_INTERACTION_TYPES),
    /**
     * To hide the pane whe the Processing payment spinner is shown
     */
    paneAriaHidden: PropTypes.bool,
    /**
     * To apply a custom css class to the pane
     */
    customPaneClassname: PropTypes.string,
    /**
     * To apply a custom css class to the pane content
     */
    customPaneContentClassname: PropTypes.string,
    /**
     * To apply a bottom position to the pane content
     */
    offsetBottom: PropTypes.number,
    /**
     * To apply a custom css class to the pane wrapper
     */
    customWrapperClassname: PropTypes.string,
};
