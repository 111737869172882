import Cookies, { expire } from 'cookies-js';

import { getUrlPath } from '@eventbrite/path-utils';
import { track } from '@eventbrite/datalayer-library';

import {
    ORGANIZER_COOKIE,
    loginModalContainerClassName,
    gdprModalContainerClassName,
    followNotificationContainerClassName,
} from '../constants';

import { getFollowOrganizerStatus } from '@eventbrite/redux-destination';

const getContainerParent = (parentClassName) => {
    if (
        typeof parentClassName === 'string' &&
        document.getElementsByClassName(parentClassName).length
    ) {
        return document.getElementsByClassName(parentClassName)[0];
    }

    return document.body;
};
const createContainerOnce = (className, parentClassName) => {
    const containers = document.getElementsByClassName(className);

    let container;

    if (containers.length > 0) {
        container = containers[0];
    } else {
        const newDiv = document.createElement('div');

        newDiv.classList.add(className);

        container = getContainerParent(parentClassName).appendChild(newDiv);
    }

    return container;
};

export const createGDPRContainer = () =>
    createContainerOnce(gdprModalContainerClassName);
export const createLoginModalContainer = () =>
    createContainerOnce(loginModalContainerClassName);
export const createFollowNotificationContainer = (parentClassName) =>
    createContainerOnce(followNotificationContainerClassName, parentClassName);

const explicitOptIn = 1;
const explicitOptOut = 2;
// Array with the values if the user has explicitly opted in or out for receiving notifications from an organizer
const explicitChoices = [explicitOptIn, explicitOptOut];

// TODO CHECK ASYNCHRONISM
export const shouldShowGDPRModal = async (
    isGDPRCountry = true,
    userId,
    organizerId,
) => {
    if (!isGDPRCountry) {
        return false;
    }
    track({
        eventName: 'GDPRApplies',
        eventData: { organizerId },
    });
    return hasAlreadyChosen(userId).then((hasChosen) => hasChosen);
};

export const hasAlreadyChosen = async (userId) => {
    let hasChosen = false;
    try {
        const response = await getFollowOrganizerStatus(userId);
        hasChosen =
            explicitChoices.includes(
                response['preferences'][0]['global_preference'],
            ) &&
            explicitChoices.includes(
                response['preferences'][1]['global_preference'],
            );
    } catch (error) {
        // If the api fails we should still show the GDPR modal.
    }
    return !hasChosen;
};

export const getOrganizerIdFromCookie = () => Cookies.get(ORGANIZER_COOKIE);
export const expireOrganizerCookie = () =>
    expire(ORGANIZER_COOKIE, { path: getUrlPath() });
