import { useMount } from 'react-use';
import { RUMOptions } from '../types';
import { initializeRealUserMonitoring } from '../utils';

/**
 * Upon mount, will initialize the RealUserMonitoring
 * tracking leveraging the passed in options.
 *
 * @param options RUMOptions
 */
export const useRealUserMonitoring = (options: RUMOptions) => {
    useMount(() => {
        initializeRealUserMonitoring(options);
    });
};
