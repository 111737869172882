import React from 'react';

import './FollowLoadingButton.scss';

const FollowLoadingButton = ({ size }) => (
    <div className="loading-button__container">
        <div className={`loading-button loading-button--${size}`}>
            <div className="loading-button__main" />
        </div>
    </div>
);

export default FollowLoadingButton;
