import React, { useRef } from 'react';
import { useStickyHeader } from './hooks';
import { TabNavigationHeaderProps } from './types';

export const TabNavigationHeader: React.FunctionComponent<
    TabNavigationHeaderProps
> = (props) => {
    const intersectionRef = useRef(null);
    const isSticky = useStickyHeader(intersectionRef);
    const { stickyNode } = props;

    return (
        <>
            <div ref={intersectionRef} />
            <div
                className={isSticky ? '' : 'eds-hide'}
                style={{ height: props.stickyStubContainerHeight }}
            />
            <nav
                className={`tab-navigation eds-text-bm eds-text-weight--heavy ${
                    isSticky
                        ? `${props.stickyContainerClassNames} tab-navigation__sticky`
                        : ''
                }`}
            >
                <div
                    className={`tab-navigation--content ${
                        isSticky ? props.stickyContentClassNames : ''
                    }`}
                >
                    <div className={`${isSticky ? '' : 'eds-hide'}`}>
                        {stickyNode}
                    </div>
                    <ul className="tab-navigation--scroll-container">
                        {React.Children.map(props.children, (child, index) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(
                                    child as React.ReactElement<any>,
                                    {
                                        index,
                                    },
                                );
                            }
                        })}
                    </ul>
                </div>
            </nav>
        </>
    );
};

TabNavigationHeader.defaultProps = {
    stickyStubContainerHeight: '71px',
};
