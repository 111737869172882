import React, { useEffect, useState } from 'react';
import { colorsTypes } from '../../../types';
import { getClampedValue } from '../../../utils';
import './LinearProgress.scss';

interface LinearProgressProps {
    value: number;
    label: string;
    color?: colorsTypes;
}

export const LinearProgress = ({
    value,
    label,
    color = 'ui-blue--hover',
}: LinearProgressProps) => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
        let isMounted = true;

        value &&
            requestAnimationFrame(() => {
                isMounted && setWidth(getClampedValue(value));
            });

        return () => {
            isMounted = false;
        };
    }, [value]);

    const progressBarLength = {
        width: `${width}%`,
    };

    return (
        <div className="progress-container">
            <p className="eds-text-bs label">{label}</p>
            <div className="progress-bar">
                <div
                    className={`progress progress-bar--${color}`}
                    style={progressBarLength}
                    role="progressbar"
                    aria-valuenow={getClampedValue(value)}
                    aria-valuemin={0}
                    aria-valuemax={100}
                />
            </div>
        </div>
    );
};
