import React, { PureComponent, useRef, useEffect } from 'react';

// original author: vlin
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HotKeys } from 'react-hotkeys';

import Tooltip from './Tooltip';
import { getAdditionalProps } from '@eventbrite/eds-utils';
import { onClickOutsideHOC } from '@eventbrite/eds-utils';
import {
    TOOLTIP_CONTENT_PROPTYPE,
    ARROW_LOCATION_PROPTYPE,
    STYLE_PROPTYPE,
} from './constants';

import { HIDE_CHILD, SHOW_CHILD } from '@eventbrite/eds-hot-keys';
import { ACTION_KEY_MAP } from './hotKeys';

/**
 * withTooltip is an HOC that provides the wrappedComponent with a tooltip.
 *
 * To use the HOC, wrap the component like so:
 *   let ButtonWithTooltip = withTooltip(Button);
 *
 * Then, provide the following props through the wrappedComponent
 *
 * e.g. <ButtonWithTooltip tooltipText="A button"> This is Button Text </ButtonWithTooltip>
 * 1) tooltipText             - required
 * 2) arrowLocation           - optional
 * 3) tooltipStyle            - optional
 * 4) hideTooltipOnMouseLeave - optional
 * 5) keepTooltipOpen         - optional
 * 6) manuallyToggleTooltip   - optional
 * 7) renderOnMount           - optional
 * 8) onShowTooltip           - optional
 *
 * @summary An HOC that provides the wrappedComponent with an accompanying tooltip
 */
const withTooltip = (Component) =>
    class WithTooltipWrapper extends PureComponent {
        static propTypes = {
            /**
             * The text to be set as id for tooltip
             **/
            tooltipId: PropTypes.string.isRequired,

            /**
             * The text or React element to be displayed.
             * If text is provided, it must be a label, no sentences, with a max character count of 32.
             **/
            tooltipText: TOOLTIP_CONTENT_PROPTYPE.isRequired,

            /**
             * Location of arrow for tooltip relative to targetNode. The direction chosen
             * relates to which side of the Tooltip the arrow will appear.
             * Options: left, right, top, bottom
             **/
            tooltipArrowLocation: ARROW_LOCATION_PROPTYPE,

            /**
             * The background color of the Tooltip. Options: basic, phab
             **/
            tooltipStyle: STYLE_PROPTYPE,

            /**
             * If true, the tooltip will hide anytime the mouse leaves the
             * targetNode whether the targetNode has focus or not.
             **/
            hideTooltipOnMouseLeave: PropTypes.bool,

            /**
             * If true, the tooltip will NOT hide anytime the mouse leaves the
             * targetNode whether the targetNode has focus or not.
             **/
            keepTooltipOpen: PropTypes.bool,

            /**
             * If true, the tooltip will only be opened manually by the user, and closed by clicking outside of it.
             **/
            manuallyToggleTooltip: PropTypes.bool,

            /**
             * If true, the tooltip should be visible as soon as it mounts. This handles
             * cases where the user clicks to display the tooltip rather than hovering.
             **/
            renderOnMount: PropTypes.bool,

            /**
             * External event to trigger when tooltip displayed
             **/
            onShowTooltip: PropTypes.func,

            /**
             * Wrapper component tag. Defaults to DIV
             **/
            wrapperComponent: PropTypes.string,

            /**
             * If false, the Tooltip message won't be shown until the user hovers over the targetNode.
             **/
            isOpened: PropTypes.bool,

            __containerClassName: PropTypes.string,
        };

        state = {
            targetNode: null,
            element: null,
            shouldShowTooltip: true,
        };

        hotKeyHandlers = {
            [HIDE_CHILD]: () => this._handleKeyPressedHide(),
            [SHOW_CHILD]: (e) => this._handleFocus(e),
        };

        componentDidUpdate(_prevProps, prevState) {
            if (
                prevState.element === null &&
                !!this.state.element &&
                this.props.renderOnMount
            ) {
                this.show();
            }

            if (_prevProps.isOpened !== this.props.isOpened) {
                this._updateShowTooltip();
            }
        }

        componentDidMount() {
            this._updateShowTooltip();
        }

        _updateShowTooltip() {
            // * Checking for truthy or falsy is not an option. Could be undefined.
            if (this.props.isOpened === false) {
                this.setState({ shouldShowTooltip: false });
            }
        }

        _handleMouseOver = (e) => {
            if (this.props.manuallyToggleTooltip) {
                return;
            }

            this.setState({
                shouldShowTooltip: true,
            });

            this._mouseOverNode = true;

            this._handleShow(e);
        };

        _handleMouseLeave = () => {
            if (
                this.props.keepTooltipOpen ||
                this.props.manuallyToggleTooltip
            ) {
                return;
            }

            this._mouseOverNode = false;

            if (
                (!this._nodeFocused || this.props.hideTooltipOnMouseLeave) &&
                !this._mouseOverNode
            ) {
                this._handleHide();
            }
        };

        _handleFocus = (e) => {
            this._nodeFocused = true;
            this._handleShow(e);
        };

        _handleKeyPressedHide = () => {
            if (this._nodeFocused || this._mouseOverNode) {
                this._nodeFocused = true;
                this._mouseOverNode = false;
                this._handleHide();
            }
        };

        _handleReset = () => {
            if (this.props.keepTooltipOpen) {
                this._nodeFocused = false;
                this._mouseOverNode = false;
                this._handleHide();
            }
            if (this._nodeFocused || this._mouseOverNode) {
                this._nodeFocused = false;
                this._mouseOverNode = false;
                this._handleHide();
            }
        };

        _handleBlur = () => {
            if (this.props.manuallyToggleTooltip) {
                return;
            }

            this._nodeFocused = false;

            if (!this._nodeFocused && !this._mouseOverNode) {
                this._handleHide();
            }
        };

        show = () => {
            this._handleShow({ currentTarget: this.state.element });
        };

        _handleShow = (e) => {
            this.setState({
                targetNode: e.currentTarget,
            });
            this.props?.onShowTooltip?.();
        };

        hide = () => {
            this._handleHide();
        };

        _handleHide = () => {
            this.setState({
                targetNode: null,
            });
        };

        render() {
            const { targetNode, shouldShowTooltip } = this.state;
            const {
                tooltipId,
                tooltipText,
                tooltipArrowLocation,
                tooltipStyle,
                wrapperComponent,
                isOpened,
                __containerClassName,
            } = this.props;
            const componentProps = getAdditionalProps(this);
            const tooltipClasses = classNames(
                'eds-tooltip--hoc-wrapper',
                __containerClassName,
            );
            let describedBy;

            // We don't want `aria-describedby` on the div when no tooltip is
            // present
            if (targetNode) {
                describedBy = tooltipId;
            }

            return (
                <HotKeys
                    component={wrapperComponent}
                    keyMap={ACTION_KEY_MAP}
                    handlers={this.hotKeyHandlers}
                >
                    <ToolTipWrapper
                        tooltipClasses={tooltipClasses}
                        onMouseOver={this._handleMouseOver}
                        onMouseLeave={this._handleMouseLeave}
                        onFocus={this._handleFocus}
                        onBlur={this._handleBlur}
                        onClickOutside={this._handleReset}
                        describedBy={describedBy}
                        onElementReady={(element) => {
                            this.setState({
                                element,
                            });
                        }}
                    >
                        <Component {...componentProps} />

                        <Tooltip
                            tooltipId={tooltipId}
                            targetNode={shouldShowTooltip ? targetNode : null}
                            arrowLocation={tooltipArrowLocation}
                            style={tooltipStyle}
                        >
                            {tooltipText}
                        </Tooltip>
                    </ToolTipWrapper>
                </HotKeys>
            );
        }
    };

const ToolTipWrapper = onClickOutsideHOC(
    ({
        tooltipClasses,
        onMouseOver,
        onMouseLeave,
        onFocus,
        onClick,
        onBlur,
        describedBy,
        children,
        onElementReady,
    }) => {
        const element = useRef(null);

        useEffect(() => {
            let unmounted = false;

            if (element.current && !unmounted) {
                onElementReady(element.current);
            }

            return () => {
                unmounted = true;
            };
            // eslint-disable-next-line
        }, [element.current, onElementReady]);

        return (
            /* Accessibility: This component does have key events, but not picked up by the linter becasue of the HOC */
            /* Ideally this component would be a button element but as it is a HOC, that has already been relased we can't do this. e.g. copy button with tooltip */
            /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
            <div
                ref={element}
                className={tooltipClasses}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                onFocus={onFocus}
                onClick={onClick}
                onBlur={onBlur}
                // `aria-describedby` is based on the <button> tag and not
                // with the component because in cases where component has
                // `aria-hidden` (i.e. <i> ), we still want `aria-describedby`
                // to be present and corresponds to the tooltip id
                aria-describedby={describedBy}
                data-spec="tooltip-wrapper"
                tabIndex="0"
            >
                {children}
            </div>
        );
    },
);

export default withTooltip;
