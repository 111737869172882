import { gettext } from '@eventbrite/i18n';
import {
    trackGetDataError,
    trackGetDataSuccess,
    trackModalClose,
    trackModalOpen,
    trackRatingHelperCenterClick,
    trackRatingsDisplayed,
    trackRatingsLoaded,
    trackRatingsNotDisplayed,
} from '../tracking/analytics';
import {
    heapGetDataError,
    heapGetDataSuccess,
    heapModalClose,
    heapModalOpen,
    heapRatingHelperCenterClick,
    heapRatingsDisplayed,
    heapRatingsLoaded,
    heapRatingsNotDisplayed,
} from '../tracking/heap';
import {
    logGetDataError,
    logModalClose,
    logModalOpen,
    logRatingHelperCenterClick,
} from '../tracking/statsig';
export const MOCK_ORGANIZER = '123';

export const mockedGetRatingSample = {
    rating: 2.9,
    total_count: {
        value: 1456,
        display: '1.45K',
    },
    break_down: [
        {
            rating_star: 5,
            count: 100,
            display: '100',
            percentage: 0.07,
        },
        {
            rating_star: 4,
            count: 300,
            display: '300',
            percentage: 0.21,
        },
        {
            rating_star: 3,
            count: 700,
            display: '700',
            percentage: 0.48,
        },
        {
            rating_star: 2,
            count: 0,
            display: '0',
            percentage: 0,
        },
        {
            rating_star: 1,
            count: 356,
            display: '356',
            percentage: 0.24,
        },
    ],
    tag_summary: [
        {
            display_text: 'Cool/unique venue',
            count: 6,
            display_count: '6',
            id: '11',
            symbol: {
                codes: ['1f3f0'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Groups',
            count: 1,
            display_count: '1',
            id: '14',
            symbol: {
                codes: ['1f389'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Families',
            count: 2,
            display_count: '2',
            id: '15',
            symbol: {
                codes: ['1f46a'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Individuals',
            count: 2,
            display_count: '2',
            id: '16',
            symbol: {
                codes: ['1f468', '1f3fe', '200d', '1f9b0'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Meet people',
            count: 1,
            display_count: '1',
            id: '1',
            symbol: {
                codes: ['1faf1', '1f3fc', '200d', '1faf2', '1f3fe'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Learn something',
            count: 1,
            display_count: '1',
            id: '2',
            symbol: {
                codes: ['1f4da'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Entertain others',
            count: 1,
            display_count: '1',
            id: '3',
            symbol: {
                codes: ['1f939', '1f3fb'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Feel connected',
            count: 4,
            display_count: '4',
            id: '4',
            symbol: {
                codes: ['1f469', '1f3fe', '200d', '1f3a4'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Relax/unwind',
            count: 4,
            display_count: '4',
            id: '5',
            symbol: {
                codes: ['1f9d8', '1f3ff', '200d', '2640', 'fe0f'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Have fun',
            count: 4,
            display_count: '4',
            id: '6',
            symbol: {
                codes: ['2728'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Helpful organizer',
            count: 1,
            display_count: '1',
            id: '7',
            symbol: {
                codes: ['1f4ac'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Excellent value',
            count: 2,
            display_count: '2',
            id: '8',
            symbol: {
                codes: ['1f4b0'],
                label: 'fake code',
            },
        },
        {
            display_text: 'Memorable experience',
            count: 3,
            display_count: '3',
            id: '9',
            symbol: {
                codes: ['1f3c6'],
                label: 'fake code',
            },
        },
        {
            display_text: 'As described',
            count: 3,
            display_count: '3',
            id: '10',
            symbol: {
                codes: ['1f4d0'],
                label: 'fake code',
            },
        },
    ],
};

export const GET_RATING_URL = '/api/v3/reviews/organizer_rating/';
export const ERROR_OTHER = gettext('There is an error. Try again later.');
export const SUPPORT_BASE_URL = 'https://www.eventbrite.com/support';
export const HELP_CENTER_ATTENDEE_URL = `${SUPPORT_BASE_URL}/articleredirect?anum=41782`;
export const HELP_CENTER_ORGANIZER_URL = `${SUPPORT_BASE_URL}/articleredirect?anum=41786`;

export const TRACK_EVENTS_PLATFORMS = ['GA', 'StatSig', 'Heap'];

export const GET_RATING_OBJECT_TRACKING_METHODS = {
    GA: {
        RATINGS_LOADED: (trackCategory: string, organizerId: string) =>
            trackRatingsLoaded(trackCategory, organizerId),
    },
    StatSig: {
        RATINGS_LOADED: () => null,
    },
    Heap: {
        RATINGS_LOADED: (trackCategory: string, organizerId: string) =>
            heapRatingsLoaded(trackCategory, organizerId),
    },
};

export const RATING_DISPLAY_TRACKING_METHODS = {
    GA: {
        RATING_NOT_DISPLAYED: (
            trackCategory: string,
            rating: number,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            organizerId: string,
        ) => trackRatingsNotDisplayed(trackCategory, rating || 0),
        RATING_DISPLAYED: (
            trackCategory: string,
            rating: number,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            organizerId: string,
        ) => trackRatingsDisplayed(trackCategory, rating || 0),
        MODAL_OPEN: (trackCategory: string, organizerId: string) =>
            trackModalOpen(trackCategory, organizerId),
        MODAL_CLOSE: (trackCategory: string, organizerId: string) =>
            trackModalClose(trackCategory, organizerId),
    },
    StatSig: {
        RATING_NOT_DISPLAYED: () => null,
        RATING_DISPLAYED: () => null,
        MODAL_OPEN: (trackCategory: string, organizerId: string) =>
            logModalOpen(trackCategory, organizerId),
        MODAL_CLOSE: (trackCategory: string, organizerId: string) =>
            logModalClose(trackCategory, organizerId),
    },
    Heap: {
        RATING_NOT_DISPLAYED: (
            trackCategory: string,
            rating: number,
            organizerId: string,
        ) => heapRatingsNotDisplayed(trackCategory, rating || 0, organizerId),
        RATING_DISPLAYED: (
            trackCategory: string,
            rating: number,
            organizerId: string,
        ) => heapRatingsDisplayed(trackCategory, rating || 0, organizerId),
        MODAL_OPEN: (trackCategory: string, organizerId: string) =>
            heapModalOpen(trackCategory, organizerId),
        MODAL_CLOSE: (trackCategory: string, organizerId: string) =>
            heapModalClose(trackCategory, organizerId),
    },
};

export const RATING_DETAIL_MODAL_TRACKING_METHODS = {
    GA: {
        HELPER_CENTER_CLICK: (trackCategory: string, organizerId: string) =>
            trackRatingHelperCenterClick(trackCategory, organizerId),
    },
    StatSig: {
        HELPER_CENTER_CLICK: (
            trackCategory: string,
            organizerId: string,
            link: string,
        ) => logRatingHelperCenterClick(trackCategory, organizerId, link),
    },
    Heap: {
        HELPER_CENTER_CLICK: (
            trackCategory: string,
            organizerId: string,
            link: string,
        ) => heapRatingHelperCenterClick(trackCategory, organizerId, link),
    },
};

export const GET_RATING_DATA_TRACKING_METHODS = {
    GA: {
        GET_DATA_SUCCESS: (trackCategory: string, organizerId: string) =>
            trackGetDataSuccess(trackCategory, organizerId),
        GET_DATA_ERROR: (
            trackCategory: string,
            organizerId: string,
            error: string,
        ) => trackGetDataError(trackCategory, organizerId, error),
    },
    StatSig: {
        GET_DATA_SUCCESS: () => null,
        GET_DATA_ERROR: (
            trackCategory: string,
            organizerId: string,
            error: string,
        ) => logGetDataError(trackCategory, organizerId, error),
    },
    Heap: {
        GET_DATA_SUCCESS: (trackCategory: string, organizerId: string) =>
            heapGetDataSuccess(trackCategory, organizerId),
        GET_DATA_ERROR: (
            trackCategory: string,
            organizerId: string,
            error: string,
        ) => heapGetDataError(trackCategory, organizerId, error),
    },
};

export const TLD_DISPLAY = ['.com']; // TLD where we need to display ratings
