import * as React from 'react';

const StarHalfFilledSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="star-fill_svg__eds-icon--star-fill_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.11794 9.42857L12 1.71429L14.8785 9.42857H22.2857L16.6286 14.5714L18.6857 22.2857L11.9995 17.8562L5.31429 22.2857L7.37143 14.5714L1.71429 9.42857H9.11794ZM11.9932 4.6754L14.1645 10.4565H19.6072L15.5058 14.1856L17.0152 19.9517L11.9999 16.6279L11.9932 4.6754Z"
            id="star-fill_svg__eds-icon--star-fill_base"
        />
    </svg>
);

StarHalfFilledSvg.displayName = 'StarHalfFilledSvg';
export default StarHalfFilledSvg;
