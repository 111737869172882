import React, { createContext, useContext, useEffect, useState } from 'react';
import { TRACK_EVENTS_PLATFORMS } from '../constants';
import { useGetRatingObject } from '../hooks';
import { RatingData } from '../types';
export interface TrustSignalsProviderProps {
    children?: any;
    organizerId: string;
    trackCategory: string;
}

type TrustSignalsContextValue = {
    ratingData?: RatingData;
    tagsData?: RatingData['tag_summary'];
};

const contextInitialState: TrustSignalsContextValue = {
    ratingData: undefined,
    tagsData: undefined,
};

const TrustSignalsContext = createContext(contextInitialState);

export const TrustSignalsProvider = (props: TrustSignalsProviderProps) => {
    const [ratingData, setRatingData] = useState(
        contextInitialState.ratingData,
    );
    const [tagsData, setTagsData] = useState(contextInitialState.tagsData);

    const { ratingObject } = useGetRatingObject({
        organizerId: props.organizerId,
        trackCategory: props.trackCategory,
        trackingMethods: TRACK_EVENTS_PLATFORMS,
    });

    useEffect(() => {
        setRatingData(ratingObject);
        setTagsData(ratingObject?.tag_summary);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratingObject]);

    return (
        <TrustSignalsContext.Provider
            value={{
                ratingData,
                tagsData,
            }}
        >
            {props.children}
        </TrustSignalsContext.Provider>
    );
};

export const useTrustSignalsContext = () => useContext(TrustSignalsContext);
