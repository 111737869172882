import React from 'react';
import { trackClickOnSectionTab } from '../../tracking/analytics';
import { useTabNavigationContext } from './context';
import { useTabVisibility } from './hooks';
import { TabNavLinkProps } from './types';

export const TabNavLink: React.FunctionComponent<TabNavLinkProps> = (props) => {
    const { activeTab, removeTab } = useTabNavigationContext();
    const isVisible = useTabVisibility(props.hide || '');

    React.useEffect(() => {
        if (!isVisible) {
            if (typeof props.index === 'number') removeTab?.(props.index);
        }
    }, [removeTab, props.index, isVisible]);

    if (!isVisible) {
        return null;
    }

    return (
        <li
            className={`${props.containerClassName} eds-text-bm eds-text-weight--heavy`}
        >
            <a
                onClick={() => trackClickOnSectionTab(props.id)}
                className={`${
                    props.index === activeTab
                        ? 'tab-navigation__active-tab'
                        : ''
                }`}
                href={`#${props.id}`}
            >
                {props.children}
            </a>
        </li>
    );
};
