import { sdkRequest } from '@eventbrite/http';
import chunk from 'lodash/chunk';
import {
    DEFAULT_DESTINATION_EXPANSIONS,
    DESTINATION_EVENTS_ENDPOINT,
} from '../constants';
import { transformDestinationEvent } from '../transforms';
import {
    DestinationEvent,
    DestinationResponse,
    FormattedEvent,
} from '../types';

export const getEventsById = async (
    ids: string[],
    chunkSize = 50,
    destination_expansions: string[] = DEFAULT_DESTINATION_EXPANSIONS,
): Promise<{ events: FormattedEvent[] }> => {
    if (chunkSize > 50) {
        throw Error(
            'Arguments error. getEventsById supports a maximum chuink size of 50',
        );
    }

    const batchedIds = chunk(ids, chunkSize);

    const promiseArray = batchedIds.map(
        (idGroup): Promise<DestinationResponse> => {
            const url = `${DESTINATION_EVENTS_ENDPOINT}?event_ids=${idGroup}&expand=${destination_expansions}&page_size=${chunkSize}&include_parent_events=true`;
            return sdkRequest(url);
        },
    );

    return Promise.all(promiseArray).then((values) => {
        const mergedEvents = values.reduce(
            (memo: DestinationEvent[], response) => {
                memo = [...memo, ...response.events];

                return memo;
            },
            [],
        );

        return {
            events: mergedEvents.map(transformDestinationEvent),
        };
    });
};
