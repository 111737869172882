import { Button, STYLE_LINK } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React, { useContext, useEffect, useState } from 'react';
import { ORGANIZER_CONTACT_CLICK } from '../constants';
import { EnvContext, OrganizerContext, UserContext } from '../context';

interface LazyLoadedLib {
    ContactOrganizer: any;
}

export const ContactOrganizerButton: React.FunctionComponent = () => {
    const [isVisible, setVisible] = useState(false);
    const [{ ContactOrganizer }, setLazyLoadedLib] = useState<
        Partial<LazyLoadedLib>
    >({ ContactOrganizer: null });

    const handleLazyLoadedLib = async () => {
        if (!ContactOrganizer) {
            const promise = import('@eventbrite/contact-organizer');
            const lib = await promise;
            setLazyLoadedLib({ ContactOrganizer: lib['ContactOrganizer'] });
        }
    };

    useEffect(() => {
        if (window.location.search.includes('contact_organizer')) {
            handleLazyLoadedLib();
            setVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { isAuthenticated } = useContext(UserContext);
    const { captchaKey } = useContext(EnvContext);
    const { id } = useContext(OrganizerContext);

    return (
        <>
            <Button
                onMouseEnter={() => handleLazyLoadedLib()}
                onFocus={() => handleLazyLoadedLib()}
                style={STYLE_LINK}
                onClick={() => {
                    logEvent(ORGANIZER_CONTACT_CLICK);
                    setVisible(!isVisible);
                }}
            >
                {gettext('Contact the organizer')}
            </Button>
            {ContactOrganizer && (
                <ContactOrganizer
                    isAuthenticated={isAuthenticated}
                    captchaKey={captchaKey}
                    isVisible={isVisible}
                    organizerId={id}
                    onClose={() => setVisible(false)}
                />
            )}
        </>
    );
};
