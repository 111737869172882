import React from 'react';
import { Organizer } from '../../types';
import { ReadMore } from '../ReadMore';
import { removeInlineStyleTags } from './utils';

interface OrganizerSummary {
    organizer?: Organizer;
}

export const OrganizerSummary: React.FunctionComponent<OrganizerSummary> = (
    props,
) => {
    const { organizer } = props;

    const hasLongDescription = !!organizer?.long_description?.text;
    const hasDescription = !!organizer?.description?.text;

    if (!hasLongDescription && !hasDescription) {
        return null;
    }

    const content = hasLongDescription
        ? organizer?.long_description?.html || ''
        : organizer?.description?.html || '';

    const cleanedContent = removeInlineStyleTags(content);
    const dangerouslySetContent = (
        //eslint-disable-next-line
        <span dangerouslySetInnerHTML={{ __html: cleanedContent }} />
    );

    return (
        <div className="organizer-profile-header__description">
            <ReadMore>{dangerouslySetContent}</ReadMore>
        </div>
    );
};
