export const GA_ORGANIZER_CATEGORY = 'organizer';

// GA tracking dropdown
export const GA_ACTION_PROFILE_DROPDOWN = 'ProfileDropdown';
export const GA_CATEGORY_HEADER = 'header';
export const GA_LABEL_CREATOR_EVENT = 'ViewFromConsumer';

// GA tracking actions
export const GA_PROFILE_CATEGORY = 'organizer-profile';
export const GA_PROFILE_TAB_ACTION = 'OrgProfileNavigationTab';
export const GA_PROFILE_SOCIAL_ACTION = 'ExternalSiteClicked';
export const GA_PROFILE_SHOW_ACTION = 'OrganizerDescription';
export const GA_PROFILE_CLICK_TAB_ACTION = 'OrgProfileClickOnTab';
export const GA_PROFILE_CLICK_ON_HELPER_CENTER_LINK_ACTION =
    'OrgProfileClickOnHelperCenterLink';

export const ORGANIZER_PROFILE_RUM_SAMPLE_RATE = 1;

export const ORGANIZER_ABOUT_TYPES = ['mobile', 'desktop'] as const;

export const ORGANIZER_EVENT_CARD_CLICK =
    'Organizer profile - All - Click - Event Card';
export const ORGANIZER_CONTACT_CLICK =
    'Organizer profile - All - Click - Contact';
export const ORGANIZER_FOLLOW_MESSAGE_VIEW =
    'Organizer profile - All - Follow Message - View';
export const ORGANIZER_FOLLOW_MESSAGE_CLOSE =
    'Organizer profile - All - Follow Message - Close Click';
export const ORGANIZER_FOLLOW_CLICK =
    'Organizer profile - All - Click - Follow';
export const ORGANIZER_UNFOLLOW_CLICK =
    'Organizer profile - All - Click - Unfollow';
export const ORGANIZER_ABOUT_TAB =
    'Organizer profile - All - Click - About Tab';

export const STATSIG_LOCATION_STRING = 'Organizer profile - All';
export const EVENT_CARD_AFFILIATE_CODE = 'ebdsoporgprofile';

export const TOTAL_RATING_THRESHOLD = 3;

export const SURFACING_RATING_EXPERIMENT = {
    experimentName: 'listing_page_surfacing_rating_experiment',
    param: 'variant_type',
    defaultValue: 'control',
    experimentLoaded: 'OrganizerProfileSurfacingRatingExperimentLoaded',
    eligibleOrganizerControlVariant: 'OrganizerProfileEligibleControlVariant',
};
