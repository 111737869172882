import { useEffect, useState } from 'react';
import { useTabNavigationContext } from '../context';

export const useTabVisibility = (breakpointToHide: string): boolean => {
    const { currentBreakpoint } = useTabNavigationContext();
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (breakpointToHide === currentBreakpoint) {
            setIsVisible(false);
        } else if (breakpointToHide !== currentBreakpoint) {
            setIsVisible(true);
        }
    }, [breakpointToHide, currentBreakpoint]);

    return isVisible;
};
