import { getShortenedNumber } from '@eventbrite/eds-utils';

// CESS will return no greater than 10,000 events
// Display as +9.9K when the value is greater
export const RETURN_COUNT_UPPER_LIMIT = 9900;

/**
 * Limits the upper return value to a constant RETURN_COUNT_UPPER_LIMIT
 */
export const formatReturnCountLimit = (eventCount: number): string => {
    if (!eventCount) return '0';
    if (eventCount > RETURN_COUNT_UPPER_LIMIT) return '+9.9K';
    // else, display the shortened value. ex: 4,500 == 4.5K
    return getShortenedNumber(eventCount);
};
