export const normalizeOrganizers = (organizers) =>
    organizers.reduce((acc, org) => {
        const {
            id,
            name,
            url,
            logo,
            num_future_events: numUpcomingEvents,
            follow_status: {
                followed_by_you: followedByYou,
                num_followers: numFollowers,
            } = {},
        } = org;

        const profilePicture = logo ? logo.url : undefined;

        return {
            [id]: {
                id,
                name,
                url,
                profilePicture,
                followedByYou,
                numFollowers,
                numUpcomingEvents,
            },
            ...acc,
        };
    }, {});
