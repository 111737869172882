import { IconButton } from '@eventbrite/eds-icon-button';
import {
    GlobeChunky,
    LogoFacebook,
    LogoTwitter,
} from '@eventbrite/eds-iconography';
import { setWindowLocation } from '@eventbrite/http';
import { gettext } from '@eventbrite/i18n';
import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import React from 'react';
import { GA_PROFILE_CATEGORY, GA_PROFILE_SOCIAL_ACTION } from '../../constants';
import './SocialWidget.scss';

interface SocialWidgetProps {
    twitter?: string;
    facebook?: string;
    website?: string;
}

enum SocialTypes {
    'facebook',
    'twitter',
    'website',
}

interface SocialIconProps {
    type: SocialTypes;
    data?: string;
}

const handleOnClick = (
    label: string,
    callback: Function,
    path: string,
    id?: string,
) => {
    trackAnalyticsEvent({
        category: GA_PROFILE_CATEGORY,
        label: label,
        action: GA_PROFILE_SOCIAL_ACTION,
    });
    callback(`${path}${id || ''}`);
};

const SOCIAL_ICON_MAP = {
    [SocialTypes.facebook]: {
        icon: LogoFacebook,
        title: gettext('Facebook profile'),
        path: 'https://www.facebook.com/',
        onClick: (id: string) =>
            handleOnClick(
                SocialTypes[SocialTypes.facebook],
                setWindowLocation,
                'https://www.facebook.com/',
                id,
            ),
    },
    [SocialTypes.twitter]: {
        icon: LogoTwitter,
        title: gettext('Twitter profile'),
        path: 'https://www.twitter.com/',
        onClick: (id: string) =>
            handleOnClick(
                SocialTypes[SocialTypes.twitter],
                setWindowLocation,
                'https://www.twitter.com/',
                id,
            ),
    },
    [SocialTypes.website]: {
        icon: GlobeChunky,
        title: gettext('Organizer website'),
        onClick: (path: string) =>
            handleOnClick(
                SocialTypes[SocialTypes.website],
                setWindowLocation,
                path,
            ),
        path: '',
    },
};

const SocialIcon: React.FunctionComponent<SocialIconProps> = (props) => {
    if (!props.data) {
        return null;
    }

    const {
        icon: IconType,
        title,
        onClick,
        path,
    } = SOCIAL_ICON_MAP[props.type];

    return (
        <IconButton
            type="link"
            title={title}
            iconType={<IconType />}
            onClick={() => onClick(props.data || '')}
            href={path ? `${path}${props.data}` : props.data}
        />
    );
};

export const SocialWidget: React.FunctionComponent<SocialWidgetProps> = (
    props,
) => {
    return (
        <div className="social-widget">
            <SocialIcon type={SocialTypes.facebook} data={props.facebook} />
            <SocialIcon type={SocialTypes.twitter} data={props.twitter} />
            <SocialIcon type={SocialTypes.website} data={props.website} />
        </div>
    );
};
