import { trackAnalyticsEvent } from '@eventbrite/site-analytics';
import { EventStates } from '../components/OrganizerEvents';
import {
    GA_PROFILE_CATEGORY,
    GA_PROFILE_CLICK_ON_HELPER_CENTER_LINK_ACTION,
    GA_PROFILE_CLICK_TAB_ACTION,
    GA_PROFILE_SHOW_ACTION,
    GA_PROFILE_SOCIAL_ACTION,
    GA_PROFILE_TAB_ACTION,
} from '../constants';
export const trackClickOnSocialWidget = (label: string) => {
    trackAnalyticsEvent({
        category: GA_PROFILE_CATEGORY,
        label: label,
        action: GA_PROFILE_SOCIAL_ACTION,
    });
};

export const trackClickOnOrganizerEventsTags = (state: number) => {
    trackAnalyticsEvent({
        category: GA_PROFILE_CATEGORY,
        label: EventStates[state],
        action: GA_PROFILE_TAB_ACTION,
    });
};

export const trackClickOnReadMoreOrganizerDescription = (state: boolean) => {
    trackAnalyticsEvent({
        category: GA_PROFILE_CATEGORY,
        label: state ? 'show more' : 'show less',
        action: GA_PROFILE_SHOW_ACTION,
    });
};

export const trackClickOnSectionTab = (id: string) => {
    trackAnalyticsEvent({
        category: GA_PROFILE_CATEGORY,
        label: id,
        action: GA_PROFILE_CLICK_TAB_ACTION,
    });
};

export const trackClickHelperCenterLink = () => {
    trackAnalyticsEvent({
        category: GA_PROFILE_CATEGORY,
        action: GA_PROFILE_CLICK_ON_HELPER_CENTER_LINK_ACTION,
    });
};
