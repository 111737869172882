import React from 'react';
import classNames from 'classnames';

import { Button } from '@eventbrite/eds-button';
import { SIZE_BLOCK, STYLE_LINK } from '@eventbrite/eds-button';
import { Divider } from '@eventbrite/eds-divider';
import { IconButton } from '@eventbrite/eds-icon-button';
import { NotificationBar } from '@eventbrite/eds-notification';

import { MODAL_BODY_PROP_TYPES, MODAL_TITLE_ID } from './constants';

const _getNotificationClasses = (notificationOptions) => {
    const {
        isClosing,
        shouldFloatAboveContent,
        shouldAnimate,
        //Modal default behavior has been
        //to display notifications full width
        //at all breakpoints. This continues
        //that pattern but allows for user
        //to configure otherwise. Particularly
        //when using a ComplexModal on full
        //screen experiences.
        fullWidth = true,
        ...prunedOptions
    } = notificationOptions;

    const classes = classNames('eds-modal__notification', {
        'eds-modal__notification--is-closing': isClosing,
        'eds-modal__notification--abs': shouldFloatAboveContent,
        'eds-modal__notification--animate': shouldAnimate,
        'eds-g-cell-md-10-12': !fullWidth,
        'eds-g-offset-md-1-12': !fullWidth,
        'eds-g-cell-12-12': fullWidth,
    });

    return { classes, prunedOptions };
};

const _getNotifications = (notificationOptions) => {
    let notifications = null;

    if (notificationOptions) {
        const notificationOptionsList = Array.isArray(notificationOptions)
            ? notificationOptions
            : [notificationOptions];

        notifications = notificationOptionsList.map((notificationOption) => {
            const { classes, prunedOptions } =
                _getNotificationClasses(notificationOption);
            let key = notificationOption.children;

            if (
                notificationOption.children &&
                notificationOption.children.key
            ) {
                key = notificationOption.children.key;
            }

            return (
                <span className={classes} key={key}>
                    <NotificationBar {...prunedOptions} />
                </span>
            );
        });
    }

    return notifications;
};

const Header = ({
    customClassName,
    headerActionIconType,
    headerActionIconTypeClassName,
    headerActionTitle,
    hideDivider,
    onHeaderAction,
    secondaryTitle,
    secondaryTitleClassName,
    title,
    titleClassName,
}) => {
    let component = null;

    if (title) {
        const extraProps = hideDivider ? { hideDivider } : {};

        component = (
            <header
                className={classNames(
                    'eds-modal__header eds-align--center-vertical',
                    customClassName,
                )}
            >
                <HeaderAction
                    headerActionIconType={headerActionIconType}
                    headerActionIconTypeClassName={
                        headerActionIconTypeClassName
                    }
                    headerActionTitle={headerActionTitle}
                    onHeaderAction={onHeaderAction}
                />
                <TitleHeader
                    secondaryTitleClassName={secondaryTitleClassName}
                    secondaryTitle={secondaryTitle}
                    title={title}
                    titleClassName={titleClassName}
                    {...extraProps}
                />
            </header>
        );
    }

    return component;
};

const HeaderAction = ({
    headerActionIconType,
    headerActionIconTypeClassName,
    headerActionTitle,
    onHeaderAction,
}) => {
    let component = null;

    if (headerActionIconType) {
        const headerActionIconWrapperClassNames = classNames(
            'eds-modal__secondary-header-action',
            headerActionIconTypeClassName,
        );
        component = (
            <div className={headerActionIconWrapperClassNames}>
                <IconButton
                    data-automation="modal-header-action-button"
                    data-spec="secondary-header-action"
                    onClick={onHeaderAction}
                    iconType={headerActionIconType}
                    title={headerActionTitle}
                />
            </div>
        );
    }

    return component;
};

const TitleHeader = ({
    hideDivider,
    secondaryTitle,
    secondaryTitleClassName,
    title,
    titleClassName,
}) => {
    const divider = !hideDivider ? (
        <div className="eds-modal__title-spacing">
            <Divider />
        </div>
    ) : null;

    return title ? (
        <div className="eds-modal__title-container eds-modal__title-spacing">
            <h2
                id={MODAL_TITLE_ID}
                className={classNames(
                    'eds-text-bl--fixed eds-text--truncate eds-modal__title eds-text-color--ui-800',
                    titleClassName,
                )}
                data-spec="eds-modal__title"
                data-testid="eds-modal__title"
            >
                {title}
            </h2>
            {secondaryTitle && (
                <span
                    className={classNames(
                        'eds-l-pad-hor-4 eds-l-mar-top-4 eds-align--center eds-text-bs--fixed eds-text-color--ui-600',
                        secondaryTitleClassName,
                    )}
                    data-spec="eds-modal__secondary-title"
                    data-testid="eds-modal__secondary-title"
                >
                    {secondaryTitle}
                </span>
            )}
            {divider}
        </div>
    ) : null;
};

const Illustration = ({ illustration }) => {
    let component = null;

    if (illustration) {
        component = (
            <div className="eds-modal__illustration">{illustration}</div>
        );
    }

    return component;
};

const PrimaryButton = ({
    onPrimaryAction,
    primaryText,
    primaryType,
    primarySize,
    primaryAttributes,
    primaryIsLoading,
    primaryIsDisabled,
    primaryKeepDisabledStyleWhileLoading,
}) => {
    let component = null;
    const automationSelector = 'eds-modal__primary-button';

    if (primaryText) {
        component = (
            <Button
                {...primaryAttributes}
                onClick={onPrimaryAction}
                style={primaryType}
                disabled={primaryIsDisabled}
                keepDisabledStyleWhileLoading={
                    primaryKeepDisabledStyleWhileLoading
                }
                data-spec={automationSelector}
                data-automation={automationSelector}
                isLoading={primaryIsLoading}
                size={primarySize}
            >
                {primaryText}
            </Button>
        );
    }

    return component;
};

const SecondaryButton = ({
    onSecondaryAction,
    secondaryText,
    secondaryAttributes,
    secondaryType,
    secondaryIsDisabled,
    secondaryIsLoading,
}) => {
    let component = null;

    if (secondaryText) {
        component = (
            <div className="eds-l-mar-right-4">
                <Button
                    {...secondaryAttributes}
                    onClick={onSecondaryAction}
                    style={secondaryType}
                    disabled={secondaryIsDisabled}
                    isLoading={secondaryIsLoading}
                    data-spec="eds-modal__secondary-button"
                >
                    {secondaryText}
                </Button>
            </div>
        );
    }

    return component;
};

const TertiaryButton = ({ onTertiaryAction, tertiaryNode }) => {
    let component = null;

    if (tertiaryNode) {
        component = onTertiaryAction ? (
            <Button
                onClick={onTertiaryAction}
                style="none"
                __containerClassName="eds-modal__button-bar__tertiary"
            >
                {tertiaryNode}
            </Button>
        ) : (
            <div className="eds-modal__button-bar__tertiary">
                {tertiaryNode}
            </div>
        );
    }

    return component;
};

/**
 * When there is a message to display in the button bar. returns the requisite markup.
 * When there is no message, returns null.
 *
 * @param {String} message - If null or undefined, the component is null
 * @param {Boolean} hasBlockButton
 * @param {String[]} customClassNames- Custom class names to add to the root node of this component (optional, defaults to [])
 */
const ButtonBarMessage = ({
    message,
    hasBlockButton,
    customClassnames = [],
}) => {
    let component = null;
    let messageClasses;

    if (message) {
        messageClasses = classNames(
            'eds-modal__message eds-text-bs eds-text-color--ui-500',
            ...customClassnames,
            {
                'eds-modal__message--right': !hasBlockButton,
            },
        );

        component = <div className={messageClasses}>{message}</div>;
    }

    return component;
};

const ButtonBar = ({
    primaryType,
    primaryText,
    primaryAttributes,
    primaryIsDisabled,
    primaryKeepDisabledStyleWhileLoading,
    primaryIsLoading,
    primarySize,
    onPrimaryAction,
    secondaryType,
    secondaryText,
    secondaryAttributes,
    secondaryIsDisabled,
    secondaryIsLoading,
    onSecondaryAction,
    title,
    tertiaryNode,
    onTertiaryAction,
    buttonBarMessage,
    shouldShowFooter,
    __footerContainerClassName,
}) => {
    const hasBlockButton =
        primarySize === SIZE_BLOCK && !secondaryText && !tertiaryNode;

    const footerClasses = classNames(
        {
            'eds-modal__footer--block': hasBlockButton,
        },
        __footerContainerClassName,
    );

    const buttonBarClasses = classNames('eds-modal__button-bar', {
        'eds-modal__button-bar--with-title': title,
        'eds-modal__button-bar--with-tertiary': tertiaryNode,
    });

    const primarySecondaryClasses = classNames(
        'eds-modal__button-bar__primary-secondary',
        {
            'eds-align--right': !hasBlockButton,
            'eds-modal__button-bar--block': hasBlockButton,
        },
    );

    if (!(primaryText || shouldShowFooter)) {
        return null;
    }

    return (
        <ModalFooter __footerContainerClassName={footerClasses}>
            <nav className={buttonBarClasses} role="none">
                <div className={primarySecondaryClasses}>
                    <SecondaryButton
                        onSecondaryAction={onSecondaryAction}
                        secondaryText={secondaryText}
                        secondaryType={secondaryType}
                        secondaryAttributes={secondaryAttributes}
                        secondaryIsDisabled={secondaryIsDisabled}
                        secondaryIsLoading={secondaryIsLoading}
                    />

                    <PrimaryButton
                        onPrimaryAction={onPrimaryAction}
                        primaryText={primaryText}
                        primaryType={primaryType}
                        primarySize={primarySize}
                        primaryAttributes={primaryAttributes}
                        primaryIsDisabled={primaryIsDisabled}
                        primaryKeepDisabledStyleWhileLoading={
                            primaryKeepDisabledStyleWhileLoading
                        }
                        primaryIsLoading={primaryIsLoading}
                    />
                </div>
                <TertiaryButton
                    onTertiaryAction={onTertiaryAction}
                    tertiaryNode={tertiaryNode}
                />
                <ButtonBarMessage
                    customClassnames={['eds-align--center-vertical']}
                    hasBlockButton={hasBlockButton}
                    message={buttonBarMessage}
                />
            </nav>
        </ModalFooter>
    );
};

export const ModalContent = ({
    className,
    children,
    title,
    secondaryTitle,
    illustration,
    headerActionIconType,
    headerActionIconTypeClassName,
    headerActionTitle,
    headerClassName,
    hideDivider = false,
    onHeaderAction,
    notificationOptions,
    secondaryTitleClassName,
    titleClassName,
}) => {
    const notifications = _getNotifications(notificationOptions);
    const mainClasses = classNames(
        'eds-modal__main',
        {
            'eds-modal__main--row': illustration,
        },
        className,
    );

    const headerExtraProps = hideDivider ? { hideDivider } : {};

    return (
        <div className="eds-modal__container">
            <Header
                customClassName={headerClassName}
                headerActionIconType={headerActionIconType}
                headerActionIconTypeClassName={headerActionIconTypeClassName}
                headerActionTitle={headerActionTitle}
                onHeaderAction={onHeaderAction}
                secondaryTitle={secondaryTitle}
                secondaryTitleClassName={secondaryTitleClassName}
                title={title}
                titleClassName={titleClassName}
                {...headerExtraProps}
            />
            <main
                className={mainClasses}
                data-spec="eds-modal__main"
                data-testid="eds-modal__main"
            >
                <Illustration illustration={illustration} />
                <div className="eds-modal__content">
                    {notifications}
                    {children}
                </div>
            </main>
        </div>
    );
};

export const ModalContentChildren = ({ children }) => {
    return (
        <div
            id="edsModalContentChildren"
            className="eds-modal__content__children"
        >
            {children}
        </div>
    );
};

export const ModalFooter = ({
    children,
    __footerContainerClassName,
    __footerOuterContainerClassName,
}) => {
    const footerInnerContainerClasses = classNames(
        'eds-modal__footer',
        __footerContainerClassName,
    );

    const footerOuterContainerClasses = classNames(
        'eds-modal__footer-background',
        __footerOuterContainerClassName,
    );

    return (
        <div className={footerOuterContainerClasses}>
            <div
                className={footerInnerContainerClasses}
                data-spec="eds-modal__footer"
            >
                {children}
            </div>
        </div>
    );
};

export default class ModalBody extends React.PureComponent {
    static propTypes = MODAL_BODY_PROP_TYPES;

    static defaultProps = {
        primaryType: STYLE_LINK,
        secondaryType: STYLE_LINK,
    };

    render() {
        const {
            children,
            title,
            titleClassName,
            secondaryTitle,
            illustration,
            headerActionIconType,
            headerActionTitle,
            onHeaderAction,
            primaryType,
            primaryText,
            primaryAttributes,
            primarySize,
            primaryIsDisabled,
            primaryKeepDisabledStyleWhileLoading,
            primaryIsLoading,
            onPrimaryAction,
            secondaryType,
            secondaryText,
            secondaryAttributes,
            secondaryIsDisabled,
            secondaryIsLoading,
            secondaryTitleClassName,
            onSecondaryAction,
            tertiaryNode,
            notificationOptions,
            onTertiaryAction,
            buttonBarMessage,
            shouldShowFooter,
            __footerContainerClassName,
        } = this.props;

        return (
            <ModalContent
                title={title}
                titleClassName={titleClassName}
                secondaryTitle={secondaryTitle}
                secondaryTitleClassName={secondaryTitleClassName}
                illustration={illustration}
                headerActionIconType={headerActionIconType}
                headerActionTitle={headerActionTitle}
                onHeaderAction={onHeaderAction}
                notificationOptions={notificationOptions}
            >
                <ModalContentChildren>{children}</ModalContentChildren>

                <ButtonBar
                    primaryType={primaryType}
                    primaryText={primaryText}
                    primaryAttributes={primaryAttributes}
                    primarySize={primarySize}
                    primaryIsDisabled={primaryIsDisabled}
                    primaryKeepDisabledStyleWhileLoading={
                        primaryKeepDisabledStyleWhileLoading
                    }
                    onPrimaryAction={onPrimaryAction}
                    secondaryType={secondaryType}
                    secondaryText={secondaryText}
                    secondaryAttributes={secondaryAttributes}
                    secondaryIsDisabled={secondaryIsDisabled}
                    onSecondaryAction={onSecondaryAction}
                    title={title}
                    primaryIsLoading={primaryIsLoading}
                    secondaryIsLoading={secondaryIsLoading}
                    tertiaryNode={tertiaryNode}
                    onTertiaryAction={onTertiaryAction}
                    buttonBarMessage={buttonBarMessage}
                    shouldShowFooter={shouldShowFooter}
                    __footerContainerClassName={__footerContainerClassName}
                />
            </ModalContent>
        );
    }
}
