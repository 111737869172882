import { Tag } from '@eventbrite/eds-tag';
import React from 'react';
import { ReviewTagProps } from '../../types';
import './ReviewTag.scss';

interface EmojiProps {
    className?: string;
    label: string;
    codes: string[];
}

const Emoji = ({ className, label, codes }: EmojiProps) => {
    const getParamsFromArray = (arr: string[]) =>
        arr?.map((param: string) => parseInt(param, 16));

    return (
        <span className={className} role="img" aria-label={label}>
            {String.fromCodePoint(...getParamsFromArray(codes))}
        </span>
    );
};

export const ReviewTag = ({
    displayText,
    displayCount,
    onActiveToggle,
    isSelected,
    isDisabled,
    displayOnly,
    symbol,
    style = 'default',
}: ReviewTagProps) => {
    const displayString =
        displayCount && !symbol
            ? `${displayText} (${displayCount})`
            : displayText;
    const tagStyles = displayOnly ? 'outlined' : style;
    return (
        <Tag
            onActiveToggle={onActiveToggle}
            initialIsActive={isSelected}
            isStatic={displayOnly}
            isDisabled={isDisabled}
            style={tagStyles}
            __containerClassName="review-tag__container"
        >
            {symbol ? (
                <Emoji
                    codes={symbol?.codes}
                    className="tag-emoji"
                    label={symbol?.label}
                />
            ) : null}
            {displayString}
        </Tag>
    );
};
