import { getShortenedNumber } from '@eventbrite/eds-utils';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { RatingData, RatingDisplay } from '@eventbrite/trust-signals-lib';
import classnames from 'classnames';
import React from 'react';
import { GA_PROFILE_CATEGORY } from '../constants';
import { UseTrustSignalsContext } from '../context';
import { Organizer } from '../types';
import { OrganizerTrustSignalsVariants } from '../types/organizer';
import { formatReturnCountLimit } from '../utils';
import './OrganizerTrustSignals.scss';

export interface OrganizerTrustSignalsProps {
    followCount: number;
    totalEvents: number;
    organizer: Organizer;
    variant?: OrganizerTrustSignalsVariants;
    ratingSummary?: RatingData;
}

interface SignalDisplayProps {
    count: any;
    title: GenericLazyString;
    variant: OrganizerTrustSignalsVariants;
}

const SignalDisplay = ({ count, title, variant }: SignalDisplayProps) => {
    const containerClassNames = classnames('signal-display__container', {
        'condensed-variant': variant === 'condensed',
    });
    const countClassNames = classnames({
        'eds-text-color--ui-900 eds-text-bs': variant === 'condensed',
        'eds-text-bl eds-text-weight--bold': variant === 'regular',
    });
    const titleClassNames = classnames('eds-text-bs', {
        'eds-text-color--ui-600': variant === 'regular',
        'eds-text-color--ui-900': variant === 'condensed',
    });
    return (
        <div
            className={containerClassNames}
            data-testid="trust-signals-display-container"
        >
            <span className={countClassNames}>{count}</span>
            <p className={titleClassNames}>{title}</p>
        </div>
    );
};

export const OrganizerTrustSignals = ({
    followCount,
    totalEvents,
    organizer,
    variant = 'regular',
    ratingSummary,
}: OrganizerTrustSignalsProps) => {
    const [showLoader, setShowLoader] = React.useState(true);
    const { setRatingData, enableDisplayRatings } = UseTrustSignalsContext();
    const followCountTitle =
        variant === 'condensed' ? gettext('followers') : gettext('Followers');
    const totalEventsTitle =
        variant === 'condensed' ? gettext('events') : gettext('Total events');

    const signalsData = [
        { count: getShortenedNumber(followCount), title: followCountTitle },
        {
            count: formatReturnCountLimit(totalEvents),
            title: totalEventsTitle,
        },
    ];

    const signalsContainerClassNames = classnames(
        'organizer-trust-signals__container',
        { condensed: variant === 'condensed' },
        { 'loading-container': showLoader },
    );

    React.useEffect(() => {
        setShowLoader(followCount == null && totalEvents == null);
    }, [followCount, totalEvents]);

    if (showLoader) {
        return <div className={signalsContainerClassNames} />;
    }

    return (
        <div className={signalsContainerClassNames}>
            {enableDisplayRatings && (
                <RatingDisplay
                    organizerData={{
                        organizerName: organizer.name,
                        organizerId: organizer.id,
                    }}
                    size={variant === 'condensed' ? 'xsmall' : 'medium'}
                    onGetRatingData={setRatingData}
                    trackCategory={GA_PROFILE_CATEGORY}
                    ratingSummary={ratingSummary}
                    filterRating
                />
            )}
            {signalsData.map(({ count, title }, idx) => {
                if (!count || count === '0') return null;
                return (
                    <SignalDisplay
                        key={idx}
                        count={count}
                        title={title}
                        variant={variant}
                    />
                );
            })}
        </div>
    );
};
