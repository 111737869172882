import { Icon } from '@eventbrite/eds-icon';
import { Star, StarFill } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import classnames from 'classnames';
import React from 'react';
import { widgetSize } from '../../types';
import { SIZES_FROM_WIDGET } from './constants';

interface RatingEmptyStateProps {
    size: widgetSize;
}
export const RatingEmptyState = ({ size }: RatingEmptyStateProps) => {
    const containerClassNames = classnames('empty-state--container', {
        'medium-size': size === 'medium',
    });

    const starType = size === 'medium' ? <StarFill /> : <Star />;

    return (
        <div className={containerClassNames} data-testid="empty-state">
            <div className="empty-state--icon-wrapper">
                <Icon
                    type={starType}
                    title="Empty star"
                    size={SIZES_FROM_WIDGET[size].icon}
                    color="ui-orange"
                />
                {size === 'medium' && (
                    <p className="eds-text-bl eds-text-weight--bold eds-text-color--ui-500">
                        0.0
                    </p>
                )}
            </div>
            <p
                className={`eds-text-${SIZES_FROM_WIDGET[size].reviewNumber} eds-text-color--ui-600 eds-l-mar-left-1`}
            >
                {size === 'medium'
                    ? gettext('Not enough')
                    : gettext('Not enough ratings')}
            </p>
        </div>
    );
};
