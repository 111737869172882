import React, { useRef } from 'react';
import { useIntersection } from 'react-use';
import { useTabNavigationContext } from './context';
import { useTabVisibility } from './hooks';
import { TabSectionProps } from './types';

const THRESHHOLD = [0, 0.25, 0.5, 0.75, 1];

export const TabSection: React.FunctionComponent<TabSectionProps> = (props) => {
    const intersectionRef = useRef(null);
    const intersectionVal = useRef(null);
    const { setTabIntersection } = useTabNavigationContext();
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px 0px -62% 0px',
        threshold: THRESHHOLD,
        ...props.intersectionOptions,
    });
    const isVisible = useTabVisibility(props.hide || '');

    if (!isVisible) {
        return null;
    }

    if (!props.children) {
        return null;
    }

    if (
        intersection &&
        intersection.intersectionRatio !== intersectionVal.current
    ) {
        intersectionVal.current = intersection.intersectionRatio;

        if (typeof props.index === 'number') {
            setTabIntersection?.(props.index, intersection.intersectionRatio);
        }
    }

    return (
        <div
            ref={intersectionRef}
            id={props.id}
            className={`tab-navigation__tab-section ${
                props.containerClassName ? props.containerClassName : ''
            }`}
        >
            {props.children}
        </div>
    );
};

TabSection.defaultProps = {
    intersectionOptions: {},
};
