import { getWindowObject } from '@eventbrite/feature-detection';
import { getExperiment, initializeSdk } from '@eventbrite/statsig';

const getDomain = () => {
    return getWindowObject('__SERVER_DATA__')?.env?.ebDomain;
};
export const initializeStatsigSdk = async () => {
    await initializeSdk({
        user: {
            custom: {
                ebDomain: getDomain(),
            },
        },
    });
};

export const getLoginExperiment = () => {
    return getExperiment(
        'reduce_login_friction_organizer_profile_page',
        'loginExperimentActive',
        false,
    );
};
