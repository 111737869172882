export * from './copy';
export * from './googleAnalytics';
export * from './styles';

export const CARD_CLOSE_DELAY = 500;

// Cookies
export const ORGANIZER_COOKIE = 'ebOrganizerId';
export const NOTIFICATION_COOKIE = 'ebFollowOrganizerNotification';

const NOTIFICATION_COOKIE_EXPIRATION_MONTHS = 3;

export const NOTIFICATION_COOKIE_EXPIRATION_SECONDS =
    60 * 60 * 24 * 30 * NOTIFICATION_COOKIE_EXPIRATION_MONTHS;

// Web Performance
export const FOLLOW_DONE_CUSTOM_METRIC_DATA = 'organizer-followed';
export const FOLLOW_READY_CUSTOM_METRIC_MARK = 'follow-organizer-ready';

export const loginModalContainerClassName =
    'js-follow-organizer-login-container';
export const gdprModalContainerClassName = 'js-gdpr-container';
export const followNotificationContainerClassName =
    'js-follow-organizer-notification';

export const LOGIN_CONFIRMATION_PATH = '/signin/confirmation/';
export const UNAUTHORIZED_ERROR_CODE = 403;

// Errors
export const ERROR_FOLLOW = 'follow';
export const ERROR_UNFOLLOW = 'unfollow';
export const ERROR_ORG_INFO = 'getOrgInfo';
export const ERROR_USER_INFO = 'getUSer';

// Tracking for Statsig

export const FOLLOW_ORGANIZER_ATTEMPT = 'Follow Organizer Button - Attempt';
export const FOLLOW_ORGANIZER_SUCCESS = 'Follow Organizer Button - Success';
