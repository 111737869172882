import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { Star, StarFill } from '@eventbrite/eds-iconography';
import classNames from 'classnames';
import fill from 'lodash/fill';
import isUndefined from 'lodash/isUndefined';
import React, { ReactElement, useEffect, useState } from 'react';
import { iconSizes, starColorsType } from '../../types';
import StarHalfFilled from '../shared/svg/StarHalfFilled';
import './RatingStars.scss';

interface RatingStarsProps {
    rating: number;
    setRatingSelected?: Function;
    size?: iconSizes;
    isReadOnly?: boolean;
    starsAmount?: number;
    title?: string;
    color?: starColorsType;
}

export const RatingStars = ({
    rating,
    setRatingSelected,
    size = 'small',
    isReadOnly,
    starsAmount = 5,
    title = 'Star',
    color,
}: RatingStarsProps) => {
    const [stars, setStars] = useState<number[] | boolean[]>([]);
    const [starsHovered, setStarsHovered] = useState<number[] | boolean[]>([]);

    useEffect(() => {
        _calculateStarFillStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rating]);

    const starsColors = {
        base: color?.base || 'grey-500',
        hover: color?.hover || 'ui-orange',
        selected: color?.selected || 'ui-orange',
    };

    const _calculateStarFillStatus = () => {
        if (rating > starsAmount) {
            return null;
        }
        const stars = fill(Array(starsAmount), 0);
        if (!isUndefined(rating) && rating >= 0) {
            const decimalPortionRating = _retrievePartOfRating(true, rating);
            const integerPortionRating = _retrievePartOfRating(false, rating);

            let quantityOfEmptyStars = starsAmount - integerPortionRating;
            let starsHalfFilled: number[] = [];

            if (decimalPortionRating > 1) {
                quantityOfEmptyStars--;
                starsHalfFilled = fill(Array(1), 2);
            }

            const starsFilled = fill(Array(integerPortionRating), 1);
            const starsEmpty = fill(Array(quantityOfEmptyStars), 0);
            const starsFromProps = [
                ...starsFilled,
                ...starsHalfFilled,
                ...starsEmpty,
            ];

            setStars(starsFromProps);
        } else {
            setStars(stars);
        }
    };

    const _retrievePartOfRating = (decimal: boolean, rating: number) => {
        const whichPartToRetrieve = decimal ? 1 : 0;
        const ratingSplit = `${rating}`.split('.');
        const decimalRating = +ratingSplit[whichPartToRetrieve];

        return decimalRating >= 0 ? decimalRating : 0;
    };

    const _getRating = (stars: Array<any>) => {
        let rating = 0;

        stars.forEach((value) => {
            if (value) {
                rating++;
            }
        });

        return rating;
    };

    const _handleStarClick = (index: number) => {
        const currentStarNumber = index + 1;

        let newStars = fill(Array(currentStarNumber), 1);

        const remainingStars = fill(Array(starsAmount - currentStarNumber), 0);

        newStars = [...newStars, ...remainingStars];

        setRatingSelected && setRatingSelected(_getRating(newStars));
        setStars(newStars);
    };

    const _handleMouseInteraction = (
        currentStar: number,
        isMouseEnter: boolean,
    ) => {
        const starsHovered = fill(Array(currentStar), isMouseEnter);

        setStarsHovered(starsHovered);
    };

    const starsSection: Array<any> = [];

    const createIcon = (
        starIconType: ReactElement,
        idx: number,
        isHovered: boolean | number,
        isSelected: boolean | number,
    ) => {
        const colorBasedOnMouseState = isHovered
            ? starsColors.hover
            : isSelected || isReadOnly
            ? starsColors.selected
            : starsColors.base;

        const iconType = isReadOnly ? starIconType : <StarFill />;

        const starIcon = (
            <Icon
                type={iconType}
                title={title}
                size={size}
                color={colorBasedOnMouseState}
            />
        );

        return isReadOnly ? (
            starIcon
        ) : (
            <Button
                style="none"
                onClick={() => _handleStarClick(idx)}
                onMouseEnter={() => _handleMouseInteraction(idx + 1, true)}
                onMouseLeave={() => _handleMouseInteraction(idx + 1, false)}
            >
                {starIcon}
            </Button>
        );
    };

    stars.forEach((value, idx) => {
        let starIconType = <Star />;
        const isStarSelected = value === 1 || starsHovered[idx];
        const isHalfStarSelected = value === 2;

        if (isStarSelected) {
            starIconType = <StarFill />;
        }

        if (isHalfStarSelected) {
            starIconType = <StarHalfFilled />;
        }

        const starClass = classNames(
            { [`star-spacing--${size}`]: stars.length - 1 !== idx },
            'eds-align--left',
            `star-${idx + 1}`,
        );

        starsSection.push(
            <div className={starClass} key={idx}>
                {createIcon(
                    starIconType,
                    idx,
                    starsHovered[idx],
                    isStarSelected,
                )}
            </div>,
        );
    });

    return (
        <div className="eds-align--left rating-stars--container">
            {starsSection}
        </div>
    );
};
