import { gettext } from '@eventbrite/i18n';
import React from 'react';
import {
    DiscoveryEventsFromView,
    organizerPastEvent,
    organizerUpcomingEvent,
} from '../types';
import { formatReturnCountLimit } from '../utils';
import { EventRenderer } from './EventRenderer';

interface OrganizerEventsInternalProps {
    events: DiscoveryEventsFromView;
}

export const OrganizerEventFollowExperiment: React.FunctionComponent<
    OrganizerEventsInternalProps
> = (props) => {
    const { future_events, past_events, ...eventMetaData } = props.events;
    return (
        <>
            <div
                data-testid="organizer-profile__future-events"
                className="eds-tabs__content--upcoming-events"
            >
                <h2 className="eds-tabs__content--upcoming-events-title">
                    {gettext('Upcoming (%(numFutureEvents)s)', {
                        numFutureEvents: formatReturnCountLimit(
                            eventMetaData.num_future_events,
                        ),
                    })}
                </h2>
                <EventRenderer
                    eventMetaData={eventMetaData}
                    events={future_events}
                    removeSaveHandlers={false}
                    type="future"
                    bucketLabel={organizerUpcomingEvent}
                />
            </div>

            {past_events.length > 0 && (
                <div
                    data-testid="organizer-profile__past-events"
                    className="eds-tabs__content--past-events"
                >
                    <h2 className="eds-tabs__content--past-events-title">
                        {gettext('Past (%(numPastEvents)s)', {
                            numPastEvents: formatReturnCountLimit(
                                eventMetaData.num_past_events,
                            ),
                        })}
                    </h2>
                    <EventRenderer
                        eventMetaData={eventMetaData}
                        events={past_events}
                        removeSaveHandlers={true}
                        type="past"
                        bucketLabel={organizerPastEvent}
                    />
                </div>
            )}
        </>
    );
};
