/**
 * Parses and removes inline style tags via regex
 *
 * @param htmlString String of raw HTML
 */
export const removeInlineStyleTags = (htmlString: string) => {
    const cleanedHtml = htmlString.replace(/(<[^>]+) style=".*?"/g, '$1');

    return cleanedHtml;
};
