import { Button } from '@eventbrite/eds-button';
import {
    withMainControls,
    WithMainControlsProps,
} from '@eventbrite/eds-structure';
import { gettext } from '@eventbrite/i18n';
import React from 'react';

interface ShowMoreEventsProps {
    isLoading: boolean;
    onClick: Function;
    hasMore: boolean;
}

export const ShowMoreEventsInternal: React.FunctionComponent<
    ShowMoreEventsProps & WithMainControlsProps
> = (props) => {
    if (!props.hasMore) {
        return null;
    }

    return (
        <div className="organizer-profile__show-more eds-l-pad-top-4 eds-align--center">
            <Button isLoading={props.isLoading} onClick={() => props.onClick()}>
                {gettext('Show more')}
            </Button>
        </div>
    );
};

export const ShowMoreEvents = withMainControls(ShowMoreEventsInternal);
