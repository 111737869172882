import React from 'react';

/**
 * Mimic React class based component constructor functionality in functional components
 *
 * @param callBack function to call only once
 * @returns void
 *
 */
export const useConstructor = (callBack: () => void) => {
    const [hasBeenCalled, setHasBeenCalled] = React.useState(false);
    if (hasBeenCalled) return;

    callBack();
    setHasBeenCalled(true);
};
